import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { DEFAULT_VALUES } from "../../../constants";

const { ONE } = DEFAULT_VALUES;

const SpecialServicesSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#e5e7eb" highlightColor="#9ca3af">
      <div className="w-full">
        <div className="p-2">
          <Skeleton count={ONE} height={60} width={"100%"} />
        </div>
        <div className="p-2">
          <Skeleton count={ONE} height={60} width={"100%"} />
        </div>
        <div className="p-2">
          <Skeleton count={ONE} height={60} width={"100%"} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default SpecialServicesSkeleton;
