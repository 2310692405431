import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal, { selectSelectedModal, setSelectedModal } from "../../Modal";
import { selectHotelSearchFilters } from "../../Search";
import { getQueryParams } from "../../../../helper";
import { Information, RenderSVG } from "../../../../assets/icons";
import { parseDescription } from "../../../../screens/HotelInfo/HotelInfo";
import { MODALS } from "../modals.constants";
import { ROUTES, DEFAULT_VALUES } from "../../../../constants";

const { HOTEL_RESULTS } = ROUTES;
const { HOTEL_DETAILS_CHANGE_MODAL } = MODALS;
const { ONE } = DEFAULT_VALUES;

const DetailsChangeModal = ({
  handleClose = () => {},
  title = "",
  message = "",
}) => {
  const navigate = useNavigate();
  const activeFilters = useSelector(selectHotelSearchFilters) || {};

  return (
    <div className="overflow-auto">
      <Modal shouldShowModalFromProps handleClose={handleClose} title={title}>
        <div className="flex justify-center items-center py-4 mt-4">
          <RenderSVG
            Svg={Information}
            alt="Inflation Icon"
            height="100"
            width="100"
          />
        </div>
        <div className="flex justify-center items-center py-8 p-10">
          <div className="text-xl min-w-[65px]">{message}</div>
        </div>
        <div className="flex justify-center items-center px-10 gap-4 mb-4">
          <div className="py-4 text-center">
            <button
              className="btn bg-transparent border border-contrast-400 hover:opacity-90"
              onClick={() =>
                navigate(`${HOTEL_RESULTS}?${getQueryParams(activeFilters)}`)
              }
            >
              Search Again
            </button>
          </div>
          <div className="py-4 text-center">
            <button className="btn btn-primary" onClick={() => handleClose()}>
              Agree, Continue
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const HotelDetailsChangeModal = ({
  havePoliciesChanged,
  hasPriceChanged,
  updatedPrice,
  updatedCancellationPolicy,
}) => {
  const dispatch = useDispatch();
  const selectedModal = useSelector(selectSelectedModal);
  const splittedHotelDesctiption = updatedCancellationPolicy?.substring(
    updatedCancellationPolicy.lastIndexOf("|") + ONE
  );

  const handleModalClose = () => dispatch(setSelectedModal(null));

  return (
    selectedModal === HOTEL_DETAILS_CHANGE_MODAL && (
      <>
        {hasPriceChanged && (
          <DetailsChangeModal
            handleClose={handleModalClose}
            title={"Room Prices have changed"}
            message={`New price is: ${updatedPrice}`}
          />
        )}
        {havePoliciesChanged && (
          <DetailsChangeModal
            handleClose={handleModalClose}
            title="Hotel Policy has changed"
            message={parseDescription(splittedHotelDesctiption)}
          />
        )}
      </>
    )
  );
};

export default HotelDetailsChangeModal;
