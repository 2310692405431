import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import createStore from "./createStore";
import rootReducer from "./rootReducer";
import router from "./router";
import { DEFAULT_COLORS, COLORS, DEFAULT_VALUES } from "./constants";
import config from "./config.json";
import { generateColorVariables } from "./helper/generateColorVariables";
import { Helmet, HelmetProvider } from "react-helmet-async";
import SessionProvider from "./infrastructure/SessionProvider/SessionProvider";

const { EMPTY_STRING } = DEFAULT_VALUES;
const { primaryColor, contrast, secondaryColor } = config;
const { PRIMARY, CONTRAST, SECONDARY } = COLORS;
const { primaryHexCode, contrastHexCode, secondaryHexCode } = DEFAULT_COLORS;

const store = createStore(rootReducer);

const App = () => {
  const isDefaultTheme =
    primaryColor.toLowerCase() === primaryHexCode &&
    secondaryColor.toLowerCase() === secondaryHexCode &&
    contrast.toLowerCase() === contrastHexCode;
  const contrastColorVariables = generateColorVariables(
    contrast,
    CONTRAST,
    isDefaultTheme
  );
  const secondaryColorVariables = generateColorVariables(
    secondaryColor,
    SECONDARY,
    isDefaultTheme
  );
  const primaryColorVariables = generateColorVariables(
    primaryColor,
    PRIMARY,
    isDefaultTheme
  );
  const getCSSVariables = () => {
    if (
      primaryColorVariables &&
      contrastColorVariables &&
      secondaryColorVariables
    )
      return primaryColorVariables
        .concat(contrastColorVariables)
        .concat(secondaryColorVariables)
        .join(EMPTY_STRING);
  };
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <style type="text/css">{`:root {${getCSSVariables()} }`}</style>
        </Helmet>
      </HelmetProvider>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GG_APP_ID || EMPTY_STRING}
      >
        <Provider store={store}>
          <SessionProvider>
            <RouterProvider router={router} />
          </SessionProvider>
        </Provider>
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
