import { memo } from "react";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { OtherInfo } from "../../components/molecules/TripOverviewCard/TripOverviewCard";
import { DEFAULT_VALUES, MINI_FARE_RULES } from "../../constants";

const { EMPTY_ARRAY, EMPTY_STRING, ONE, TWO, ZERO } = DEFAULT_VALUES;
const { CANCELLATION: CANCELLATION_RULE } = MINI_FARE_RULES;

const calculatePenalties = (type, miniFareRules) => {
  const penalties = miniFareRules
    ?.filter((rule) => rule.Type === type)
    ?.map((rule) => {
      let penaltyDetails;
      let timeRange;
      let charges;
      const { Details, From, To, Unit } = rule;

      if (!From) timeRange = `0 Hours onwards`;
      else if (!To) timeRange = `${From} ${Unit} onwards`;
      else timeRange = `${From} ${Unit} to ${To} ${Unit}`;

      if (Details === "100%") charges = "Non Refundable";
      else if (Details === "Nil") charges = "No Charges";
      else {
        const priceMatch = Details.match(/([a-zA-Z]+)?\s?(\d+)/);
        if (priceMatch) {
          const currencySymbol = priceMatch[ONE] || EMPTY_STRING;
          const price = priceMatch[TWO];
          charges = `${currencySymbol} ${Number(price).toLocaleString(
            "en-IN"
          )}`;
        } else charges = Details;
      }

      penaltyDetails = {
        timeRange,
        charges,
      };

      return penaltyDetails;
    });

  return penalties;
};

const MiniFareRulesTab = ({ flight, type }) => {
  const { t } = useTranslation();
  const miniFareRules = get(flight, "miniFareRules[0]", EMPTY_ARRAY);
  const isCancellationType = type === CANCELLATION_RULE;
  const penalties = calculatePenalties(type, miniFareRules);

  let fareDetails = {
    tableHeader: [
      {
        title: t("flightResults.journeyDetails.timeFrame"),
        subtitle: t("flightResults.journeyDetails.fromDeparture"),
      },
      {
        title: t("flightResults.journeyDetails.airlineFee"),
        subtitle: t("flightResults.journeyDetails.perPassenger"),
      },
    ],
    penalties,
    penaltyInfo: t("flightResults.journeyDetails.beforeDeparture"),
  };

  return (
    <OtherInfo
      data={fareDetails}
      header={
        isCancellationType
          ? t("flightResults.journeyDetails.cancellation")
          : t("flightResults.journeyDetails.dateChange")
      }
    />
  );
};

export default memo(MiniFareRulesTab);
