import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawer, { selectSelectedDrawer } from "../../../../components/organisms/Drawer";
import { actions } from "../../../../components/organisms/Drawer/drawers.reducers";
import HotelFilters from "../HotelFilters";
import { DRAWER_SIZE } from "../../../../constants";

const { setSelectedDrawer } = actions;
const { MD } = DRAWER_SIZE;

const HotelFiltersDrawer = ({
  setFilteredHotels,
  sortingValues,
  setSortingValues,
  hotelFilters,
  setHotelFilters,
  resetFilters,
  setResetFilters,
}) => {
  const dispatch = useDispatch();
  const selectedDrawer = useSelector(selectSelectedDrawer);

  const handleClose = useCallback(() => {
    dispatch(setSelectedDrawer(null));
  }, [dispatch]);

  const getDrawerHeading = () => "Filters";

  return (
    <Drawer
      position="left"
      size={MD}
      heading={getDrawerHeading()}
      selectedDrawer={selectedDrawer}
      handleClose={handleClose}
    >
      <HotelFilters
        setFilteredHotels={setFilteredHotels}
        sortingValues={sortingValues}
        setSortingValues={setSortingValues}
        hotelFilters={hotelFilters}
        setHotelFilters={setHotelFilters}
        resetFilters={resetFilters}
        setResetFilters={setResetFilters}
      />
    </Drawer>
  );
};

export default HotelFiltersDrawer;
