import { AgGridReact } from "ag-grid-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { isEmpty } from "lodash";
import { getImageUrlByName } from "../../../helper";
import { fetchBookingInfo } from "../index";
import StyledCellContent from "./StyledCellContent";
import exportFromJSON from "export-from-json";
import { BOOKING_TABLE_COLUMNS, ROUTES } from "../../../constants";

const { ADMIN_BOOKINGS } = ROUTES;

const columnDefs = Object.entries(BOOKING_TABLE_COLUMNS).map(
  ([key, value]) => ({
    headerName: value,
    field: key,
    resizable: true,
    cellRenderer: ({ value }) => (
      <StyledCellContent columnKey={key} value={value} />
    ),
  })
);

const BookingTable = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigateToBookingInfo = (tavaBookingId) => {
    dispatch(fetchBookingInfo(tavaBookingId));
    navigate(`${ADMIN_BOOKINGS}/${tavaBookingId}`);
  };

  const downloadBookingCSV = (rowData) => {
    const data = [rowData];
    const fileName = "bookings" + Date.now();
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  const onCellClicked = (params) => {
    if (params.colDef.field === "tavaBookingId") {
      handleNavigateToBookingInfo(params.data.tavaBookingId);
    } else if (params.colDef.field === "bookingData") {
      downloadBookingCSV(params.data);
    }
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: "auto",
        width: "100%",
        border: "1px solid #ccc",
        borderRadius: "10px",
        overflow: "auto",
      }}
    >
      {!isEmpty(data) ? (
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          domLayout="autoHeight"
          onCellClicked={onCellClicked}
          suppressDragLeaveHidesColumns={true}
          headerClass="custom-header-class"
        />
      ) : (
        <div className="w-full flex flex-col items-center mt-20">
          <img
            src={getImageUrlByName("misc/noResult.png")}
            alt="default-booking"
            className="w-10 h-30"
          />
          <h6 className="text-contrast-600 block">No flights booked yet!</h6>
        </div>
      )}
    </div>
  );
};

export default BookingTable;
