import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { uniqueId, get } from "lodash";
import { Helmet } from "react-helmet";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import PricingDetailsSection from "./SingleBooking/PricingDetailsSection";
import FlightDetailsCards from "./SingleBooking/FlightDetailsCards";
import TravelerDetailsSection from "./SingleBooking/TravelerDetailsSection";
import { DEFAULT_VALUES, BOOKING_STATUS, TRIP_TYPES } from "../../../constants";
import { getImageUrlByName } from "../../../helper";
import { Calendar, RenderSVG, TicketSlash, Wave } from "../../../assets/icons";
import { selectBookingInfo } from "../admin.selector";
import { actions as modalActions } from "../../../components/organisms/Modal/modal.reducers";
import { actions as searchActions } from "../../../components/organisms/Search/search.reducer";
import { MODALS } from "../../../components/organisms/AppModals";
import { setSelectedTripType } from "../../FlightResults";
import config from "../../../config.json";

const { brandName } = config;
const { CONFIRMED, PENDING, REJECTED, CANCELLED } = BOOKING_STATUS;
const { EMPTY_OBJECT, ZERO } = DEFAULT_VALUES;
const { FETCH_ADMIN_BOOKING_INFO } = SPINNER_NAMES;
const { FLIGHT_REISSUANCE_MODAL } = MODALS;
const { setSelectedModal } = modalActions;
const { ONE_WAY } = TRIP_TYPES;
const { setSelectedReIssuanceFlight } = searchActions;
const BOOKING_PROVIDER = {
  AM: "Amadeus",
  TBO: "TBO",
};

const getBookingStatusClasses = (status) =>
  classNames("rounded-3xl px-3 py-1", {
    "bg-primary-100 text-primary-500": status === CONFIRMED,
    "bg-yellow-100 text-yellow-600": status === PENDING,
    "text-red-700 font-semibold text-xs bg-red-50 ":
      status === REJECTED || status === CANCELLED,
  });

const getFormattedBookingData = ({ isTBO, bookingData }) => {
  const valuePathForLCCFlights = isTBO
    ? "bookingJSON.bookingRequest"
    : "bookingJSON";
  const valuePathForNonLCCFlights = isTBO
    ? "bookingJSON.bookingRequest.journeyDetails.0"
    : "bookingJSON";
  const { itineraries, travelerDetails, price } = get(
    bookingData,
    valuePathForLCCFlights,
    EMPTY_OBJECT
  );
  if (!itineraries) {
    const { itineraries, travelerDetails, price } = get(
      bookingData,
      valuePathForNonLCCFlights,
      EMPTY_OBJECT
    );
    return { itineraries, travelerDetails, price };
  }
  return { itineraries, travelerDetails, price };
};

const formatBookingRequest = (bookingInfo) => {
  const bookingData = get(bookingInfo, "result.0", EMPTY_OBJECT);
  const { status, tavaBookingId, pnr, provider } = bookingData;
  const isTBO = provider === BOOKING_PROVIDER.TBO;

  const { itineraries, travelerDetails, price } = getFormattedBookingData({
    isTBO,
    bookingData,
  });

  return {
    status,
    tavaBookingId,
    pnr,
    provider,
    itineraries,
    travelerDetails,
    price,
  };
};

const FlightBookingInfoScreen = () => {
  const dispatch = useDispatch();
  const bookingInfo = useSelector(selectBookingInfo);

  const {
    status,
    tavaBookingId,
    pnr,
    itineraries,
    travelerDetails,
    price: pricingDetails,
    provider,
  } = formatBookingRequest(bookingInfo);

  const handleFlightReschedule = () => {
    dispatch(setSelectedModal(FLIGHT_REISSUANCE_MODAL));
    dispatch(setSelectedReIssuanceFlight(bookingInfo.result[ZERO]));
    dispatch(setSelectedTripType(ONE_WAY));
  };

  return (
    <div className="flex flex-col align-middle min-h-[100vh]">
      <Helmet>
        <title>{brandName} | Admin Booking Info</title>
      </Helmet>
      <Spinner name={FETCH_ADMIN_BOOKING_INFO} size="w-12 h-10">
        {bookingInfo && (
          <>
            <header
              className={classNames("relative w-full", {
                "bg-teal-600": status === CONFIRMED,
                "bg-yellow-600": status === PENDING,
                "bg-red-600": status === REJECTED || status === CANCELLED,
              })}
            >
              <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-gray-900/0 to-gray-900/50"></div>
              <img
                src={Wave}
                className="absolute w-full h-full bottom-0 left-0 object-cover object-left"
                alt="wave"
              />
              <div className="container px-8 mx-auto relative">
                <div className="grid grid-cols-12 min-h-[160px]">
                  <div className="col-span-12 lg:col-span-9 flex flex-col justify-end py-4">
                    <h4 className="text-xl text-gray-900 mb-1 font-bold">
                      Booking ID:{" "}
                      <span className="font-semibold">#{tavaBookingId}</span>
                    </h4>
                    <div className="flex items-center gap-2">
                      {pnr && (
                        <p className="text-gray-800 text-sm font-semibold">
                          PNR No: {pnr}
                        </p>
                      )}
                      <span className={getBookingStatusClasses(status)}>
                        {status}
                      </span>
                    </div>
                  </div>
                  <div className="hidden lg:block col-span-3 py-6">
                    <img
                      src={getImageUrlByName("admin/plane.png")}
                      width="290"
                      className="max-w-full"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </header>
            <div className="my-4">
              <div className="container px-8 mx-auto">
                <div className="grid grid-cols-12 gap-8">
                  <div className="col-span-12 xl:col-span-8">
                    <h4 className="text-gray-900 font-bold mb-6 text-xl">
                      Flight summary
                    </h4>
                    <div className="flex flex-col gap-8 mb-8">
                      {itineraries?.map((itinerary) => (
                        <FlightDetailsCards
                          key={uniqueId()}
                          itinerary={itinerary}
                        />
                      ))}
                    </div>
                    {travelerDetails && (
                      <TravelerDetailsSection
                        travlerDetails={travelerDetails}
                      />
                    )}
                  </div>
                  <div className="col-span-12 xl:col-span-4">
                    {pricingDetails && (
                      <PricingDetailsSection pricingDetails={pricingDetails} />
                    )}
                    <div className="border border-gray-300 rounded-lg divide-y divide-gray-300 mb-6">
                      <div className="p-6">
                        <h4 className="font-bold text-gray-900 text-base mb-5">
                          Change in Plans
                        </h4>
                        <ul className="flex flex-col gap-3">
                          <li>
                            <button
                              className="flex gap-2 items-center text-sm font-medium text-indigo-600 disabled:text-indigo-400 disabled:cursor-not-allowed"
                              disabled
                            >
                              <div className="icon">
                                <RenderSVG Svg={TicketSlash} />
                              </div>
                              <span>Cancel Full Booking</span>
                            </button>
                          </li>
                          <li>
                            <button
                              className="flex gap-2 items-center text-sm font-medium text-indigo-600 disabled:text-indigo-400 disabled:cursor-not-allowed"
                              disabled={
                                status !== CONFIRMED || provider !== "TBO"
                              }
                              onClick={handleFlightReschedule}
                            >
                              <div className="icon">
                                <RenderSVG Svg={Calendar} />
                              </div>
                              <span>Change Travel Dates</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Spinner>
    </div>
  );
};

export default FlightBookingInfoScreen;
