import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import {
  getPriceAfterDiscount,
  getSurchargesAmount,
  priceFormatter,
} from "../../helper";
import { selectPricePolicyInfo } from "../HotelInfo";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import { DEFAULT_VALUES, CURRENCY_SYMBOLS } from "../../constants";
import { Cross, RenderSVG } from "../../assets/icons";
import {
  selectSelectedPromoCode,
  setPromoCode,
} from "../Booking/FlightBookings";
import { selectCountryInfo } from "../Profile";

const { ZERO, EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;
const { INR } = CURRENCY_SYMBOLS;

const PriceBreakdownSection = () => {
  const dispatch = useDispatch();
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const searchFilters = useSelector(selectHotelSearchFilters);
  const pricePolicyInfo = useSelector(selectPricePolicyInfo);
  const selectedPromoCode = useSelector(selectSelectedPromoCode);
  const [discountAmount, setDiscountAmount] = useState(ZERO);
  const [priceBreakDown, setPriceBreakDown] = useState({
    totalRoomPrice: ZERO,
    totalTaxesAndFees: ZERO,
    totalPublishedFare: ZERO,
    pgCharges: ZERO,
    netPayable: ZERO,
  });
  const {
    totalPublishedFare,
    netPayable,
    totalRoomPrice,
    totalTaxesAndFees,
    pgCharges,
  } = priceBreakDown;

  const priceInfo = get(
    pricePolicyInfo,
    "PriceAndCancellationPolicyDetails.PriceAndCancellationPolicyDetail.BlockRoomResult.HotelRoomsDetails",
    []
  );
  const { NoOfNights = ZERO, NoOfRooms = ZERO } = searchFilters || {};
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  useEffect(() => {
    let totalRoomPrice = ZERO;
    let totalPublishedFare = ZERO;
    if (!isEmpty(priceInfo)) {
      priceInfo.forEach((room) => {
        if (room.Price?.RoomPrice) {
          totalRoomPrice += room.Price.RoomPrice;
          totalPublishedFare += room.Price.PublishedPriceRoundedOff;
        }
      });
    }
    setPriceBreakDown({
      totalRoomPrice,
      totalTaxesAndFees: totalPublishedFare - totalRoomPrice,
      totalPublishedFare: totalPublishedFare,
      pgCharges: getSurchargesAmount(totalPublishedFare),
      netPayable: totalPublishedFare + getSurchargesAmount(totalPublishedFare),
    });
  }, [priceInfo]);

  useEffect(() => {
    if (isEmpty(selectedPromoCode)) setDiscountAmount(ZERO);
    else {
      const finalPrices = getPriceAfterDiscount(
        selectedPromoCode,
        totalPublishedFare
      );
      const updatedPrice = finalPrices.updatedPrice + pgCharges;

      if (updatedPrice !== selectedPromoCode.offeredPrice) {
        const promoCode = {
          ...selectedPromoCode,
          offeredPrice: updatedPrice,
          ...(finalPrices.discountAmount && {
            discountAmount: finalPrices.discountAmount,
          }),
        };
        dispatch(setPromoCode(promoCode));
        setDiscountAmount(finalPrices.discountAmount);
      }
    }
  }, [dispatch, selectedPromoCode, totalPublishedFare, pgCharges]);

  return (
    <div className="border border-contrast-300 rounded-lg mb-6 bg-white">
      <ul className="flex flex-col text-sm !divide-y">
        <li className="px-4 py-3">
          <h4 className="text-contrast-900 font-bold text-base">
            Price breakdown
          </h4>
        </li>
        <li className="flex items-center gap-2 px-4 py-3">
          <div className="mr-auto flex flex-col">
            <span className="font-semibold text-contrast-900">
              {NoOfRooms} Room x {NoOfNights} Nights
            </span>
            <span className="text-xs text-contrast-500">Base price</span>
          </div>
          <span className="font-medium whitespace-nowrap">
            {currencySymbol}
            {priceFormatter(totalRoomPrice)}
          </span>
        </li>
        <li className="flex items-center gap-2 px-4 py-3">
          <span className=" mr-auto text-contrast-900">
            Hotel Taxes &amp; Fees
          </span>
          <span className="font-medium whitespace-nowrap">
            {currencySymbol}
            {priceFormatter(totalTaxesAndFees)}
          </span>
        </li>
        {!isEmpty(selectedPromoCode) && (
          <li className="flex items-center gap-2 px-4 py-3">
            <span className=" mr-auto text-green-700 font-semibold">
              Total Discount
            </span>
            <div className="flex items-center justify-between font-medium text-green-600">
              <span className="font-semibold text-green-700 whitespace-nowrap">
                {currencySymbol}
                {priceFormatter(discountAmount)}
              </span>
              {!isEmpty(selectedPromoCode) && (
                <button
                  type="button"
                  className="flex items-center justify-end pl-2"
                  onClick={() => dispatch(setPromoCode(EMPTY_OBJECT))}
                >
                  <RenderSVG
                    Svg={Cross}
                    alt="Check Icon"
                    className="h-4 relative rounded-full"
                    color="#FF0000"
                  />
                </button>
              )}
            </div>
          </li>
        )}
        <li className="flex items-center gap-2 px-4 py-3">
          <span className=" mr-auto text-contrast-900">
            Payment Gateway Fees
          </span>
          <span className="font-medium whitespace-nowrap">
            {currencySymbol}
            {priceFormatter(pgCharges)}
          </span>
        </li>
        <li className="flex items-center gap-2 px-4 py-3 bg-primary-100/50">
          <span className="text-base font-bold mr-auto text-primary-600">
            Total Due
          </span>
          <span className="text-base font-bold text-primary-600 whitespace-nowrap">
            {currencySymbol}
            {priceFormatter(netPayable - discountAmount)}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default PriceBreakdownSection;
