import classNames from "classnames";
import {
  getCancellationCharges,
  getFormattedLongDate,
  getFormattedTime,
} from "../../helper";

const CancellationPolicyInfo = ({ cancellationPolicies = [] }) => (
  <div className="max-w-md flex items-center mb-14">
    <div className="flex-1 bg-green-700 text-[10px] text-white text-center rounded-s-2xl flex items-center py-0.5 px-1">
      <div className="w-3 h-3 bg-white rounded-full border-contrast-200 relative">
        <div className="absolute top-full left-1/2 -translate-x-1/2 whitespace-nowrap pt-2">
          <h6 className="font-semibold text-contrast-800 text-[10px]">Now</h6>
          <p className="text-[10px] text-contrast-500">Pay ₹0</p>
        </div>
      </div>
      <div className="flex-1 ">100% refund</div>
    </div>
    <div
      className={classNames("flex", {
        "w-4/12": cancellationPolicies.length === 1,
        "w-1/2": cancellationPolicies.length === 2,
      })}
    >
      {cancellationPolicies.map(
        ({ Charge, ChargeType, FromDate, ToDate, Currency }, index) => {
          const refundability =
            Charge === 100
              ? "Non Refundable"
              : `${getCancellationCharges(
                  Charge,
                  ChargeType,
                  Currency
                )} Refund`;

          return (
            <div key={FromDate}>
              <div className="relative self-stretch">
                <div className="absolute top-full -translate-x-1/2 whitespace-nowrap pt-2">
                  <h6 className="font-semibold text-contrast-800 text-[10px] mt-4">
                    {getFormattedLongDate(FromDate, {
                      day: "numeric",
                      month: "short",
                    })}
                  </h6>
                  <h6 className="font-semibold text-contrast-800 text-[10px]">
                    {getFormattedTime(FromDate)}
                  </h6>
                </div>
              </div>
              <div
                className={classNames(
                  "flex-1 bg-amber-200 text-[10px] text-amber-800 text-center flex items-center py-0.5 px-2 border-l border-black",
                  {
                    "rounded-e-2xl": index === cancellationPolicies.length - 1,
                    "bg-red-200 text-red-800":
                      ChargeType === 2 && Charge === 100,
                  }
                )}
              >
                <div className="flex-1 whitespace-nowrap">{refundability}</div>
                {index === cancellationPolicies.length - 1 && (
                  <div className="relative">
                    <div className="w-3 h-3 ml-1 bg-white rounded-full border-contrast-200 relative"></div>
                    <div className="absolute whitespace-nowrap">
                      <h6 className="font-semibold text-contrast-800 text-[10px] mt-2">
                        {getFormattedLongDate(ToDate, {
                          day: "numeric",
                          month: "short",
                        })}
                      </h6>
                      <h6 className="font-semibold text-contrast-800 text-[10px]">
                        {getFormattedTime(ToDate)}
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        }
      )}
    </div>
  </div>
);

export default CancellationPolicyInfo;
