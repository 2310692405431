import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { get, isEmpty } from "lodash";
import { Helmet } from "react-helmet";
import BookingInfoCard from "./BookingInfoCard";
import { formatDateWithLeadingZeros, getImageUrlByName } from "../../helper";
import { fetchBookings, selectBookings, selectHotelBookings } from "../Admin";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  DEFAULT_VALUES,
  ROUTES,
  BOOKING_CATEGORIES,
  HOTEL_BOOKING_CATEGORIES,
  BOOKING_SEARCH_OPTIONS,
} from "../../constants";
import config from "../../config.json";

const { brandName } = config;
const { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT, ZERO } = DEFAULT_VALUES;
const { HOME } = ROUTES;
const { BOOKINGS, FETCH_USER } = SPINNER_NAMES;
const {
  CONFIRMED,
  COMPLETED,
  UNSUCCESSFUL,
  UPCOMING,
  CANCELED,
  FAILED,
  AWAITING_PAYMENT,
  SUCCESSFUL,
} = BOOKING_CATEGORIES;
const { HOTEL_AWAITING_PAYMENT, HOTEL_CONFIRMED } = HOTEL_BOOKING_CATEGORIES;
const { BookingId } = BOOKING_SEARCH_OPTIONS;
const categories = [UPCOMING, COMPLETED, CANCELED, UNSUCCESSFUL];
const IS_VOUCHERED_BOOKING = "isVoucheredBooking";

const getHotelCheckIndate = (hotelBookingJson) => {
  const [{ DayRates = [] } = {}] = hotelBookingJson.HotelRoomsDetails || [];
  const dateInString = get(DayRates, "0.Date", EMPTY_STRING);
  return new Date(dateInString);
};

const getFlightDepartureDate = (flightBookingJson) => {
  const { journeyDetails = EMPTY_ARRAY } = flightBookingJson;
  let [{ itineraries = EMPTY_ARRAY } = EMPTY_OBJECT] = journeyDetails;

  if (isEmpty(itineraries)) return;
  const departureDate = get(
    itineraries,
    "0.segments.0.departure.date",
    EMPTY_STRING
  );
  const departureTime = get(
    itineraries,
    "0.segments.0.departure.time",
    EMPTY_STRING
  );
  const dateInString = formatDateWithLeadingZeros(
    `${departureDate}T${departureTime}`
  );
  return dateInString ? new Date(dateInString) : new Date();
};

const filterBookingsByStatus = (category, bookings = []) =>
  bookings.filter((bookingObject) => {
    const { pnr, status, bookingStatus } = bookingObject;
    if (bookingObject.hasOwnProperty(IS_VOUCHERED_BOOKING)) {
      /*--- filter hotel bookings ---*/
      return category === UNSUCCESSFUL
        ? bookingStatus === HOTEL_AWAITING_PAYMENT
        : bookingStatus === CANCELED;
    } else {
      /*--- filter flight bookings ---*/
      return category === UNSUCCESSFUL ? !pnr : status === CANCELED && pnr;
    }
  });

const filterBookingsByDate = (category, bookings = []) =>
  bookings.filter((bookingObject) => {
    const {
      bookingJSON,
      ticketingStatus,
      bookingReqJson,
      bookingStatus,
      status,
    } = bookingObject;
    if (bookingObject.hasOwnProperty(IS_VOUCHERED_BOOKING)) {
      /*--- filter hotel bookings ---*/
      const hotelCheckIndate = getHotelCheckIndate(bookingReqJson);
      if (category === COMPLETED)
        return (
          hotelCheckIndate < new Date() && bookingStatus === HOTEL_CONFIRMED
        );
      else
        return (
          hotelCheckIndate > new Date() && bookingStatus === HOTEL_CONFIRMED
        );
    } else if (bookingJSON) {
      /*--- filter flight bookings ---*/
      const dateObject = getFlightDepartureDate(bookingJSON);
      if (category === COMPLETED)
        return (
          dateObject < new Date() &&
          ticketingStatus === CONFIRMED &&
          status === CONFIRMED
        );
      else
        return (
          dateObject > new Date() &&
          ticketingStatus === CONFIRMED &&
          status === CONFIRMED
        );
    }
  });

const getBookingCategory = (bookingObject) => {
  const {
    bookingJSON,
    ticketingStatus,
    status,
    pnr,
    bookingReqJson,
    bookingStatus,
  } = bookingObject;
  if (bookingObject.hasOwnProperty(IS_VOUCHERED_BOOKING)) {
    /*--- filter hotel bookings ---*/
    const hotelCheckIndate = getHotelCheckIndate(bookingReqJson);
    switch (bookingStatus) {
      case HOTEL_AWAITING_PAYMENT:
        return UNSUCCESSFUL;
      case CANCELED:
        return CANCELED;
      case HOTEL_CONFIRMED:
        return hotelCheckIndate < new Date() ? COMPLETED : UPCOMING;
    }
  } else {
    /*--- filter flight bookings ---*/
    const dateObject = getFlightDepartureDate(bookingJSON);

    if (!pnr) return UNSUCCESSFUL;
    else if (pnr && status === CANCELED) return CANCELED;
    else if (
      dateObject < new Date() &&
      ticketingStatus === CONFIRMED &&
      status === CONFIRMED
    )
      return COMPLETED;
    else if (
      dateObject > new Date() &&
      ticketingStatus === CONFIRMED &&
      status === CONFIRMED
    )
      return UPCOMING;
  }
};

const getSortedBookings = (booking) =>
  booking.toSorted((firstBooking, secondBooking) => {
    const {
      bookingReqJson: firstHotelBookingJson,
      bookingJSON: firstFlightBookingJson,
    } = firstBooking;
    const {
      bookingReqJson: secondHotelBookingJson,
      bookingJSON: secondFlightBookingJson,
    } = secondBooking;
    const firstBookingdate = firstHotelBookingJson
      ? getHotelCheckIndate(firstHotelBookingJson)
      : getFlightDepartureDate(firstFlightBookingJson);
    const secondBookingdate = secondHotelBookingJson
      ? getHotelCheckIndate(secondHotelBookingJson)
      : getFlightDepartureDate(secondFlightBookingJson);
    return firstBookingdate - secondBookingdate;
  });

const MyTrips = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const flightBookings = useSelector(selectBookings);
  const hotelBookings = useSelector(selectHotelBookings);
  const [activeFilter, setActiveFilter] = useState(BookingId);
  const [searchValue, setSearchValue] = useState(EMPTY_STRING);
  const [category, setCategory] = useState(UPCOMING);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);

  useEffect(() => {
    let combinedBookings = [];
    let updatedFilteredBookings = [];
    if (flightBookings)
      combinedBookings = [...combinedBookings, ...flightBookings.result];
    if (hotelBookings)
      combinedBookings = [...combinedBookings, ...hotelBookings.result];

    if (category === COMPLETED || category === UPCOMING)
      updatedFilteredBookings = filterBookingsByDate(
        category,
        combinedBookings
      );
    else
      updatedFilteredBookings = filterBookingsByStatus(
        category,
        combinedBookings
      );
    const sortedBookingByDate = getSortedBookings(updatedFilteredBookings);
    setFilteredBookings(sortedBookingByDate);
  }, [flightBookings, hotelBookings, category]);

  useEffect(() => {
    const queryParams = {
      pageSize: 100,
    };
    dispatch(fetchBookings(queryParams));
  }, [dispatch]);

  useEffect(() => window.scrollTo(ZERO, ZERO), EMPTY_ARRAY);

  const handleSearchBookings = (activeFilter, searchValue) => {
    const queryParams = {
      [activeFilter]: searchValue,
    };

    dispatch(fetchBookings(queryParams)).then(({ payload = [] }) => {
      const combinedBookings = payload.reduce((acc, { output }) => {
        acc = [...acc, ...output.result];
        return acc;
      }, []);
      const updatedCategory =
        combinedBookings.length && getBookingCategory(combinedBookings[ZERO]);
      updatedCategory && setCategory(updatedCategory);
    });
  };

  const handleTabChange = (category) => {
    let status = category;
    let secStatus;
    let terStatus;
    if (category === COMPLETED || category === UPCOMING) {
      status = CONFIRMED;
    } else if (category === UNSUCCESSFUL) {
      status = FAILED;
      secStatus = SUCCESSFUL;
      terStatus = AWAITING_PAYMENT;
    }

    const queryParams = {
      pageSize: 100,
      status,
      secStatus,
      terStatus,
    };

    dispatch(fetchBookings(queryParams));
    setSearchValue(EMPTY_STRING);
    setCategory(category);
  };

  return (
    <div>
      <Helmet>
        <title>{brandName} | MyTrips</title>
      </Helmet>
      <header
        className={classNames("relative", {
          "bg-teal-200": category === UPCOMING || category === COMPLETED,
          "bg-yellow-200": category === CANCELED,
          "bg-red-200": category === UNSUCCESSFUL,
        })}
      >
        <div
          className={classNames(
            "absolute w-full h-full top-0 left-0 bg-gradient-to-r from-gray-900/0",
            {
              "to-teal-900/50": category === UPCOMING || category === COMPLETED,
              "to-yellow-900/50": category === CANCELED,
              "to-red-900/50": category === UNSUCCESSFUL,
            }
          )}
        ></div>
        <div className="container px-4 pt-12 pb-20 mx-auto relative">
          <div className="grid grid-cols-12 gap-4  max-w-5xl mx-auto">
            <div className="col-span-12 md:col-span-4 lg:col-span-7">
              <h4 className="text-xl text-teal-950 mb-1 font-bold">
                {t("userBookings.myTrips")}
              </h4>
            </div>
            <div className="col-span-12 md:col-span-8 lg:col-span-5">
              <div className="flex">
                <select
                  onChange={(e) => setActiveFilter(e.target.value)}
                  className="form-select text-xs font-medium py-2 pl-3 pr-10 rounded-l-md border-gray-300"
                >
                  {Object.entries(BOOKING_SEARCH_OPTIONS).map(
                    ([key, value]) => (
                      <option key={key} value={value}>
                        {key}
                      </option>
                    )
                  )}
                </select>
                <input
                  type="text"
                  className="text-xs font-medium py-2 pl-3 pr-10  border-gray-300 border-l-0 flex-1"
                  placeholder={
                    activeFilter
                      ? `Search Booking by ${activeFilter}`
                      : "Select to Search by ID or PNR"
                  }
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <button
                  className="bg-primary-700 py-2 px-3 rounded-r-md text-xs font-semibold text-white disabled:bg-contrast-500"
                  onClick={() =>
                    handleSearchBookings(activeFilter, searchValue)
                  }
                  disabled={!searchValue || isSpinnerActive}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="pb-16 relative">
        <div className="container px-4 mx-auto -mt-12">
          <div className="w-full">
            <div className="bg-white rounded-lg shadow-lg max-w-5xl mx-auto">
              <ul className="nav-tab flex items-center border-b border-gray-200 px-3 max-w-full overflow-x-scroll no-scrollbar">
                {categories.map((tabStatus) => (
                  <li key={tabStatus}>
                    <button
                      onClick={() => handleTabChange(tabStatus)}
                      className={`flex justify-center px-6 py-5 gap-3 items-center border-b-2 ${
                        tabStatus === category
                          ? "border-primary-600 text-primary-600"
                          : "border-gray-300 text-gray-600"
                      }`}
                    >
                      <span className="font-semibold text-current text-sm capitalize">
                        {tabStatus.toLowerCase()}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
              <div className="min-h-[400px] flex items-center justify-center">
                <Spinner
                  name={[BOOKINGS, FETCH_USER]}
                  message={t("userBookings.spinnerMessage")}
                  spinnerClassName="w-full"
                  setIsSpinnerActive={setIsSpinnerActive}
                >
                  {filteredBookings.length ? (
                    <div className="w-full min-h-screen px-6 flex flex-col justify-start items-start">
                      <div
                        className={classNames(
                          "w-full py-6 flex-col justify-start items-start inline-flex"
                        )}
                      >
                        <div className="self-stretch text-contrast-900 font-bold">
                          <div
                            className={classNames({
                              "flex flex-col gap-4 md:w-full":
                                !isEmpty(filteredBookings),
                            })}
                          >
                            {filteredBookings.map((booking) => (
                              <BookingInfoCard
                                key={booking.id}
                                type={category}
                                booking={booking}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center">
                      <div className="container bg-white rounded-lg h-[360px] overflow-hidden px-5">
                        <div className="text-center">
                          <div className="flex justify-center">
                            <img
                              src={getImageUrlByName("misc/noResult.png")}
                              alt="image"
                              className="w-60 h-60"
                            />
                          </div>
                          <div className="font-bold text-xl">
                            {t("userBookings.noFlightsBooked", {
                              category: category.toLowerCase(),
                            })}
                          </div>
                          <span
                            className="text-md font-bold text-primary-700 hover:text-primary-900 cursor-pointer"
                            onClick={() => navigate(HOME)}
                          >
                            {searchValue === EMPTY_STRING ? (
                              <div>{t("userBookings.planTrip")}</div>
                            ) : (
                              <div>
                                {activeFilter === BookingId ? (
                                  <p>{t("userBookings.noFlightWithId")}</p>
                                ) : (
                                  <p>{t("userBookings.noFlightWithPnr")}</p>
                                )}
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </Spinner>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MyTrips;
