import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import AdminLeftSidebar from "./Widgets/AdminLeftSidebar";
import Footer from "../../components/organisms/Footer";
import config from "../../config.json";

const { brandName } = config;

const Admin = () => {
  return (
    <div className=" bg-gray-100">
      <Helmet>
        <title>{brandName} | Admin</title>
      </Helmet>
      <div className="hidden lg:block">
        <AdminLeftSidebar />
      </div>
      <div className="lg:pl-56">
        <Outlet />
        <div className="mt-4">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Admin;
