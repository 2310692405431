import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  fetchAmadeusFlights,
  fetchTBOFlights,
  getCalendarDatePrices,
  getIATALocations,
  getHotelLocations,
  getHotels,
} from "./index";
import { getFormattedFlightSearchResponse } from "../../../ResponseMapper";
import { getFormattedFarePricesPerDates } from "../../../ResponseMapper/FareCalendarMapping/getFormattedFareCalendarResponse";
import {
  DEFAULT_VALUES,
  LOCATION_TYPE,
  INITIAL_FLIGHT_COUNT,
  SORTING_HEADERS,
  FLIGHTS_RESULT_TYPE,
  INITIAL_FILTERS,
  DEFAULT_FLIGHT_SEARCH_OPTIONS,
} from "../../../constants";

const { EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const { FROM, TO } = LOCATION_TYPE;
const { cheapest } = SORTING_HEADERS;
const { INBOUND_RESULT, OUTBOUND_RESULT } = FLIGHTS_RESULT_TYPE;

const initialState = {
  flights: EMPTY_OBJECT,
  flightsCount: INITIAL_FLIGHT_COUNT,
  filteredFlights: EMPTY_OBJECT,
  activeFilters: INITIAL_FILTERS,
  flightSearchOptions: DEFAULT_FLIGHT_SEARCH_OPTIONS,
  flightTokens: EMPTY_OBJECT,
  searchFilters: EMPTY_OBJECT,
  selectedReIssuanceFlight: null,
  toIataLocations: EMPTY_ARRAY,
  fromIataLocations: EMPTY_ARRAY,
  farePricesPerDates: EMPTY_OBJECT,
  isNonStopSelected: false,
  sortedFlights: EMPTY_OBJECT,
  activeSortOrder: {
    inbound: cheapest.name,
    outbound: cheapest.name,
    packages: cheapest.name,
  },
  // TODO: Need to move to separate hotel redux state
  hotels: EMPTY_OBJECT,
  searchHotelFilters: null,
  hotelLocations: EMPTY_ARRAY,
};

const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchFilters(state, action) {
      state.searchFilters = action.payload || initialState.searchFilters;
    },
    setFlights(state, action) {
      const {
        flights = initialState.flights,
        count = initialState.flightsCount,
      } = action.payload || EMPTY_OBJECT;
      state.flights = flights;
      state.flightsCount = count;
    },
    setHotels(state, action) {
      state.hotels = action.payload || initialState.hotels;
    },
    setSearchHotelFilters(state, action) {
      if (action.payload) state.searchHotelFilters = action.payload;
    },
    setActiveFilters(state, action) {
      state.activeFilters = action.payload || initialState.activeFilters;
    },
    setFlightSearchOptions(state, action) {
      state.flightSearchOptions =
        action.payload || initialState.flightSearchOptions;
    },
    setFilteredFlights(state, action) {
      state.filteredFlights = action.payload || initialState.filteredFlights;
    },
    setSearch(state, action) {
      if (action.payload) return action.payload;
    },
    resetFlights(state) {
      state.flights = initialState.flights;
      state.flightsCount = initialState.flightsCount;
      state.flightTokens = initialState.flightTokens;
      state.filteredFlights = initialState.filteredFlights;
      state.activeSortOrder = initialState.activeSortOrder;
    },
    setFlightsCount(state, action) {
      state.flightsCount = action.payload || initialState.flightsCount;
    },
    setIsNonStopSelected(state, action) {
      state.isNonStopSelected =
        action.payload || initialState.isNonStopSelected;
    },
    setSortedFlights(state, action) {
      state.sortedFlights = action.payload;
    },
    setActiveSortOrder(state, action) {
      const { activeSort, type } = action.payload;
      switch (type) {
        case INBOUND_RESULT:
          state.activeSortOrder.inbound = activeSort;
          break;
        case OUTBOUND_RESULT:
          state.activeSortOrder.outbound = activeSort;
          break;
        default:
          state.activeSortOrder.packages = activeSort;
          break;
      }
    },
    setSelectedReIssuanceFlight(state, action) {
      state.selectedReIssuanceFlight = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAmadeusFlights.fulfilled, (state, action) => {
        const travelType = get(action, "meta.arg.body.travelType", "domestic");
        if (!action.payload) return;
        const formattedAmadeusFlightsResult = getFormattedFlightSearchResponse(
          state.flights,
          state.flightsCount,
          action.payload.output,
          state.searchFilters.tripType,
          travelType,
          state.searchFilters.fareType
        );
        const { flights, count } = formattedAmadeusFlightsResult;
        state.flights = flights;
        state.filteredFlights = flights;
        state.flightsCount = { ...state.flightsCount, ...count };
      })
      .addCase(fetchTBOFlights.fulfilled, (state, action) => {
        const travelType = get(action, "meta.arg.body.travelType", "domestic");

        if (!action.payload) return;
        const formattedTBOFlightsResult = getFormattedFlightSearchResponse(
          state.flights,
          state.flightsCount,
          action.payload.output,
          state.searchFilters.tripType,
          travelType,
          state.searchFilters.fareType
        );
        const { flights, count, tokenId, traceId } = formattedTBOFlightsResult;
        state.flights = flights;
        state.filteredFlights = flights;
        state.flightTokens = { traceId, tokenId };
        state.flightsCount = { ...state.flightsCount, ...count };
      })
      .addCase(getHotels.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.hotels = action.payload.output;
      })
      .addCase(getIATALocations.fulfilled, (state, action) => {
        if (!action.payload) return;
        if (action.payload.type === FROM)
          state.fromIataLocations = action.payload;
        else if (action.payload.type === TO)
          state.toIataLocations = action.payload;
        else {
          state.fromIataLocations = action.payload;
          state.toIataLocations = action.payload;
        }
      })
      .addCase(getCalendarDatePrices.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.farePricesPerDates = getFormattedFarePricesPerDates(
          action.payload
        );
      })
      .addCase(getHotelLocations.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.hotelLocations = get(action, "payload.hits", []);
      });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
