import get from "lodash/get";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { selectSelectedLCCBaggages } from "../index";
import { selectFlightPriceReq } from "../../../FlightResults";
import { Minus, Plus, RenderSVG } from "../../../../assets/icons";
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_VALUES,
  TRAVELER_TYPE,
} from "../../../../constants";
import { showSSRPrice } from "../../../../helper";
import { selectCountryInfo } from "../../../Profile";

const { ZERO, ONE, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;
const { HELD_INFANT } = TRAVELER_TYPE;

const countOfCurrentBaggage = (code, baggages = EMPTY_ARRAY) =>
  baggages.filter((baggage) => baggage.Code === code).length;

const BaggageCard = ({ data, handleBaggageSelection }) => {
  const { t } = useTranslation();
  const { FlightNumber, Weight, Code, Price } = data;
  const currentFlightBaggages = get(
    useSelector(selectSelectedLCCBaggages),
    FlightNumber,
    EMPTY_ARRAY
  );
  const totalAvailableBaggagesThatCanBeSelected = get(
    useSelector(selectFlightPriceReq),
    "0.price.travelerDetails",
    EMPTY_ARRAY
  ).filter(({ travelerType }) => travelerType !== HELD_INFANT).length;
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencyCode = get(
    selectedCountryInfo,
    "currency.code",
    DEFAULT_CURRENCY_CODE
  );
  const baggagesCount = countOfCurrentBaggage(Code, currentFlightBaggages);

  const isAddButtonDisabled =
    totalAvailableBaggagesThatCanBeSelected > currentFlightBaggages.length;

  const getErrorMessage = () =>
    !isAddButtonDisabled
      ? `Sorry, you cannot select more than ${totalAvailableBaggagesThatCanBeSelected} baggages`
      : EMPTY_STRING;

  return (
    <div>
      <div key={Code} className="my-1">
        <div className="my-1 p-3 border-b flex justify-between items-center">
          <div className="flex flex-col">
            <div>
              {t("baggageSelection.additional")} {Weight}kg
            </div>
            <div className="text-sm">{showSSRPrice(Price, currencyCode)}</div>
          </div>
          <div className="relative">
            <Tooltip
              id="baggage-tooltip"
              className="!bg-contrast-500 !rounded-lg"
            />
            <div className="h-full flex gap-1 border border-1 items-center justify-center p-1 rounded-md">
              {!baggagesCount ? (
                <button
                  className="flex items-center justify-center text-xs disabled:cursor-not-allowed gap-1 px-2 py-1"
                  onClick={() => handleBaggageSelection(data, ONE)}
                  disabled={!isAddButtonDisabled}
                  data-tooltip-id="baggage-tooltip"
                  data-tooltip-content={getErrorMessage()}
                >
                  {t("travelerInfo.add")}
                </button>
              ) : (
                <>
                  <button
                    disabled={!baggagesCount}
                    onClick={() => handleBaggageSelection(data, ZERO)}
                  >
                    <RenderSVG Svg={Minus} height="15" alt="Minus" />
                  </button>
                  <div>{baggagesCount}</div>
                  <button
                    className="disabled:cursor-not-allowed"
                    disabled={!isAddButtonDisabled}
                    onClick={() => handleBaggageSelection(data, ONE)}
                    data-tooltip-id="baggage-tooltip"
                    data-tooltip-content={getErrorMessage()}
                  >
                    <RenderSVG Svg={Plus} height="15" alt="Plus" />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaggageCard;
