import { useEffect, useLayoutEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  getFromLocalStorage,
  setToSessionStorage,
  getSSRPrice,
  checkIsInternationalFlight,
  getUniqueCountriesByIataCodes,
  getFromSessionStorage,
} from "../../../helper";
import {
  resetFlightPriceInfo,
  fetchFlightPrices,
  selectFlightPriceInfo,
  selectFlightPriceReq,
  selectSelectedTripType,
  selectFlightInfo,
} from "../../../screens/FlightResults";
import { selectSelectedModal, setSelectedModal } from "../Modal";
import {
  fetchSpecialServices,
  selectSelectedLCCBaggages,
  selectSelectedLCCMeals,
  selectSelectedLCCSeats,
  getPromoCodes,
  selectSpecialServices,
  setSpecialServices,
} from "../../../screens/Booking/FlightBookings";
import {
  fetchAmadeusFlights,
  fetchTBOFlights,
  selectSearchFilters,
  getHotels,
  selectHotelSearchFilters,
  selectFlights,
} from "../Search";
import renderModal from "../AppModals";
import renderDrawer from "../AppDrawers";
import {
  getRateExchanges,
  getTravelers,
  selectCountryInfo,
  selectExchangeRates,
} from "../../../screens/Profile";
import { actions as authActions } from "../../../screens/Auth/auth.reducer";
import { actions } from "../../../screens/FlightResults/flightResults.reducer";
import { selectSelectedDrawer } from "../Drawer";
import {
  getHotelInfo,
  selectHotelInfo,
  selectHotelInfoReqBody,
  selectPricePolicyReqBody,
} from "../../../screens/HotelInfo";
import { actions as flightBookingActions } from "../../../screens/Booking/FlightBookings/flightBookings.reducer";
import { cancelAllAPIRequests } from "../../../infrastructure/httpMethods/requestingMethods";
import { selectAuthInfo } from "../../../screens/Auth";
import { updateSessionInfo } from "../../../screens/session";
import { MODALS } from "../AppModals";
import {
  ROUTES,
  DEFAULT_VALUES,
  CACHE_KEYS,
  FLIGHT_SERVICE_TYPE,
  FARE_TYPES,
  isProdEnv,
  DEFAULT_CURRENCY,
} from "../../../constants";
import { verifyHotelPricePolicy } from "../../../screens/HotelInfo/hotelInfo.actions";

const { setSelectedTripType, setIsInternational } = actions;
const { setTotalSSRPrice } = flightBookingActions;
const { setAuthInfo } = authActions;

const { ZERO, EMPTY_STRING, EMPTY_OBJECT, ONE } = DEFAULT_VALUES;
const { AUTH, SESSION_ID, HOTEL_SEARCH_FILTERS, CURRENCY_INFO } = CACHE_KEYS;
const { HOME } = ROUTES;
const { TBO, AMADEUS } = FLIGHT_SERVICE_TYPE;
const { REGULAR } = FARE_TYPES;
const { SESSION_EXPIRED_MODAL } = MODALS;
const FLIGHT_DETAILS = "flightDetails";
const INTERNATIONAL = "international";
const DOMESTIC = "domestic";

const authInfo = getFromLocalStorage(AUTH);

const handleSessionCreation = (sessionId, endUserIp) => {
  const pageUrl = window.location.href;
  const url = new URL(pageUrl);
  const existingSessionId = new URLSearchParams(pageUrl).get(SESSION_ID);
  if (existingSessionId && existingSessionId !== "undefined") return;

  const encodedCurrentIP = btoa(endUserIp);
  const sessionParams = `${sessionId}_${encodedCurrentIP}`;
  window.location.pathname !== HOME &&
    url.searchParams.set(SESSION_ID, sessionParams);
  window.history.replaceState(null, null, url);
  const sessionData = {
    sessionParams,
    encodedCurrentIP,
    sessionId,
  };
  setToSessionStorage(SESSION_ID, sessionData);
};

const SidePanel = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let timeoutId = useRef(null);
  const selectedModal = useSelector(selectSelectedModal);
  const selectedDrawer = useSelector(selectSelectedDrawer);
  const flightPriceInfo = useSelector(selectFlightPriceInfo);
  const flightPriceReq = useSelector(selectFlightPriceReq);
  const storeState = useSelector((store) => store);
  const searchFilters = useSelector(selectSearchFilters);
  const tripType = useSelector(selectSelectedTripType);
  const selectSelectedFlightInfo = useSelector(selectFlightInfo);
  const selectedMeals = useSelector(selectSelectedLCCMeals);
  const selectedSeats = useSelector(selectSelectedLCCSeats);
  const selectedBaggages = useSelector(selectSelectedLCCBaggages);
  const selectedAuthInfo = useSelector(selectAuthInfo);
  const { ip: endUserIp } = useSelector(selectCountryInfo) || EMPTY_OBJECT;
  const flightResults = useSelector(selectFlights);
  const specialServicesDetails = useSelector(selectSpecialServices);
  const countryInfo = useSelector(selectCountryInfo);

  const hotelInfoReqBody = useSelector(selectHotelInfoReqBody);
  const pricePolicyReqBody = useSelector(selectPricePolicyReqBody);
  const hotelInfo = useSelector(selectHotelInfo);
  const exchangeRates = useSelector(selectExchangeRates);

  // TODO: Move Hotels logic to separate components
  const selectedHotelSearchFilters = useSelector(selectHotelSearchFilters);

  const isHomeScreen = location.pathname === HOME;
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);

  useEffect(() => {
    if (!authInfo) return;
    dispatch(setAuthInfo(authInfo));
  }, [dispatch, authInfo]);

  useLayoutEffect(() => {
    cancelAllAPIRequests();
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getRateExchanges());
  }, [countryInfo, dispatch]);

  useEffect(() => {
    if (!isEmpty(exchangeRates) && !isEmpty(countryInfo)) {
      const currencyInfo = get(countryInfo, "currency", DEFAULT_CURRENCY);
      setToSessionStorage(CURRENCY_INFO, {
        exchangeRate: exchangeRates[`${currencyInfo.code}`],
        ...currencyInfo,
      });
    }
  }, [exchangeRates, countryInfo]);

  useEffect(() => {
    !isEmpty(hotelInfoReqBody) && dispatch(getHotelInfo(hotelInfoReqBody));
  }, [hotelInfoReqBody]);

  useEffect(() => {
    !isEmpty(pricePolicyReqBody) &&
      dispatch(verifyHotelPricePolicy(pricePolicyReqBody));
  }, [pricePolicyReqBody]);

  useEffect(() => {
    if (!selectedAuthInfo?.id) return;
    const { id } = selectedAuthInfo;
    id && dispatch(getTravelers(id));
  }, [dispatch, selectedAuthInfo?.id]);
  useEffect(() => {
    if (tripType) return;
    const { tripType: optedTripType } = searchFilters;
    if (optedTripType) dispatch(setSelectedTripType(optedTripType));
  }, [tripType, searchFilters]);

  useEffect(() => {
    window.scrollTo(ZERO, ZERO);
  }, [location]);

  useEffect(() => {
    setShouldShowModal(selectedModal);
  }, [selectedModal]);

  useEffect(() => {
    setShouldShowDrawer(selectedDrawer);
  }, [selectedDrawer]);

  useEffect(() => {
    if (!isEmpty(searchFilters) && isEmpty(flightResults) && endUserIp) {
      const { value: regularFareType } = REGULAR;
      const { journeys, fareType = regularFareType, provider } = searchFilters;
      const countries = getUniqueCountriesByIataCodes([
        journeys[0].destCode.iata,
        journeys[0].originCode.iata,
      ]);
      const sessionId = uuid();
      const queryFilter = {
        ...searchFilters,
        endUserIp,
        journeys: journeys?.map((each) => ({
          ...each,
          originCode: get(each, "originCode.iata", EMPTY_STRING),
          destCode: get(each, "destCode.iata", EMPTY_STRING),
        })),
        travelType: countries.length > ONE ? INTERNATIONAL : DOMESTIC,
        key: "search",
        sessionId,
      };
      const flightsReqBody = { body: queryFilter };
      // NOTE: Disabled TBO Flights for Bancstac
      // if (fareType === regularFareType && provider !== TBO && !isProdEnv)
      dispatch(fetchAmadeusFlights(flightsReqBody)).then((res) => {
        if (res.payload) handleSessionCreation(sessionId, endUserIp);
      });
      // provider !== AMADEUS &&
      //   dispatch(fetchTBOFlights(flightsReqBody)).then(() =>
      //     handleSessionCreation(sessionId, endUserIp)
      //   );
      dispatch(resetFlightPriceInfo());
    }
  }, [dispatch, searchFilters, endUserIp]);

  useEffect(() => {
    if (!isEmpty(selectedHotelSearchFilters)) {
      setToSessionStorage(HOTEL_SEARCH_FILTERS, selectedHotelSearchFilters);
      dispatch(getHotels({ body: selectedHotelSearchFilters }));
    }
  }, [dispatch, selectedHotelSearchFilters]);

  useEffect(() => {
    if (!flightPriceReq || !isEmpty(flightPriceInfo)) return;
    dispatch(setSpecialServices([]));
    dispatch(fetchFlightPrices(flightPriceReq));
    const isInternational = checkIsInternationalFlight(
      flightPriceReq[ZERO].price.itineraries
    );
    dispatch(setIsInternational(isInternational));
  }, [dispatch, flightPriceReq, flightPriceInfo]);

  useEffect(() => {
    if (isEmpty(flightPriceReq) || !isEmpty(specialServicesDetails)) return;
    const priceReqForTBO = flightPriceReq.filter(
      (request) => request.source === TBO
    );
    if (
      priceReqForTBO?.every(
        ({ source, isReissuanceFlight }) =>
          source === TBO && !isReissuanceFlight
      )
    ) {
      const tboReqForSSR = priceReqForTBO.map(
        ({ tokenId, isLCC, traceId, endUserIp, resultIndex, source }) => ({
          source,
          isLCC,
          resultIndex,
          data: {
            TokenId: tokenId,
            TraceId: traceId,
            EndUserIp: endUserIp,
            ResultIndex: resultIndex,
          },
          commission: {},
        })
      );

      !isEmpty(flightPriceInfo) && dispatch(fetchSpecialServices(tboReqForSSR));
    }
  }, [dispatch, flightPriceReq, flightPriceInfo]);

  useEffect(() => {
    if (!isEmpty(flightPriceInfo) || !isEmpty(hotelInfo))
      dispatch(getPromoCodes());
  }, [dispatch, flightPriceInfo, hotelInfo]);

  useEffect(() => {
    if (!isEmpty(selectSelectedFlightInfo) && tripType)
      setToSessionStorage(FLIGHT_DETAILS, selectSelectedFlightInfo);
    else sessionStorage.removeItem(FLIGHT_DETAILS);
  }, [selectSelectedFlightInfo, tripType]);

  useEffect(() => {
    const TotalSSRPrice =
      getSSRPrice(selectedBaggages) +
      getSSRPrice(selectedMeals) +
      getSSRPrice(selectedSeats);

    dispatch(setTotalSSRPrice(TotalSSRPrice));
  }, [dispatch, selectedBaggages, selectedMeals, selectedSeats]);

  const syncSession = () => {
    // const searchParams = new URLSearchParams(window.location.search); Remove if not needed
    // const sessionId = searchParams.get(SESSION_ID);
    const sessionDetails = getFromSessionStorage(SESSION_ID) || EMPTY_OBJECT;
    const { decodedParentIP, sessionParams } = sessionDetails;
    const parentIp = decodedParentIP || endUserIp;
    if (sessionParams && parentIp) {
      const requestBody = { data: storeState, parentIp };
      dispatch(updateSessionInfo({ sessionParams, requestBody })).then(
        (res) => {
          if (!get(res, "payload.output", "")) {
            dispatch(setSelectedModal(SESSION_EXPIRED_MODAL));
            return;
          }
          setToSessionStorage(SESSION_ID, {
            ...sessionDetails,
            decodedParentIP: endUserIp,
            encodedParentIP: btoa(endUserIp),
          });
        }
      );
    }
  };

  const throttledSyncSession = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(syncSession, 2000);
  };

  useEffect(() => {
    throttledSyncSession();
    return () => clearTimeout(timeoutId);
  }, [storeState]);

  useEffect(() => {
    if (isHomeScreen) sessionStorage.removeItem(SESSION_ID);
  }, [location.pathname]);

  return (
    <>
      {shouldShowModal && renderModal(selectedModal)}
      {shouldShowDrawer && renderDrawer(selectedDrawer)}
    </>
  );
};

export default SidePanel;
