import { useState } from "react";
import { get, unescape } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";
import { getLeadGuestInfo, mapPaymentReq, loadScript } from "../../../helper";
import { selectCountryInfo } from "../../Profile";
import { RAZORPAY_CHECKOUT_SCRIPT_URL, ROUTES } from "../../../constants";
import { processPayment, setSelectedBooking } from "../index";
import {
  BookingSummary,
  BookingPricing,
  BookingImportantInfo,
  BookingLinks,
} from "../../../components/organisms/BookingInfoSections";
import { Lock, Confetti, RenderSVG } from "../../../assets/icons";

const { HOTEL_NON_VOUCHERED_PAYMENT } = ROUTES;

const CONFIRMED = "Confirmed";

const calculateAdultsAndChildren = (hotelRooms) => {
  let totalPassengers = {
    adults: 0,
    children: 0,
  };

  hotelRooms.forEach((room) => {
    const roomPassengers = room.HotelPassenger.reduce(
      (total, passenger) => {
        if (passenger?.PaxType === 1) total.adults = total.adults + 1;
        else total.children = total.children + 1;
        return total;
      },
      { adults: 0, children: 0 }
    );

    totalPassengers = {
      adults: totalPassengers.adults + roomPassengers.adults,
      children: totalPassengers.children + roomPassengers.children,
    };
  });

  return totalPassengers;
};

const SuccessfulBookingHeader = ({
  confirmationNo,
  tavaBookingId,
  bookingStatus,
  email,
  paymentId,
  hotelName,
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative bg-teal-200">
      <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-teal-100/50"></div>
      <div className="container px-8 pt-8 pb-16 mx-auto relative">
        {paymentId ? (
          <div>
            <div className="flex">
              <h4 className="text-xl text-contrast-900 font-bold mr-2">
                Congrats! Your booking with {hotelName} is confirmed
              </h4>
              <RenderSVG
                Svg={Lock}
                className="w-[25px] h-[25px] lg:block hidden"
                alt="lock-icon"
              />
            </div>
            <p className="text-sm font-medium mb-5 text-contrast-600">
              Enjoy your stay!
            </p>
          </div>
        ) : (
          <div>
            <div className="flex">
              <h4 className="text-xl text-contrast-900 font-bold mr-2">
                Your booking with {hotelName} is successful!
              </h4>
              <RenderSVG
                Svg={Confetti}
                className="w-[25px] h-[25px]"
                alt="confetti-icon"
              />
            </div>
            <p className="text-sm font-medium mb-5 text-contrast-600">
              Ensure confirming your booking by making payment before due date.
              Hope you have a great stay!
            </p>
          </div>
        )}
        <div className="flex items-center gap-2 mb-2">
          <p className="text-contrast-600 text-sm">
            {t("bookingResult.confirmationNumber")}:{" "}
            <span className="mx-1 font-semibold text-contrast-900">
              {confirmationNo}
            </span>
          </p>
          <p className="text-contrast-600 text-sm">
            Tava Booking ID:{" "}
            <span className="mx-1 font-semibold text-contrast-900">
              {tavaBookingId}
            </span>
          </p>
          <span className="font-semibold text-xs px-3 py-1 rounded-3xl text-teal-800 bg-teal-100">
            {bookingStatus}
          </span>
        </div>
        <div className="text-gray-800 text-sm">
          Confirmation and E-Vouchers sent to{" "}
          <span className="font-semibold text-teal-900">{email}</span>
        </div>
      </div>
    </div>
  );
};

const UnsuccessfulBookingHeader = ({ tavaBookingId }) => {
  const { t } = useTranslation();

  return (
    <div className="relative bg-red-200">
      <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-red-900/50"></div>
      <div className="container px-8 pt-8 pb-16 mx-auto relative">
        <h4 className="text-xl text-contrast-900 mb-1 font-bold">
          Booking Unsuccessful
        </h4>
        <div className="flex items-center gap-2 mb-2">
          <p className="text-contrast-600 text-sm">
            Tava Booking ID:{" "}
            <span className="mx-1 font-semibold text-contrast-900">
              {tavaBookingId}
            </span>
          </p>
        </div>
        <div className="w-full text-md bg-red-100 p-4 rounded-md text-red-600 my-1 font-medium">
          {t("bookingResult.failureMsg")}
        </div>
      </div>
    </div>
  );
};

const HotelBookingInfo = ({
  booking,
  isSpinnerActive,
}) => {
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPayNowSpinnerActive, setIsPayNowSpinnerActive] = useState(false);

  const {
    paymentId,
    bookingStatus,
    bookingId,
    confirmationNo,
    invoiceNo,
    bookingReqJson,
    blockRoomResJson,
    tavaBookingId,
  } = booking;

  const hotelRoomsInfo = get(
    blockRoomResJson,
    "BlockRoomResult.HotelRoomsDetails",
    []
  );
  const inclusions = hotelRoomsInfo.flatMap((room) => room.Inclusion);
  const splittedInclusions = inclusions.flatMap((inc) => inc.split(","));
  const starRating = get(blockRoomResJson, "BlockRoomResult.StarRating", 0);
  const lastCancellationDate = get(
    blockRoomResJson,
    "BlockRoomResult.HotelRoomsDetails[0].CancellationPolicies[0].FromDate",
    ""
  );
  const hotelName = get(bookingReqJson, "HotelName", "");
  const hotelCode = get(bookingReqJson, "HotelCode", "");
  const cancellationPolicies = get(hotelRoomsInfo, "0.CancellationPolicy", "");
  const cancellationMsgArr = cancellationPolicies
    ?.split("#^#")[1]
    ?.replaceAll("#!#", "")
    ?.split("|")
    ?.filter((item) => item && item !== "undefined")
    ?.map((msg) => (
      <li className="list-item" key={uuid()}>
        {msg}
      </li>
    ));

  const hotelPolicyInfo = get(
    blockRoomResJson,
    "BlockRoomResult.HotelPolicyDetail",
    ""
  );

  const { profileDetails: leadGuest } = getLeadGuestInfo(
    get(bookingReqJson, "HotelRoomsDetails.0.HotelPassenger", [])
  );

  const addressLine1 = get(
    blockRoomResJson,
    "BlockRoomResult.AddressLine1",
    ""
  );
  const addressLine2 = get(
    blockRoomResJson,
    "BlockRoomResult.AddressLine2",
    ""
  );

  const { title, firstName, lastName, email } = leadGuest;
  const paymentInfo = mapPaymentReq({
    userCountryInfo: selectedCountryInfo,
    blockRoomRes: blockRoomResJson,
    leadGuest,
  });
  const { amount, currency } = paymentInfo || {};

  const { adults, children } = calculateAdultsAndChildren(
    get(bookingReqJson, "HotelRoomsDetails", [])
  );
  const noOfRooms = hotelRoomsInfo.length;
  const isBookingSuccessful = bookingStatus === CONFIRMED;
  const decodedHotelInfo = unescape(hotelPolicyInfo);

  const CheckInDate = "";
  const NoOfNights = 0;
  const hotelImages = [];

  const initiatePayment = async () => {
    dispatch(setSelectedBooking(booking));
    const loadedScript = await loadScript(RAZORPAY_CHECKOUT_SCRIPT_URL);
    if (!loadedScript) return;

    setIsPayNowSpinnerActive(true);

    dispatch(processPayment({ body: paymentInfo })).then(() => {
      setIsPayNowSpinnerActive(false);
      navigate(HOTEL_NON_VOUCHERED_PAYMENT.replace(":hotelId", hotelCode));
    });
  };

  const hotelInfo = {
    hotelBookingStatus: bookingStatus,
    addressLine1,
    addressLine2,
    hotelImages,
    hotelName,
    hotelCode,
    starRating,
    cancellationMsgArr,
    decodedHotelInfo,
  };
  const bookingInfo = {
    lastCancellationDate,
    tavaBookingId,
    bookingId,
    paymentId,
    invoiceNo,
    checkInDate: CheckInDate,
    noOfNights: NoOfNights,
    noOfRooms,
    amount,
    currency,
    splittedInclusions,
  };
  const customerInfo = {
    firstName,
    lastName,
    title,
    email,
    adults,
    children,
  };

  return (
    <div className="relative z-0">
      {isBookingSuccessful ? (
        <SuccessfulBookingHeader
          confirmationNo={confirmationNo}
          tavaBookingId={tavaBookingId}
          bookingStatus={bookingStatus}
          email={email}
          paymentId={paymentId}
          hotelName={hotelName}
        />
      ) : (
        <UnsuccessfulBookingHeader tavaBookingId={tavaBookingId} />
      )}
      <div className="pb-16 relative">
        <div className="container px-8 mx-auto -mt-8">
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-12 xl:col-span-8 flex flex-col gap-4">
              <div className="flex flex-col gap-8">
                <BookingSummary
                  hotelInfo={hotelInfo}
                  bookingInfo={bookingInfo}
                  customerInfo={customerInfo}
                  isVoucherBooking={paymentId}
                  isSpinnerActive={isSpinnerActive}
                  isPayNowSpinnerActive={isPayNowSpinnerActive}
                  initiatePayment={initiatePayment}
                  showPayNowOption={!paymentId}
                />
              </div>
              <BookingPricing
                bookingInfo={bookingInfo}
                hotelRoomsInfo={hotelRoomsInfo}
                customerInfo={customerInfo}
              />
              <BookingImportantInfo decodedHotelInfo={decodedHotelInfo} />
            </div>
            <BookingLinks
              isSpinnerActive={isSpinnerActive}
              tavaBookingId={tavaBookingId}
              isVoucherBooking={paymentId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelBookingInfo;
