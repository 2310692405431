import { useEffect, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { get, debounce, isEmpty, isEqual } from "lodash";
import { Form, Formik, Field, useFormikContext } from "formik";
import { Tooltip } from "react-tooltip";
import * as yup from "yup";
import classNames from "classnames";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import {
  getIATALocations,
  resetFlights,
  setSearchFilters,
  selectIsNonStopSelected,
  setIsNonStopSelected,
  setSortedFlights,
} from "./index";
import OneWaySearch from "../OneWaySearch/OneWaySearch";
import RoundTripSearch from "../RoundTripSearch/RoundTripSearch";
import MultiCitySearch from "../MultiCitySearch/MultiCitySearch";
import { selectActiveSpinners, SPINNER_NAMES } from "../Spinner";
import JourneySummary from "../../molecules/JourneySummary";
import TripTypeSelector from "../../molecules/TripTypeSelector";
import {
  ChevronDown,
  ChevronUp,
  RenderSVG,
  SearchIcon,
} from "../../../assets/icons";
import {
  DEFAULT_VALUES,
  TRIP_TYPES,
  JOURNEY_DEFAULT_VALUES,
  FARE_TYPES,
  CACHE_KEYS,
  ROUTES,
  FLIGHTS_RESULT_TYPE,
  DEBOUNCE_TIME,
  INITIAL_FILTERS,
  DEFAULT_FLIGHT_SEARCH_OPTIONS,
} from "../../../constants";
import {
  getQueryParams,
  getObjectFromQueryParams,
  getFromLocalStorage,
  setToLocalStorage,
  setToSessionStorage,
} from "../../../helper";
import { actions } from "../../../screens/FlightResults/flightResults.reducer";
import { actions as filterActions } from "./search.reducer";
import {
  clearSelectedValues,
  selectBookingTravelers,
  setBookingTravelers,
  setSelectedTravelers,
} from "../../../screens/Booking/FlightBookings";
import { selectCountryInfo } from "../../../screens/Profile";
import { selectSortedFlights } from "./search.selectors";

const { EMPTY_STRING, EMPTY_OBJECT, EMPTY_ARRAY, ZERO, ONE } = DEFAULT_VALUES;
const { SEARCH_FILTERS, TRAVELERS_INFORMATION } = CACHE_KEYS;
const { ONE_WAY, ROUND_TRIP, MULTI_CITY } = TRIP_TYPES;
const { REGULAR } = FARE_TYPES;
const { FLIGHT_RESULTS, HOME } = ROUTES;
const { OUTBOUND_RESULT } = FLIGHTS_RESULT_TYPE;
const { FETCH_AMEDUES_FLIGHTS, FETCH_TBO_FLIGHTS } = SPINNER_NAMES;
const ECONOMY = "ECONOMY";
const ENTER_KEY_CODE = 13;
const { setSelectedFlightInfo, setCurrentFlightType, setSelectedTripType } =
  actions;
const { setActiveFilters, setFlightSearchOptions } = filterActions;

const searchButtonClassnames = {
  [ONE_WAY]: {
    className:
      "py-3 px-4 h-full items-center flex gap-2 rounded-md  justify-center text-sm font-medium text-white bg-primary-600 max-h-[54px] disabled:cursor-not-allowed",
    inputClassName: "lg:hidden xl:flex",
  },
  [ROUND_TRIP]: {
    className:
      "col-span-12 md:col-span-3 lg:col-span-2 xl:col-span-4 py-3 px-2 h-full items-center flex gap-2 rounded-md  justify-center text-sm font-medium text-white bg-primary-600 max-h-[54px] disabled:cursor-not-allowed",
    inputClassName: "lg:hidden xl:flex",
  },
  [MULTI_CITY]: {
    className:
      "py-3 px-4 h-full items-center flex gap-2 rounded-md justify-center text-sm font-medium text-white bg-primary-600 w-full sm:w-fit disabled:cursor-not-allowed",
    inputClassName: EMPTY_STRING,
  },
};

const getDetailsOfLastSession = () => {
  const lastSessionSearch = getFromLocalStorage(SEARCH_FILTERS);
  const journey = [{ ...JOURNEY_DEFAULT_VALUES, ...lastSessionSearch }];
  return {
    adult: ONE,
    children: ZERO,
    infants: ZERO,
    tripType: ROUND_TRIP,
    travelClass: ECONOMY,
    journey,
    fareType: REGULAR.value,
    isDirectFlight: false,
  };
};

const SearchButton = ({ tripType }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { initialValues, values } = useFormikContext();
  const isHomePage = location.pathname === HOME;
  const activeSpinners = useSelector(selectActiveSpinners);
  const isDisabled =
    (isEqual(initialValues, values) && !isHomePage) ||
    activeSpinners.some(
      (spinner) =>
        spinner === FETCH_AMEDUES_FLIGHTS || spinner === FETCH_TBO_FLIGHTS
    );

  const getToolTipMessage = () => {
    if (
      activeSpinners.some(
        (spinner) =>
          spinner === FETCH_AMEDUES_FLIGHTS || spinner === FETCH_TBO_FLIGHTS
      )
    )
      return "Searching flights...";
    else if (isEqual(initialValues, values) && !isHomePage && isDisabled)
      return "No changes made";
    else return null;
  };

  return (
    <button
      className={classNames(searchButtonClassnames[tripType].className, {
        "bg-secondary-600": isHomePage,
        "opacity-75": isDisabled,
      })}
      type="submit"
      disabled={isDisabled}
      data-tooltip-id="spinner-tooltip"
      data-tooltip-place="bottom"
      data-tooltip-content={getToolTipMessage()}
    >
      {isDisabled && (
        <Tooltip
          id="spinner-tooltip"
          className="!w-56 !sm:w-72 !bg-primary-600 !rounded-lg !z-50"
        />
      )}
      <RenderSVG Svg={SearchIcon} alt="Search Icon" color="white" />
      <span className={searchButtonClassnames[tripType].inputClassName}>
        {t("searchSection.findFlights")}
      </span>
    </button>
  );
};

const SearchSection = ({ showEditSearch = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonStopSelected = useSelector(selectIsNonStopSelected);
  const { search } = useLocation();
  const { t } = useTranslation();
  const { ip: endUserIp, currency } =
    useSelector(selectCountryInfo) || EMPTY_OBJECT;
  const bookingTravelers = useSelector(selectBookingTravelers);
  const lastSessionSearch = getDetailsOfLastSession();
  const [initialValues, setInitialValues] = useState(lastSessionSearch);
  const sortedFlights = useSelector(selectSortedFlights);
  const activeSpinners = useSelector(selectActiveSpinners);

  const isReissuanceFlight =
    search && getObjectFromQueryParams(search).searchType === "1";
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse({
      duration: 500,
      defaultExpanded: !showEditSearch,
    });

  const shouldShowNonStopToggle = !activeSpinners.some(
    (spinner) =>
      spinner === FETCH_AMEDUES_FLIGHTS || spinner === FETCH_TBO_FLIGHTS
  );

  useEffect(() => {
    if (!search) return;
    const searchFilters = getObjectFromQueryParams(search);
    if (isEmpty(searchFilters)) return;
    const {
      passengersCount,
      tripType: type,
      travelClass,
      journeys,
      fareType,
      isDirectFlight,
    } = searchFilters;

    const updatedValues = {
      adult: +get(passengersCount, "adult", ONE),
      children: +get(passengersCount, "children", ZERO),
      infants: +get(passengersCount, "infants", ZERO),
      tripType: type,
      travelClass: travelClass,
      journey: journeys,
      fareType,
      isDirectFlight,
    };

    const searchJourney = {
      destCode: journeys[0].destCode,
      originCode: journeys[0].originCode,
    };

    dispatch(setSearchFilters(searchFilters));
    setToLocalStorage(SEARCH_FILTERS, searchJourney);
    setInitialValues(updatedValues);
  }, [dispatch, search]);

  const handleNavigate = (queryString) =>
    navigate(`${FLIGHT_RESULTS}?${queryString}`, { replace: showEditSearch });

  const debouncedLocationChange = useMemo(() => {
    const fetchCityCodes = (mainLocationSearchValue) => {
      const locationParams = {
        limit: 12,
        search: mainLocationSearchValue.inputValue.replace(/[^a-zA-Z ]/g, ""),
        type: mainLocationSearchValue.type,
      };
      dispatch(getIATALocations(locationParams));
    };
    return debounce(fetchCityCodes, DEBOUNCE_TIME);
  }, []);

  useEffect(() => {
    dispatch(clearSelectedValues());
  }, [dispatch]);

  const handleLocationChange = (mainLocationSearchValue) =>
    debouncedLocationChange(mainLocationSearchValue);

  const validationSchema = yup.object().shape({
    adult: yup
      .number()
      .min(ONE)
      .required(t("validationSchemas.searchSection.adults")),
    children: yup
      .number()
      .test(
        "check-children-count",
        t("validationSchemas.searchSection.invalidFareTypeDetails"),
        (value, context) =>
          !(context.parent.fareType !== REGULAR.value && value > ZERO)
      ),
    infants: yup
      .number()
      .test(
        "check-infants-counts",
        t("validationSchemas.searchSection.invalidFareTypeDetails"),
        (value, context) => {
          if (context.parent.fareType !== REGULAR.value && value > ZERO)
            return false;
          return context.parent.adult >= value;
        }
      )
      .optional(),
    fareType: yup.string(),
    journey: yup.array().of(
      yup.object().shape({
        originCode: yup
          .object()
          .required(t("validationSchemas.searchSection.origin")),
        destCode: yup
          .object()
          .required(t("validationSchemas.searchSection.destination"))
          .test(
            "is-valid-loc",
            t("validationSchemas.searchSection.sameToAndFrom"),
            (value, context) => context.parent.originCode?.iata !== value?.iata
          ),
        returnDate: yup
          .string()
          .test(
            "is-valid-returndate",
            t("validationSchemas.searchSection.returnDate"),
            (value, context) => {
              return !(
                context.options.context.tripType === ROUND_TRIP &&
                new Date(value) < new Date(context.parent.departureDate)
              );
            }
          ),
        departureDate: yup
          .string()
          .test(
            "is-valid-departuredate",
            t("validationSchemas.searchSection.nextJourneyDate"),
            (value, context) => {
              return context.from[ONE].value.journey.every(
                (each, index) =>
                  index === ZERO ||
                  new Date(each.departureDate) >=
                    new Date(
                      context.from[ONE].value.journey[
                        index - ONE
                      ]?.departureDate
                    )
              );
            }
          ),
      })
    ),
  });

  const handleSearchFlights = useCallback(
    (values) => {
      const {
        adult,
        children,
        infants,
        journey,
        tripType: flightTripType,
        travelClass,
        fareType,
      } = values;
      setExpanded(false);
      dispatch(resetFlights());
      dispatch(setSelectedTripType(flightTripType));
      dispatch(setActiveFilters(INITIAL_FILTERS));
      dispatch(setFlightSearchOptions(DEFAULT_FLIGHT_SEARCH_OPTIONS));

      const queryFilters = {
        endUserIp,
        journeys: journey?.map((each) => ({
          ...each,
          originCode: get(each, "originCode.iata", EMPTY_STRING),
          destCode: get(each, "destCode.iata", EMPTY_STRING),
        })),
        passengersCount: {
          adult,
          children,
          infants,
        },
        tripType: flightTripType,
        travelClass,
        currencyCode: currency?.code,
        maxPrice: EMPTY_STRING,
        fareType,
        isDirectFlight: isNonStopSelected,
      };

      const queryStringFilters = { ...queryFilters, journeys: journey };
      const queryString = getQueryParams(queryStringFilters);
      dispatch(setCurrentFlightType(OUTBOUND_RESULT));
      dispatch(setSelectedFlightInfo(EMPTY_OBJECT));
      dispatch(setSelectedTravelers(EMPTY_ARRAY));
      const updatedbookingTravelers = bookingTravelers.map((traveler) => ({
        ...traveler,
        document: {
          number: EMPTY_STRING,
          type: EMPTY_OBJECT,
        },
      }));
      setToSessionStorage(TRAVELERS_INFORMATION, updatedbookingTravelers);
      dispatch(setBookingTravelers(updatedbookingTravelers));
      handleNavigate(queryString);
    },
    [dispatch, isNonStopSelected, bookingTravelers, currency]
  );

  const handleLocationSwap = (index, values, setFieldValue) => {
    setFieldValue(
      `journey[${index}].originCode`,
      values.journey[index].destCode,
      false
    );
    setFieldValue(
      `journey[${index}].destCode`,
      values.journey[index].originCode,
      false
    );
  };

  const handleNonStopToggle = (isNonStopSelected, values) => {
    let queryStringFilters;
    const {
      adult,
      children,
      infants,
      journey,
      tripType: flightTripType,
      travelClass,
      fareType,
    } = values;

    const queryFilters = {
      endUserIp,
      journeys: journey?.map((each) => ({
        ...each,
        originCode: get(each, "originCode.iata", EMPTY_STRING),
        destCode: get(each, "destCode.iata", EMPTY_STRING),
      })),
      passengersCount: {
        adult,
        children,
        infants,
      },
      tripType: flightTripType,
      travelClass,
      currencyCode: currency?.code,
      maxPrice: EMPTY_STRING,
      fareType,
      isDirectFlight: false,
    };

    if (Object.keys(sortedFlights).length) {
      if (isNonStopSelected) {
        const arePackagesPresent = !isEmpty(sortedFlights.packages);
        const areInboundFlightsPresent = !isEmpty(
          get(sortedFlights, "isolated.inbound", [])
        );
        const areOutboundFlightsPresent = !isEmpty(
          get(sortedFlights, "isolated.outbound", [])
        );
        const filteredPackages =
          arePackagesPresent &&
          sortedFlights.packages.filter((eachPackage) => {
            for (const itinerary of eachPackage.itineraries) {
              if (itinerary.segments.length !== 1) {
                return false;
              }
            }
            return true;
          });
        const filteredInbound =
          areInboundFlightsPresent &&
          sortedFlights.isolated.inbound.filter((eachPackage) => {
            return (
              eachPackage.itineraries.length === 1 &&
              eachPackage.itineraries[0].segments.length === 1
            );
          });
        const filteredOutbound =
          areOutboundFlightsPresent &&
          sortedFlights.isolated.outbound.filter((eachPackage) => {
            return (
              eachPackage.itineraries.length === 1 &&
              eachPackage.itineraries[0].segments.length === 1
            );
          });
        const filteredSortedFlights = {
          ...sortedFlights,
          ...(arePackagesPresent && { packages: filteredPackages }),
          isolated: {
            ...(areInboundFlightsPresent && { inbound: filteredInbound }),
            ...(areOutboundFlightsPresent && { outbound: filteredOutbound }),
          },
        };
        dispatch(setSortedFlights(filteredSortedFlights));
        queryStringFilters = {
          ...queryFilters,
          isDirectFlight: true,
          journeys: journey,
        };
      } else {
        dispatch(resetFlights());
        dispatch(setActiveFilters(INITIAL_FILTERS));
        queryStringFilters = {
          ...queryFilters,
          isDirectFlight: false,
          journeys: journey,
        };
      }
      const queryString = getQueryParams(queryStringFilters);
      handleNavigate(queryString);
    }
  };

  const handleKeyDown = (e) =>
    e.keyCode === ENTER_KEY_CODE && e.preventDefault();

  const renderHybridSearchBar = (tripType) => {
    switch (tripType) {
      case ROUND_TRIP:
        return (
          <RoundTripSearch
            handleLocationSwap={handleLocationSwap}
            handleLocationChange={handleLocationChange}
            footer={<SearchButton tripType={ROUND_TRIP} />}
          />
        );
      case ONE_WAY:
        return (
          <OneWaySearch
            handleLocationSwap={handleLocationSwap}
            handleLocationChange={handleLocationChange}
            footer={<SearchButton tripType={ONE_WAY} />}
          />
        );
      case MULTI_CITY:
        return (
          <MultiCitySearch
            handleLocationChange={handleLocationChange}
            footer={<SearchButton tripType={MULTI_CITY} />}
          />
        );
      default:
        return;
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSearchFlights}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form onKeyDown={handleKeyDown}>
          <div className="flex sm:flex-row flex-col gap-4 sm:items-center">
            {showEditSearch && !isExpanded && (
              <JourneySummary journey={values.journey} />
            )}
            {isExpanded && (
              <TripTypeSelector
                textColor={showEditSearch ? "text-contrast-900" : "text-white"}
              />
            )}
            {!isReissuanceFlight && shouldShowNonStopToggle && (
              <div className="flex items-center gap-2">
                <span
                  className={classNames(
                    "text-sm 2xl:text-lg lg:text-md text-contrast-900 ",
                    {
                      "text-contrast-900": showEditSearch,
                      "text-white": !showEditSearch,
                    }
                  )}
                >
                  {t("searchSection.nonstopOnly")}
                </span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <Field
                    type="checkbox"
                    name="isDirectFlight"
                    checked={isNonStopSelected}
                    onChange={(e) => {
                      setFieldValue("isDirectFlight", e.target.checked);
                      dispatch(setIsNonStopSelected(!isNonStopSelected));
                      showEditSearch &&
                        handleNonStopToggle(!isNonStopSelected, values);
                    }}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-secondary-200 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-secondary-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-secondary-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-secondary-600"></div>
                </label>
              </div>
            )}
            {!isReissuanceFlight && showEditSearch && (
              <button
                type="button"
                className="py-2 px-4 flex items-center justify-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium"
                {...getToggleProps()}
              >
                <span>{t("searchSection.editSearch")}</span>
                <RenderSVG
                  Svg={isExpanded ? ChevronUp : ChevronDown}
                  alt="Toggle dropdown"
                />
              </button>
            )}
          </div>
          <div {...getCollapseProps()}>
            <div className="pt-6 pb-1">
              {renderHybridSearchBar(values.tripType)}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SearchSection;
