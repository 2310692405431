import { getAirlineIconUrlByCarrierCode } from "../../helper";
import { useTranslation } from "react-i18next";
import { Cloud, RenderSVG, Baggage } from "../../assets/icons";
import { DEFAULT_VALUES } from "../../constants";

const { ZERO, EMPTY_STRING } = DEFAULT_VALUES;

const PackagedFlightDetails = ({
    arrival,
    startLocation,
    hasHighCO2Emission,
    baggage,
    getNoOfSeats }) => {

    const {
        carrierName,
        carrierCode,
        aircraftCode,
        flightNumber,
        departure = EMPTY_OBJECT,
    } = startLocation;

    const { t } = useTranslation();
    
    return (
        <div className="flex flex-col">
            <div className="flex items-center gap-2">
                <div className="logo">
                    <img
                        src={getAirlineIconUrlByCarrierCode(carrierCode)}
                        className="rounded-lg border border-gray-300 w-12 h-12"
                        alt={`${carrierCode}_logo`}
                    />
                </div>
                <div className="flex-1">
                    <h5 className="text-md font-semibold text-gray-900 mb-1">
                        {departure.time}
                        &nbsp; -&nbsp;
                        {arrival.time}
                    </h5>
                    <p className="text-xs text-contrast-600">
                        {carrierName || carrierCode}, {flightNumber}
                        {aircraftCode ? `| ${aircraftCode}` : EMPTY_STRING}
                    </p>
                </div>
            </div>
            <div className="flex flex-wrap gap-2">
                <div className="flex flex-row gap-2">
                    {hasHighCO2Emission && (
                        <div className="rounded-2xl py-0.5 px-2 bg-green-100 text-green-900 text-xs font-medium flex items-center gap-[6px]">
                            <RenderSVG
                                Svg={Cloud}
                                width="16"
                                height="16"
                                alt="cloud-icon"
                            />
                            <span>{t("flightResults.lowCo2")}</span>
                        </div>
                    )}
                </div>
            </div>
            <div className="mt-2 flex flex-row gap-2">
                <div className="text-xs font-semibold whitespace-nowrap bg-primary-100 text-primary-900 rounded-2xl py-0.5 px-2 flex items-center">
                    <RenderSVG
                        Svg={Baggage}
                        width="16"
                        height="16"
                        alt="baggage"
                    />
                    <span className="ml-2">
                        {t("flightResults.baggageAllowed")}{': '}
                        {Object.values(baggage)[ZERO]}
                    </span>
                </div>
                <div className="text-xs flex items-center font-semibold whitespace-nowrap text-red-500">
                    {getNoOfSeats()}
                </div>
            </div>
        </div>
    )
};

export default PackagedFlightDetails;
