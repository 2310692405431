import { DEFAULT_VALUES, CURRENCY_SYMBOLS } from "../../../constants";

const { EMPTY_STRING, ZERO } = DEFAULT_VALUES;

const BookingPricing = ({ bookingInfo, hotelRoomsInfo, customerInfo }) => {
    const {
        currency = EMPTY_STRING,
        amount = ZERO,
        splittedInclusions = EMPTY_STRING
    } = bookingInfo;
    const { adults = ZERO } = customerInfo;

    return (
        <div className="bg-white rounded-xl border border-gray-300 overflow-hidden">
        <div className="px-6 py-4 flex items-center border-b border-gray-300 bg-gray-50">
          <h4 className="text-base font-bold text-gray-900 flex-1">
            Total Price
          </h4>
          <span className="text-base font-bold text-[#4f46e5] whitespace-nowrap">
            {CURRENCY_SYMBOLS[currency]}
            {amount}
          </span>
        </div>
        <div className="p-6">
          <div className="border border-gray-200 divide-y divide-gray-200">
            <div className="flex items-center justify-between px-4 py-4">
              <div>
                <h4 className="text-base font-bold text-gray-900 flex-1">
                  {hotelRoomsInfo[0]?.RoomTypeName || EMPTY_STRING}
                </h4>
                <p className="text-xs text-gray-800">
                  <strong>{adults}</strong> Adult(s)
                </p>
              </div>
            </div>
            <div className="px-4 py-4">
              <h4 className="text-sm font-bold text-gray-900 flex-1 mb-3">
                Price Included
              </h4>
              <ul className="flex flex-col gap-1 font-medium">
                {splittedInclusions.map((each) => (
                  <li key={each} className="flex items-center gap-2">
                    <div className="p-0.5 bg-gray-500 rounded-full"></div>
                    <span className="text-xs text-gray-600">
                      {each.trim()}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
}

export default BookingPricing;
