import get from "lodash/get";
import countriesData from "../mockData/countriesData.json";
import {
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_USER_LANGUAGE,
  DEFAULT_USER_COUNTRY_CODE,
  DEFAULT_COUNTRY_INFO,
  DEFAULT_CURRENCY,
} from "../constants";
import { getFromLocalStorage } from "./getDataFromBrowserStorage";

const userLanguage = getFromLocalStorage("userLanguage");
const userCurrency = getFromLocalStorage("userCurrency");
const languageCode = get(userLanguage, "code", DEFAULT_LANGUAGE_CODE);
const languageName = get(userLanguage, "name", DEFAULT_USER_LANGUAGE);
const currency = get(userCurrency,"currency", DEFAULT_CURRENCY);

const setUserCurrentCountryConfigs = (
  countryCode = DEFAULT_USER_COUNTRY_CODE,
  ip = ""
) => {
  const userCountryInfo =
    countriesData.find((country) => country.code === countryCode) ||
    DEFAULT_COUNTRY_INFO;
  const activeUser = {
    ...userCountryInfo,
    ip,
    currency,
    language: {
      name: languageName,
      code: languageCode,
    },
    flag: `https://flagcdn.com/48x36/${countryCode.toLowerCase()}.png`,
  };
  return activeUser;
};
export default setUserCurrentCountryConfigs;
