import { useMemo, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { get, debounce, isEqual } from "lodash";
import { Formik, Form, useFormikContext } from "formik";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { selectActiveSpinners, SPINNER_NAMES } from "../Spinner";
import {
  getObjectFromQueryParams,
  getQueryParams,
  getNumberOfNights,
} from "../../../helper";
import {
  ChevronDown,
  ChevronUp,
  RenderSVG,
  SearchIcon,
} from "../../../assets/icons";
import {
  RoomAndGuestsCount,
  DateSelector,
  HotelsLocationPicker,
} from "../../molecules";
import { setHotels, setSearchHotelFilters } from "./index";
import { getHotelLocations } from "./search.actions";
import { selectCountryInfo } from "../../../screens/Profile";
import {
  SEARCH_SECTION,
  DEFAULT_VALUES,
  ROUTES,
  DEBOUNCE_TIME,
  HOTEL_DEFAULT_VALUES,
  DATE_FORMAT,
  HOTEL_DESTINATION_TYPES,
} from "../../../constants";
import { actions as modalActions } from "../Modal/modal.reducers";
import { actions as drawerActions } from "../Drawer/drawers.reducers";

const { HOTEL } = SEARCH_SECTION;
const { HOTEL_RESULTS, HOME } = ROUTES;
const { EMPTY_STRING, EMPTY_OBJECT, ONE } = DEFAULT_VALUES;
const { FETCH_HOTEL_RESULTS } = SPINNER_NAMES;
const { CITY } = HOTEL_DESTINATION_TYPES;
const { setSelectedDrawer } = drawerActions;
const { setSelectedModal } = modalActions;

const MAX_NUMBER_OF_NIGHTS = 31;

const formattedDate = (inputDate) => {
  const date = inputDate ? new Date(inputDate) : new Date();
  const day = date.getDate();
  const month = date.getMonth() + ONE;
  const year = date.getFullYear();

  return `${String(day).padStart(2, "0")}/${String(month).padStart(
    2,
    "0"
  )}/${year}`;
};

const SearchButton = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { initialValues, values } = useFormikContext();

  const isHomePage = location.pathname === HOME;
  const noOfNights = getNumberOfNights(
    new Date(values?.checkIn),
    new Date(values?.targetDate)
  );

  const activeSpinners = useSelector(selectActiveSpinners);
  const isDisabled =
    (isEqual(initialValues, values) && !isHomePage) ||
    noOfNights > MAX_NUMBER_OF_NIGHTS ||
    activeSpinners.some((spinner) => spinner === FETCH_HOTEL_RESULTS);

  const getToolTipMessage = () => {
    if (activeSpinners.some((spinner) => spinner === FETCH_HOTEL_RESULTS))
      return `${t("hotelResults.searching")}`;
    else if (isEqual(initialValues, values) && !isHomePage && isDisabled)
      return `${t("searchSection.noChanges")}`;
    else if (noOfNights > MAX_NUMBER_OF_NIGHTS)
      return `${t("hotelResults.maxNightsWarning")}`;
    else return null;
  };

  return (
    <button
      className={classNames(
        "py-3 px-4 w-full h-full items-center flex gap-2 rounded-md justify-center text-sm font-medium text-white bg-secondary-600 max-h-[54px]",
        {
          "opacity-75": isDisabled,
        }
      )}
      type="submit"
      disabled={isDisabled}
      data-tooltip-id="hotel-tooltip"
      data-tooltip-place="bottom"
      data-tooltip-content={getToolTipMessage()}
    >
      {isDisabled && (
        <Tooltip
          id="hotel-tooltip"
          className="!w-56 !sm:w-72 !bg-primary-600 !rounded-lg !z-50"
        />
      )}
      <RenderSVG Svg={SearchIcon} className="text-white" alt="Search Icon" />
      <span>{t("searchSection.findHotels")}</span>
    </button>
  );
};

const RenderSearchHotelSection = () => {
  const dispatch = useDispatch();
  const { values, setFieldValue } = useFormikContext();

  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + ONE);

  const minCheckoutDate = new Date(get(values, "checkIn"));
  minCheckoutDate.setDate(minCheckoutDate.getDate() + ONE);

  const debouncedLocationChange = useMemo(() => {
    const fetchHotels = (mainLocationSearchValue) => {
      const keyword = mainLocationSearchValue.inputValue
        ? mainLocationSearchValue.inputValue.replace(
            /[^a-zA-Z ]/g,
            EMPTY_STRING
          )
        : EMPTY_STRING;
      keyword && dispatch(getHotelLocations({ key: keyword }));
    };
    return debounce(fetchHotels, DEBOUNCE_TIME);
  }, []);

  const handlecheckInChange = (values) => {
    const startDate = get(values, "0");
    const endDate = get(values, "1");
    const formattedStartDate = startDate?.format(DATE_FORMAT);
    const formattedEndDate = endDate?.format(DATE_FORMAT);
    setFieldValue("checkIn", formattedStartDate);
    setFieldValue("targetDate", formattedEndDate);
  };

  const handlecheckOutChange = (values) => {
    const endDate = get(values, "1");
    const formattedEndDate = endDate?.format(DATE_FORMAT);
    setFieldValue("targetDate", formattedEndDate);
  };

  const handleLocationChange = (mainLocationSearchValue) =>
    debouncedLocationChange(mainLocationSearchValue);

  return (
    <div className="grid grid-cols-12 gap-4 2xl:gap-5 mt-[10px]">
      <div className="col-span-12 md:col-span-6 lg:col-span-3 gap-3 relative">
        <HotelsLocationPicker
          name="destination"
          handleLocationChange={handleLocationChange}
          source={HOTEL}
        />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-4">
        <div className="flex gap-3 divide-x divide-secondary-300">
          <span
            type="button"
            className="flex-1 rounded-lg bg-white flex items-center gap-3"
          >
            <DateSelector
              showPrices={false}
              placeholder="Check In"
              name="checkIn"
              height="53px"
              travelType={HOTEL}
              range={true}
              showCalendarIcon={true}
              minDate={tomorrowDate}
              defaultDates={[get(values, "checkIn"), get(values, "targetDate")]}
              handleChangeFromProps={handlecheckInChange}
            />
          </span>
          <span
            type="button"
            className="flex-1 rounded-lg bg-white flex items-center gap-3"
          >
            <DateSelector
              showPrices={false}
              placeholder="Check Out"
              name="targetDate"
              height="53px"
              travelType={HOTEL}
              showCalendarIcon={true}
              minDate={minCheckoutDate}
              defaultDates={[get(values, "targetDate")]}
              handleChangeFromProps={handlecheckOutChange}
            />
          </span>
        </div>
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-3 relative text-black rounded-md hover:ring-2 hover:ring-primary-50">
        <RoomAndGuestsCount />
      </div>
      <div className="col-span-12 md:col-span-6 lg:col-span-2 flex flex-col md:flex-row gap-3 2xl:gap-4">
        <SearchButton />
      </div>
    </div>
  );
};

const HotelSummary = () => {
  const { values } = useFormikContext();
  const {
    destination: { CityName, HotelName },
    checkIn,
    targetDate,
  } = values;

  return (
    <div className="flex flex-center 2xl:gap-6 gap-3 me-auto 2xl:text-base lg:text-sm text-xs text-contrast-900">
      <span>{HotelName || CityName}</span>
      <span>•</span>
      <span className="whitespace-pre">
        {checkIn}
        {targetDate && <span> - {targetDate}</span>}
      </span>
    </div>
  );
};

const getDestinationData = (destination) =>
  destination.Type === CITY
    ? { CityId: destination.DestinationId }
    : {
        HotelCode: destination.DestinationId,
        HotelName: destination.HotelName,
      };

const SearchHotelSection = ({
  showEditSearch = false,
  defaultExpandedValue = true,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const selectedCountryInfo = useSelector(selectCountryInfo) || {};
  const { ip: endUserIp } = selectedCountryInfo;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 100,
    defaultExpanded: defaultExpandedValue,
  });

  const [initialValues, setInitialValues] = useState(HOTEL_DEFAULT_VALUES);

  useEffect(() => {
    dispatch(setSelectedDrawer(null));
    dispatch(setSelectedModal(null));
  }, [dispatch]);

  useEffect(() => {
    if (!search) return;
    const {
      CheckInDate,
      CheckOutDate,
      NoOfNights,
      CountryCode,
      GuestNationality,
      CityId,
      HotelCode,
      HotelName,
      NoOfRooms,
      RoomGuests,
      CityName,
      DestinationType,
      GuestCountry,
      PreferredCurrency,
      MaxRating,
      MinRating,
    } = getObjectFromQueryParams(search);

    const formattedInitialValues = {
      checkIn: CheckInDate || EMPTY_STRING,
      noOfNights: NoOfNights || ONE,
      guestNationality: {
        isoCode: GuestNationality || EMPTY_STRING,
        name: GuestCountry || EMPTY_STRING,
      },
      destination: {
        CountryCode: CountryCode || EMPTY_STRING,
        DestinationId:
          Number(DestinationType) === CITY ? Number(CityId) : Number(HotelCode),
        CityName: CityName,
        Type: Number(DestinationType),
        ...(HotelName && { HotelName }),
      },
      noOfRooms: NoOfRooms || ONE,
      cityName: CityName || EMPTY_STRING,
      targetDate: CheckOutDate || EMPTY_STRING,
      RoomGuests,
    };

    const filtersFromQueryParam = {
      CheckInDate: formattedDate(CheckInDate),
      NoOfNights,
      CountryCode,
      HotelCode,
      ...(!HotelCode && { CityId }),
      PreferredCurrency,
      GuestNationality,
      NoOfRooms,
      RoomGuests,
      MaxRating,
      MinRating,
      EndUserIp: endUserIp,
      IsTBOMapped: "true",
    };
    search && dispatch(setSearchHotelFilters(filtersFromQueryParam));
    setInitialValues(formattedInitialValues);
  }, [search]);

  const handleNavigate = (queryString) =>
    navigate(`${HOTEL_RESULTS}?${queryString}`, { replace: true });

  const handleSearchHotels = useCallback(
    (values) => {
      const checkInDate = new Date(values.checkIn);
      const checkOutDate = new Date(values.targetDate);
      const numberOfNights = getNumberOfNights(checkInDate, checkOutDate);
      values.noOfNights = numberOfNights.toString();
      const {
        checkIn,
        noOfNights,
        guestNationality,
        noOfRooms,
        destination,
        targetDate,
        RoomGuests,
      } = values;

      const queryFilters = {
        CheckInDate: formattedDate(checkIn),
        CheckOutDate: formattedDate(targetDate),
        NoOfNights: noOfNights,
        CountryCode: destination.CountryCode,
        PreferredCurrency: get(selectedCountryInfo, "currency.code", "INR"),
        GuestNationality: guestNationality.isoCode,
        NoOfRooms: noOfRooms,
        RoomGuests,
        MaxRating: 5,
        MinRating: 0,
        IsTBOMapped: "true",
        EndUserIp: endUserIp,
        DestinationType: destination.Type,
        ...getDestinationData(destination),
      };
      const queryString = getQueryParams({
        ...queryFilters,
        CityName: destination.CityName,
        GuestCountry: get(guestNationality, "name", EMPTY_STRING),
        CheckOutDate: formattedDate(targetDate),
      });
      dispatch(setHotels(EMPTY_OBJECT));
      handleNavigate(queryString);
    },
    [dispatch]
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSearchHotels}
        enableReinitialize
      >
        {() => (
          <Form>
            <div className="flex sm:flex-row flex-col gap-4 sm:items-center">
              {showEditSearch && <HotelSummary />}
              {showEditSearch && (
                <button
                  type="button"
                  className="py-2 px-4 flex items-center justify-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium"
                  {...getToggleProps()}
                >
                  <RenderSVG
                    Svg={isExpanded ? ChevronUp : ChevronDown}
                    alt="Toggle dropdown"
                  />
                  <span>{t("searchSection.editSearch")}</span>
                </button>
              )}
            </div>
            <div {...getCollapseProps()}>
              <div>
                <RenderSearchHotelSection />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SearchHotelSection;
