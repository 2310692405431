import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { isEmpty, get } from "lodash";
import { selectUserBookingInfo, selectIsGenerateVoucherInitiated, selectSelectedBooking } from "../index";
import Spinner, { SPINNER_NAMES } from "../../../components/organisms/Spinner";
import HotelBookingInfo from "./HotelBookinginfo";
import FlightBookingInfo from "./FlightBookingInfo";
import { HotelBookingInfoSkeleton } from "../../../components/organisms/AppSkeletons";
import { WINDOWS_EVENTS, ROUTES, SEARCH_SECTION } from "../../../constants";

const { POPSTATE } = WINDOWS_EVENTS;
const { USER_BOOKINGS } = ROUTES;
const { FETCH_USER_BOOKING_INFO, GENERATE_VOUCHER } = SPINNER_NAMES;
const { HOTEL } = SEARCH_SECTION;

const UserBookingDetails = () => {
  const { division } = useParams();
  const navigate = useNavigate();

  const selectedUserBookingInfo = useSelector(selectUserBookingInfo);
  const selectedIsGenerateVoucherInitiated = useSelector(selectIsGenerateVoucherInitiated);
  const selectedBooking = useSelector(selectSelectedBooking);

  const [isSpinnerActive, setIsSpinnerActive] = useState(true);
  const [userBookingInfo, setUserBookingInfo] = useState({});

  useEffect(() => {
    if (!isEmpty(selectedUserBookingInfo))
      setUserBookingInfo(get(selectedUserBookingInfo, "result.0"));
  }, [selectedUserBookingInfo]);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    navigate(USER_BOOKINGS);
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener(POPSTATE, onBackButtonEvent);

    return () => window.removeEventListener(POPSTATE, onBackButtonEvent);
  }, []);

  return (
    <div>
      <Spinner
        name={[FETCH_USER_BOOKING_INFO, GENERATE_VOUCHER]}
        loaderComponent={<HotelBookingInfoSkeleton isGenerateVoucherInitiated={selectedIsGenerateVoucherInitiated} selectedBooking={selectedBooking} />}
        setIsSpinnerActive={setIsSpinnerActive}
      >
        {!isEmpty(userBookingInfo) && (
          <div>
            {division === HOTEL ? (
              <HotelBookingInfo
                booking={userBookingInfo}
                isSpinnerActive={isSpinnerActive}
              />
            ) : (
              <FlightBookingInfo booking={userBookingInfo} />
            )}
          </div>
        )}
      </Spinner>
    </div>
  );
};

export default UserBookingDetails;
