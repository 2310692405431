import { useState, useEffect } from "react";
import { get, uniqueId, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import DetailsCard from "./DetailsCard";
import {
  selectFlightPriceInfo,
  selectFlightPriceReq,
} from "../../../screens/FlightResults";
import FlightJourneyDetails from "../../../screens/FlightResults/FlightJourneyDetails";
import {
  selectSelectedLCCBaggages,
  selectSelectedLCCMeals,
  selectSelectedLCCSeats,
  selectTotalSSRPrice,
  selectSelectedPromoCode,
  setPromoCode,
} from "../../../screens/Booking/FlightBookings";
import Tabs from "../../organisms/Tabs/Tabs";
import {
  selectSearchFilters,
  selectSelectedReIssuanceFlight,
} from "../../../components/organisms/Search";
import {
  ChevronDown,
  ChevronUp,
  RenderSVG,
  DiscountIcon,
  Cross,
} from "../../../assets/icons";
import {
  getFormattedLongDate,
  getQueryParams,
  getStopLabel,
  getSSRPrice,
  priceFormatter,
  timeFormatter,
  getSurchargesAmount,
  getPriceAfterDiscount,
  convertedPricesFromStrings,
} from "../../../helper";
import FareRulesModal from "../../organisms/FareRuleDetails";
import { selectCountryInfo } from "../../../screens/Profile";
import Spinner, { SPINNER_NAMES } from "../../organisms/Spinner";
import { CURRENCY_SYMBOLS, DEFAULT_VALUES, ROUTES } from "../../../constants";
import config from "../../../config.json";
import { getPlatformFees } from "../../../helper/priceFormatter";

const { EMPTY_ARRAY, ZERO, ONE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { FLIGHT_RESULTS } = ROUTES;
const { PRICE } = SPINNER_NAMES;
const { INR } = CURRENCY_SYMBOLS;
const { brandName } = config;

const INITIAL_FARE_CHARGES = {
  totalBase: ZERO,
  totalTax: ZERO,
  totalGrand: ZERO,
  reissuancePrice: ZERO,
  reissueDifference: ZERO,
};

const reissuanceFlightOptions = [
  { label: "youPaid", key: "previousFlightPrice", primary: true },
  { label: "differenceAmount", key: "reissueDifference", primary: true },
  { label: "reissueChargesBySupplier", key: "reissuancePrice", primary: true },
];

const flightFareOptions = [
  { label: "baseFare", key: "totalBase", primary: true },
  { label: "taxAndFees", key: "totalTax", primary: false },
  { label: "otherServices", key: "selectedTotalSSRPrice", primary: false },
  { label: "surcharges", key: "surcharges", primary: false },
];

const getFlightTotalPrice = (data) =>
  data.reduce((total, { price }) => {
    total += price.price.grandTotal;
    return total;
  }, ZERO);

const getUpdatedFlightCharges = (flightPriceInfo = []) =>
  flightPriceInfo.reduce(
    (totals, { price, taxes }) => ({
      totalBase: totals.totalBase + Number(price.basePrice),
      totalTax:
        totals.totalTax + Number(taxes.taxAmount) + Number(taxes.otherCharges),
      totalGrand: totals.totalGrand + Number(price.grandTotal),
      reissuancePrice:
        totals.reissuancePrice + price.reissuancePrice
          ? Number(price.reissuancePrice)
          : ZERO,
      reissueDifference:
        totals.reissueDifference + price.reissueDifference
          ? Number(price.reissueDifference)
          : ZERO,
    }),
    INITIAL_FARE_CHARGES
  );

const formatPrice = (price, currencySymbol) =>
  `${price < ZERO ? "-" : ""} ${currencySymbol}${priceFormatter(
    parseFloat(Math.abs(price).toFixed(2))
  )}`;

const getTotalPaxCount = (passengers = {}) =>
  Object.values(passengers).reduce((total, pax) => {
    total = total + +pax;
    return total;
  }, ZERO);

const FlightPricingSegmentsCard = ({ segments = [] }) => {
  const { t } = useTranslation();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 500,
    defaultExpanded: false,
  });
  const firstSegment = segments[ZERO];
  const lastSegment = segments[segments.length - ONE];
  const totalStops = segments.length - ONE;

  const { departure = EMPTY_OBJECT } = firstSegment || EMPTY_OBJECT;
  const { arrival = EMPTY_OBJECT } = lastSegment || EMPTY_OBJECT;

  return (
    <div className="mb-6 bg-white rounded-lg border border-contrast-200 px-4">
      <div>
        <div className="p-2  flex justify-between items-center">
          <div className="flex flex-col justify-start">
            <div className="text-lg font-semibold text-contrast-900 mb-1">
              {departure.airportName}
              &nbsp;
              {departure?.iataCode} -&nbsp;
              {arrival.airportName}
              &nbsp;
              {arrival.iataCode}
            </div>
            <div className="font-normal text-base text-contrast-600">
              {firstSegment.carrierName} •&nbsp;
              {getFormattedLongDate(arrival.date, {
                day: "numeric",
                month: "short",
              })}
              &nbsp;•&nbsp;
              {timeFormatter(departure.time)}/{timeFormatter(arrival.time)}{" "}
              •&nbsp;
              {getStopLabel(totalStops, t)}
            </div>
          </div>
          <div {...getToggleProps()}>
            <RenderSVG
              Svg={isExpanded ? ChevronUp : ChevronDown}
              alt="chevron-up-down"
              style={{ color: "black" }}
              height={15}
              width={15}
            />
          </div>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="px-2 py-1">
          <FlightJourneyDetails segments={segments} />
        </div>
      </div>
    </div>
  );
};

export const PricingDetailsSummaryCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appliedPromoCode = useSelector(selectSelectedPromoCode) || EMPTY_OBJECT;
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const selectedTotalSSRPrice = useSelector(selectTotalSSRPrice);
  const selectedReissuanceFlight = useSelector(selectSelectedReIssuanceFlight);
  const flightPriceInfo = useSelector(selectFlightPriceInfo);
  const flightPriceReq = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;
  const [previousFareCharges, setPreviousFareCharges] =
    useState(INITIAL_FARE_CHARGES);
  const [updatedFareCharges, setUpdatedFareCharges] = useState(EMPTY_OBJECT);
  const [priceMapping, setPriceMapping] = useState(EMPTY_ARRAY);

  const selectedFlightTotalPrice = getFlightTotalPrice(flightPriceReq);
  const initialSurcharges = getSurchargesAmount(selectedFlightTotalPrice);
  const defaultFinalCharges = {
    surcharges: initialSurcharges,
    discountAmount: ZERO,
    updatedPrice: selectedFlightTotalPrice + initialSurcharges,
  };
  const [finalCharges, setFinalCharges] = useState(defaultFinalCharges);
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  const { isReissuanceFlight } = flightPriceReq[ZERO] || EMPTY_OBJECT;
  const { tava_code } = appliedPromoCode;
  const requiredOptions = isReissuanceFlight
    ? reissuanceFlightOptions
    : flightFareOptions;

  const { basePrice: platformFee } = getPlatformFees();

  useEffect(() => {
    if (isEmpty(flightPriceReq)) return;
    const previousFareCharges1 = {
      ...INITIAL_FARE_CHARGES,
      totalBase: selectedFlightTotalPrice,
      totalGrand: selectedFlightTotalPrice,
    };
    setPreviousFareCharges(previousFareCharges1);
  }, [flightPriceReq]);

  useEffect(() => {
    if (isEmpty(flightPriceInfo)) return;
    const latestPrices =
      !isEmpty(flightPriceInfo) && getUpdatedFlightCharges(flightPriceInfo);
    setUpdatedFareCharges(latestPrices);
  }, [previousFareCharges, flightPriceInfo]);

  useEffect(() => {
    if (isEmpty(updatedFareCharges)) return;
    const { totalGrand } = updatedFareCharges || EMPTY_OBJECT;
    const finalPrices = getPriceAfterDiscount(appliedPromoCode, totalGrand);
    const surcharges = getSurchargesAmount(totalGrand + selectedTotalSSRPrice);
    setFinalCharges({
      ...finalPrices,
      updatedPrice: finalPrices.updatedPrice + surcharges,
      surcharges,
    });
  }, [appliedPromoCode, updatedFareCharges, selectedTotalSSRPrice]);

  useEffect(() => {
    if (isEmpty(finalCharges) || isEmpty(appliedPromoCode)) return;
    const { discountAmount, updatedPrice } = finalCharges || EMPTY_OBJECT;
    if (updatedPrice !== appliedPromoCode.offeredPrice) {
      const promoCode = {
        ...appliedPromoCode,
        offeredPrice: updatedPrice,
        ...(discountAmount && { discountAmount }),
      };
      dispatch(setPromoCode(promoCode));
    }
  }, [dispatch, appliedPromoCode]);

  const getFareValue = (key, fareChargesObject) => {
    const previousFlightPrice = get(
      selectedReissuanceFlight,
      "bookingJSON.bookingRequest.price.grandTotal",
      ZERO
    );
    switch (key) {
      case "previousFlightPrice":
        return previousFlightPrice;
      case "selectedTotalSSRPrice":
        return parseInt(selectedTotalSSRPrice);
      case "surcharges":
        return finalCharges["surcharges"];
      default:
        return fareChargesObject[key];
    }
  };

  useEffect(() => {
    const formattedOptions = requiredOptions.map(({ label, key, primary }) => {
      const fareCharges = isEmpty(updatedFareCharges)
        ? previousFareCharges
        : updatedFareCharges;
      const requiredfare = getFareValue(key, fareCharges);
      const card = {
        label: t(`travelerInfo.fareCard.${label}`),
        value: formatPrice(requiredfare, currencySymbol),
        hidePrice: !primary && !requiredfare,
      };
      return card;
    });
    setPriceMapping(formattedOptions);
  }, [updatedFareCharges, previousFareCharges, finalCharges]);

  return (
    <div>
      {priceMapping.map(
        ({ label, hidePrice, value }) =>
          !hidePrice && (
            <div key={label} className="flex items-center justify-between py-1">
              <div className="text-contrast-900 text-base font-normal">
                {label}
              </div>
              <div className="font-medium">{value}</div>
            </div>
          )
      )}
      {tava_code && (
        <div className="flex items-center justify-between py-1 rounded text-primary-600">
          <div className="flex items-center justify-between text-base font-normal">
            <RenderSVG
              Svg={DiscountIcon}
              alt="discount"
              className="w-8 h-6 pr-2 relative"
            />
            {t("travelerInfo.fareCard.appliedPromocode", { code: tava_code })}
          </div>
          <div className="flex items-center justify-between font-medium text-green-600">
            - {currencySymbol}
            {priceFormatter(appliedPromoCode.discountAmount)}
            <button
              type="button"
              className="flex items-center justify-end pl-2"
              onClick={() => dispatch(setPromoCode(EMPTY_OBJECT))}
            >
              <RenderSVG
                Svg={Cross}
                alt="Check Icon"
                className="h-4 relative rounded-full"
                color="#FF0000"
              />
            </button>
          </div>
        </div>
      )}
      <div className="flex items-center justify-between py-1">
        <div className="text-contrast-900 text-base font-normal">
          {`${brandName} ${t("travelerInfo.fee")}`}
        </div>
        <div className="font-medium">
          {currencySymbol}
          {platformFee}
        </div>
      </div>
      <div className="flex items-center justify-between pt-1">
        <div className="text-primary-600 text-xl font-bold">
          {t("travelerInfo.fareCard.totalDue")}
        </div>
        <div className="text-primary-600 text-xl font-bold">
          {currencySymbol}
          {priceFormatter(
            (
              finalCharges.updatedPrice +
              selectedTotalSSRPrice +
              platformFee
            ).toFixed(2)
          )}
        </div>
      </div>
    </div>
  );
};

const TripDetailsTab = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const selectedFilters = useSelector(selectSearchFilters);
  const priceRequest = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;
  const [shouldShowFareRules, setShouldShowFareRules] = useState(false);
  const { isReissuanceFlight } = priceRequest[ZERO] || EMPTY_OBJECT;
  const { tripType, passengersCount } = selectedFilters || EMPTY_OBJECT;

  const totalCount = getTotalPaxCount(passengersCount);

  const handleOpenFareRuleModel = () => setShouldShowFareRules(true);

  return (
    <div className="py-6">
      <div className="px-6">
        <div className="flex items-center justify-between pb-2">
          <div className="text-xl font-bold text-contrast-900">
            {t(`searchSection.tripTypes.${tripType}`)} • {totalCount}{" "}
            {totalCount > ONE
              ? t("travelerInfo.travelers")
              : t("travelerInfo.traveler")}
          </div>
          {!isReissuanceFlight && (
            <div>
              <button
                className="py-2 px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-700 font-medium"
                onClick={() =>
                  navigate(
                    `${FLIGHT_RESULTS}?${getQueryParams(selectedFilters)}`
                  )
                }
              >
                {t("travelerInfo.editTrip")}
              </button>
            </div>
          )}
        </div>
        <div className="grid py-2 grid-cols-2">
          <div className="mt-3">
            <hr />
          </div>
          <div className="flex justify-end">
            <Spinner name={PRICE} loaderComponent={<></>}>
              <button
                className="flex text-xs sm:text-sm text-end text-primary-600 justify-end"
                onClick={handleOpenFareRuleModel}
              >
                {t("fareRules.viewFareRule")}
              </button>
            </Spinner>
          </div>
        </div>
        <div>
          {priceRequest.map(({ price }) =>
            price.itineraries.map(({ segments }) => (
              <FlightPricingSegmentsCard segments={segments} key={uniqueId()} />
            ))
          )}
        </div>
        <hr className="py-3" />
        <PricingDetailsSummaryCard />
      </div>
      {shouldShowFareRules && (
        <FareRulesModal
          shouldShowFareRules={shouldShowFareRules}
          setShouldShowFareRules={setShouldShowFareRules}
        />
      )}
    </div>
  );
};

const CostBreakDownTab = () => {
  const { t } = useTranslation();
  const flightPriceInfo = useSelector(selectFlightPriceInfo);
  const selectedMeals = useSelector(selectSelectedLCCMeals);
  const selectedSeats = useSelector(selectSelectedLCCSeats);
  const selectedBaggages = useSelector(selectSelectedLCCBaggages);
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  const SSRObject = {
    Meals: selectedMeals,
    Seats: selectedSeats,
    Baggages: selectedBaggages,
  };
  const { travelerPricings = EMPTY_ARRAY } =
    flightPriceInfo[ZERO] || EMPTY_OBJECT;

  const reducedPrices = travelerPricings.reduce((intialVal, each) => {
    intialVal[each.travelerType] = each.travelerType
      ? {
          count: each.travelersCount,
          price: each.price.basePrice,
        }
      : { count: ONE, price: +each.price.basePrice };

    return intialVal;
  }, {});

  const formattedReducedPrices = Object.entries(reducedPrices)?.reduce(
    (formattedReducedPrices, [key, value]) => {
      formattedReducedPrices[
        `${value.count} X ${key.toLowerCase()}`
      ] = `${currencySymbol} ${priceFormatter(value.price)}`;
      return formattedReducedPrices;
    },
    {}
  );

  const getSSRPricing = Object.entries(SSRObject).reduce(
    (acc, [key, value]) => {
      if (!isEmpty(value))
        acc[key] = `${currencySymbol} ${priceFormatter(getSSRPrice(value))}`;
      return acc;
    },
    {}
  );

  const pricingDetails = [
    {
      heading: t("travelerInfo.fareCard.fares"),
      data: formattedReducedPrices,
    },
    {
      heading: t("travelerInfo.fareCard.extraServices"),
      shouldShow: !isEmpty(getSSRPricing),
      data: getSSRPricing,
    },
  ];

  return pricingDetails.map((data, index) => (
    <DetailsCard key={data.heading} values={data} cardPosition={index} />
  ));
};

const TripOverviewCard = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1,
      title: t("travelerInfo.trip"),
      element: <TripDetailsTab />,
      default: true,
    },
    {
      id: 2,
      title: t("travelerInfo.costBreakdown"),
      element: <CostBreakDownTab />,
      default: false,
    },
  ];

  return (
    <div className="bg-white rounded-lg border border-gray-200 mb-4">
      <Tabs tabs={tabs} />
    </div>
  );
};

export const OtherInfo = ({ header, data }) => {
  const { tableHeader, penalties, penaltyInfo } = data;

  return (
    <div className="bg-white rounded">
      <div className="font-semibold border-b-[1px] p-2">{header}</div>
      <div className="m-2 border">
        <table className="w-full">
          <thead className="text-sm md:text-xs">
            <tr>
              {tableHeader.map(({ title, subtitle }) => (
                <th key={title} className="py-2 border-b text-start">
                  <div className="text-base font-semibold">{title}</div>
                  <div className="text-sm text-gray-500">{subtitle}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-xs text-start">
            {penalties?.map(({ timeRange, charges }, index) => (
              <tr
                key={timeRange}
                className={
                  index % 2 === 0 ? "bg-gray-50" : "bg-white hover:bg-gray-100"
                }
              >
                <td className="py-1.5 border-t border-b">{timeRange}</td>
                <td className="py-1.5 border-t border-b">
                  {convertedPricesFromStrings(charges)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-2 text-xs font-normal text-black">{penaltyInfo}</div>
      </div>
    </div>
  );
};

export default TripOverviewCard;
