import { get } from "lodash";
import { DEFAULT_VALUES, GUEST_TYPES } from "../constants";

const { ZERO } = DEFAULT_VALUES;
const { ADULT, CHILD } = GUEST_TYPES;

export const mapGuestDetails = (
  leadGuestInfo,
  otherGuestsInfo,
  isSamePANForAllAllowed,
  RoomGuests,
  index
) => {
  const {
    title = "",
    firstName = "",
    middleName = "",
    lastName = "",
    email = "",
    phoneNumber = "",
  } = get(leadGuestInfo, "profileDetails", {});
  const passportNumber = get(
    leadGuestInfo,
    "passportDetails.passportNumber",
    null
  );
  const leadGuestPAN = get(leadGuestInfo, "panCardDetails.panCardNumber", null);
  let adultGuests = [];
  let childrenGuests = [];

  otherGuestsInfo.forEach((guest) => {
    const {
      roomIndex,
      profileDetails: { isChild },
    } = guest;
    if (roomIndex === index) {
      isChild ? childrenGuests.push(guest) : adultGuests.push(guest);
    }
  });
  const firstAdultGuestId = adultGuests[ZERO]?.id;

  let mappedGuestDetails =
    index === ZERO
      ? [
          {
            Title: title,
            FirstName: firstName,
            Middlename: null,
            LastName: lastName,
            Phoneno: phoneNumber,
            Email: email,
            PaxType: ADULT,
            LeadPassenger: true,
            PassportNo: passportNumber,
            PAN: leadGuestPAN,
          },
        ]
      : [];

  adultGuests.forEach((guest) => {
    const {
      title = "",
      firstName = "",
      lastName = "",
    } = get(guest, "profileDetails", {});
    const passportNumber = get(guest, "passportDetails.passportNumber", null);
    const panCardNumber = get(guest, "panCardDetails.panCardNumber", null);

    const formattedGuestInfo = {
      Title: title,
      FirstName: firstName,
      Middlename: middleName,
      LastName: lastName,
      Phoneno: phoneNumber,
      Email: email,
      PaxType: ADULT,
      LeadPassenger: index > ZERO && guest.id === firstAdultGuestId,
      PassportNo: passportNumber,
      PAN: isSamePANForAllAllowed ? leadGuestPAN : panCardNumber,
    };
    mappedGuestDetails.push(formattedGuestInfo);
  });

  childrenGuests.forEach((guest, childIndex) => {
    const {
      title = "",
      firstName = "",
      middleName = null,
      lastName = "",
    } = get(guest, "profileDetails", {});
    const passportNumber = get(guest, "passportDetails.passportNumber", null);
    const panCardNumber = get(guest, "panCardDetails.panCardNumber", null);

    const formattedGuestInfo = {
      Title: title,
      FirstName: firstName,
      Middlename: middleName,
      LastName: lastName,
      Phoneno: phoneNumber,
      Email: email,
      PaxType: CHILD,
      Age: get(RoomGuests, `${index}.ChildAge[${childIndex}]`, ZERO),
      LeadPassenger: false,
      PassportNo: passportNumber,
      PAN: isSamePANForAllAllowed ? leadGuestPAN : panCardNumber,
    };
    mappedGuestDetails.push(formattedGuestInfo);
  });

  return mappedGuestDetails;
};
