import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { get} from "lodash";
import {
  Cross,
  RenderSVG,
  ExclamationCircleIcon,
} from "../../../../assets/icons";
import { processRefund } from "../../../../screens/MyTrips";
import getProcessRefundRequestMapping from "../../../../RequestMappers/ProcessRefundRequestMapping";
import { setSelectedModal } from "../../Modal";
import Spinner, { SPINNER_NAMES, selectActiveSpinners } from "../../Spinner";
import {
  BOOKING_CANCELLATION_STATES,
} from "../../../../constants";
import { selectSelectedBooking } from "../../../../screens/MyTrips/booking.selector";
import { fetchBookings } from "../../../../screens/Admin/admin.actions";

const { BOOKING_REFUND } = SPINNER_NAMES;
const { SUCCESS, FAILURE } =
  BOOKING_CANCELLATION_STATES;


const ConfirmModal = ({ setCurrentModal=()=>{} }) => {
    const [isSpinnerActive, setIsSpinnerActive] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const booking = useSelector(selectSelectedBooking);
    const activeSpinners = useSelector(selectActiveSpinners);
    const processRefundBody = getProcessRefundRequestMapping(booking);
    const tavaBookingId = get(booking, "tavaBookingId", "");
    const refundSpinner = activeSpinners.some(
      (spinnerName) => spinnerName === BOOKING_REFUND
    )

    const handleClose = () => {
      dispatch(setSelectedModal());
    };

    const handleAction = () => {
      dispatch(
        processRefund({ body: processRefundBody, tavaBookingId: tavaBookingId })
      ).then((res) => {
        const amadeusResponse = get(
          res,
          "payload.void[0].output.pickedValue.PNR_Reply",
          ""
        );
        const tboResponse = get(res, "payload.void[0].cancellation.status", "");
        setCurrentModal(
          !(amadeusResponse || tboResponse === "SUCCESSFUL") ? FAILURE : SUCCESS
        );
        dispatch(
          fetchBookings({
            pageSize: 100,
          })
        );
      });
    };

    return (
      <div className="bg-white">
        <div className="w-full p-6 border-b border-contrast-200 justify-start items-center gap-4 inline-flex">
          <div className="rounded-full bg-red-50 p-2 w-10 h-10">
          <RenderSVG
              Svg={ExclamationCircleIcon}
              width="24"
              height="24"
              className="text-[25px]"
            />
          </div>
          <div className="grow shrink basis-0 text-contrast-900 text-2xl font-bold font-['Inter'] leading-loose">
           {t("userBookings.cancel.confirmationModal.header")}
          </div>
          <button
            type="button"
            className="p-[9px] rounded-[19px] justify-center items-center flex disabled:cursor-not-allowed"
            onClick={handleClose}
            disabled={activeSpinners.some(
              (spinnerName) => spinnerName === BOOKING_REFUND
            )}
          >
            <RenderSVG Svg={Cross} alt="cross" />
          </button>
        </div>

        <div className="p-6 gap-4 inline-flex">
          <div className="self-stretch text-contrast-500 text-base font-normal font-['Inter'] leading-tight">
            <div className="text-sm text-contrast-400 text-[#959aa4]">
             <div>{t("userBookings.cancel.confirmationModal.description")}</div>
             <div>{t("userBookings.cancel.confirmationModal.warning")}</div>
            </div>
          </div>
        </div>
        <div className="w-full p-6 border-t border-contrast-200 justify-start items-center gap-2 inline-flex">
          <div className="grow shrink basis-0 h-[38px] justify-end items-start gap-4 flex">
            <button
              type="button"
              className="h-9 px-2 bg-white rounded-md shadow border border-contrast-300 justify-center items-center flex hover:bg-zinc-300 disabled:cursor-not-allowed"
              onClick={handleClose}
              disabled={refundSpinner}
            >
              <div className="text-contrast-700 text-sm font-medium font-['Inter'] leading-tight">
              {t("userBookings.cancel.confirmationModal.no")}
              </div>
            </button>

            <button
              type="button"
              className="h-9 w-52 rounded-md shadow border border-contrast-300 justify-center items-center bg-red-600 hover:bg-red-700 disabled:cursor-not-allowed disabled:bg-red-300 disabled:text-gray-300"
              onClick={handleAction}
              disabled={isSpinnerActive}
            >
              <Spinner
                name={BOOKING_REFUND}
                setIsSpinnerActive={setIsSpinnerActive}
              >
                <div className="text-white text-sm font-medium font-['Inter'] leading-tight">
                {t("userBookings.cancel.confirmationModal.confirmation")}
              </div>
              </Spinner>
            </button>
          </div>
        </div>
      </div>
    );
  };

  export default ConfirmModal;
