import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBookings,
  getLogsInformation,
  fetchBookingInfo,
} from "./admin.actions";

const initialState = {
  bookings: null,
  hotelBookings: null,
  bookingInfo: {},
  logsInformation: {},
  shouldShowLogInformation: false,
};

const slice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setlogsInformation(state, action) {
      state.shouldShowLogInformation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBookings.fulfilled, (state, action) => {
      if (!action.payload) return;
      action.payload.forEach((res) => {
        if (res.output) {
          if (res.output.ticketingStatusCount) state.bookings = res.output;
          else state.hotelBookings = res.output;
        }
      });
    });
    builder.addCase(getLogsInformation.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.logsInformation = action.payload;
    });
    builder.addCase(fetchBookingInfo.fulfilled, (state, action) => {
      if (!action.payload) return;
      state.bookingInfo = action.payload;
    });
  },
});

export default slice;

export const { name, reducer, actions } = slice;
