import { memo, useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import SortingHeaders from "./SortingHeaders";
import NoFlightResult from "./NoFlightResult";
import OneWayCard from "./OneWayCard";
import PackageCard from "./PackageCard";
import { selectIsPrePackagedFlights } from "./flightResults.selectors";
import {
  selectSortedFlights,
  selectFilteredFlights,
} from "../../components/organisms/Search";
import { getRearrangedFlights } from "../../helper";
import { DEFAULT_VALUES, FLIGHTS_RESULT_TYPE } from "../../constants";

const { EMPTY_ARRAY } = DEFAULT_VALUES;
const { OUTBOUND_RESULT } = FLIGHTS_RESULT_TYPE;

const PackagedFlights = ({
  numberOfNonStops,
  selectedFlightId,
  showOneWayCard,
  isReissuanceFlight,
}) => {
  const isPrePackaged = useSelector(selectIsPrePackagedFlights);
  const sortedFlights = useSelector(selectSortedFlights);
  const filteredFlights = useSelector(selectFilteredFlights);
  const [flights, setFlights] = useState(
    Array.isArray(sortedFlights.packages) ? sortedFlights.packages : EMPTY_ARRAY
  );

  useEffect(() => {
    const applySorting = () => {
      let updatedFlights;

      if (!selectedFlightId) updatedFlights = sortedFlights.packages;
      else
        updatedFlights = getRearrangedFlights(
          filteredFlights.packages,
          selectedFlightId
        );

      setFlights(updatedFlights);
    };

    applySorting();
  }, [selectedFlightId, sortedFlights, filteredFlights]);

  if (isEmpty(flights)) return <NoFlightResult showPackages={isPrePackaged} />;

  return (
    <div className="bg-contrast-50 rounded-2xl border border-contrast-200">
      <SortingHeaders numberOfNonStops={numberOfNonStops} />
      <div className="flex flex-col gap-2 max-h-[95vh] overflow-y-scroll no-scrollbar px-2">
        {flights.map((flight) =>
          showOneWayCard ? (
            <OneWayCard
              key={flight.flightId}
              flight={flight}
              currentFlightType={OUTBOUND_RESULT}
              isReissuanceFlight={isReissuanceFlight}
            />
          ) : (
            <PackageCard key={flight.flightId} flight={flight} />
          )
        )}
      </div>
    </div>
  );
};

export default memo(PackagedFlights);
