import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Carousel } from "@material-tailwind/react";
import { isEmpty, get } from "lodash";
import { v4 as uuid } from "uuid";
import { Helmet } from "react-helmet";
import { RenderSVG, LocationMarker } from "../../assets/icons";
import {
  setHotelInfoReqBody,
  setPricePolicyReqBody,
  setSelectedRoomInfo,
} from "./index";
import {
  getFormattedAddress,
  getFromSessionStorage,
  getPricePolicyReqBody,
  priceFormatter,
  renderStars,
  setToSessionStorage,
} from "../../helper";
import { selectHotelInfo, selectHotelInfoReqBody } from "./hotelInfo.selector";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import AmentiesCard from "../../components/molecules/HotelAmenities";
import { HotelRoomOptions } from "../../components/molecules";
import { selectCountryInfo } from "../Profile";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  CURRENCY_SYMBOLS,
  ROUTES,
} from "../../constants";
import config from "../../config.json";

const { brandName } = config;
const { EMPTY_STRING, ZERO, TWO, EMPTY_OBJECT } = DEFAULT_VALUES;
const {
  HOTEL_INFO_REQUEST_BODY,
  HOTEL_SEARCH_FILTERS,
  PRICE_PLOICY_REQUEST_BODY,
} = CACHE_KEYS;
const { FETCH_HOTEL_INFO } = SPINNER_NAMES;
const { HOTEL_REVIEW } = ROUTES;
const { INR } = CURRENCY_SYMBOLS;
const HTML_TAGS = {
  unorderedList: "ul",
  paragraph: "p",
  bold: "b",
};

export const parseDescription = (description) => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(description, "text/html");
  const result = Array.from(parsedHtml.body.childNodes).map((child) => {
    const tagName = child.tagName?.toLowerCase();
    switch (tagName) {
      case HTML_TAGS.unorderedList: {
        const liElements = child.querySelectorAll("li");
        const listItems = Array.from(liElements).map((li) => (
          <li key={uuid()} className="list-item text-contrast-500 font-normal">
            {li.textContent}
          </li>
        ));
        return (
          <ul key={uuid()} className="list-inside list-disc">
            {listItems}
          </ul>
        );
      }
      case HTML_TAGS.paragraph:
        return <p key={uuid()}>{child.textContent}</p>;
      case HTML_TAGS.bold:
        return <b key={uuid()}>{child.textContent}</b>;
      default:
        return <div key={uuid()}>{child.textContent}</div>;
    }
  });
  return result;
};

const getPriceBreakdown = (defaultSelectedRooms) => {
  return defaultSelectedRooms.reduce(
    (totalPrice, room) => {
      const {
        RoomPrice = ZERO,
        Tax = ZERO,
        OtherCharges = ZERO,
        AgentCommission = ZERO,
        Discount = ZERO,
      } = get(room, "Price", {});

      totalPrice.totalRoomPrice += RoomPrice;
      totalPrice.totalTax += Tax + OtherCharges + AgentCommission;
      totalPrice.totalDiscount += Discount;

      return totalPrice;
    },
    { totalRoomPrice: ZERO, totalTax: ZERO, totalDiscount: ZERO }
  );
};

const PriceBreakdownCard = ({
  defaultSelectedRooms,
  noOfRooms,
  noOfNights,
  handleDefaultRoomSelection,
  scrollToSection,
}) => {
  const { totalRoomPrice, totalTax, totalDiscount } =
    getPriceBreakdown(defaultSelectedRooms);

  const totalAmount = (
    parseFloat(totalRoomPrice.toFixed(TWO)) -
    parseFloat(totalDiscount) +
    parseFloat(totalTax)
  ).toFixed(TWO);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  const priceBreakdownData = [
    {
      label: `${noOfRooms} Room${
        noOfRooms > 1 ? "s" : ""
      } X ${noOfNights} Night${noOfNights > 1 ? "s" : ""}`,
      amount: totalRoomPrice,
    },
    {
      label: "Total Discount",
      amount: totalDiscount,
    },
    {
      label: "Tax & Service Fees",
      amount: totalTax,
    },
  ];

  return (
    <div className="bg-white border border-contrast-300 rounded-lg mb-6">
      <ul className="flex flex-col text-sm !divide-y divide-grey-200">
        <li className="px-4 py-3">
          <h2 className="text-contrast-900 font-bold text-base">
            Price Breakup
          </h2>
        </li>

        {priceBreakdownData.map(({ label, amount }) => (
          <div key={label}>
            {!!amount && (
              <li className="flex items-center gap-2 px-4 py-3">
                <span className="mr-auto text-contrast-900">{label}</span>
                <span className="font-medium whitespace-nowrap">
                  {currencySymbol}
                  {priceFormatter(amount)}
                </span>
              </li>
            )}
          </div>
        ))}

        <li className="flex items-center gap-2 px-4 py-3 bg-primary-100/50">
          <span className="text-base font-bold mr-auto text-primary-600">
            Total Amount
          </span>
          <span className="text-base font-bold text-primary-600 whitespace-nowrap">
            {currencySymbol}
            {priceFormatter(totalAmount)}
          </span>
        </li>
      </ul>
      <div className="flex justify-between px-5 py-3 font-semibold rounded-b-lg">
        <button
          onClick={scrollToSection}
          className="py-2 px-4 mr-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-xs md:text-sm text-contrast-700 font-medium"
        >
          VIEW OTHER ROOMS
        </button>
        <button
          onClick={handleDefaultRoomSelection}
          className="py-2 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-xs md:text-sm text-white font-medium"
        >
          BOOK THIS NOW
        </button>
      </div>
    </div>
  );
};

const HotelDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roomOptionsRef = useRef(null);
  const hotelInfoReqBody = useSelector(selectHotelInfoReqBody);
  const selectedHotelInfo = useSelector(selectHotelInfo);
  const { ip: endUserIp, code: guestNationality } =
    useSelector(selectCountryInfo) || EMPTY_OBJECT;
  const selectedHotelSearchFilters =
    useSelector(selectHotelSearchFilters) ||
    getFromSessionStorage(HOTEL_SEARCH_FILTERS) ||
    EMPTY_OBJECT;

  const [parsedDescription, setParsedDescription] = useState(EMPTY_STRING);
  const {
    Images,
    Description,
    HotelName,
    StarRating,
    Address,
    Email,
    HotelCode,
    HotelContactNo,
    FaxNumber,
  } = get(
    selectedHotelInfo,
    "HotelRoomInfo.HotelInfoResult.HotelDetails",
    EMPTY_OBJECT
  );
  const RoomCombinationsArray = get(
    selectedHotelInfo,
    "HotelRoomInfo.GetHotelRoomResult.RoomCombinationsArray",
    []
  );
  const roomsDetails = get(
    selectedHotelInfo,
    "HotelRoomInfo.GetHotelRoomResult.HotelRoomsDetails",
    EMPTY_OBJECT
  );
  const hotelInfoResult = get(
    selectedHotelInfo,
    "HotelRoomInfo.HotelInfoResult",
    EMPTY_OBJECT
  );
  const { CategoryId } = hotelInfoReqBody;
  const { NoOfNights, NoOfRooms } = selectedHotelSearchFilters || EMPTY_OBJECT;

  const roomCombinations = RoomCombinationsArray.filter(
    (roomCombinations) => roomCombinations.CategoryId === CategoryId
  );
  const { InfoSource, RoomCombination = [] } = roomCombinations[ZERO] || {};

  const isFixedCombination = InfoSource === "FixedCombination";

  let defaultSelectedRooms = [];

  !isEmpty(RoomCombination) &&
    isFixedCombination &&
    RoomCombination[ZERO].RoomIndex.map((roomIndex) => {
      const roomDetail = roomsDetails.find(
        (room) => room.RoomIndex === roomIndex && room.CategoryId === CategoryId
      );
      defaultSelectedRooms = [...defaultSelectedRooms, roomDetail];
    });

  useEffect(() => {
    if (isEmpty(hotelInfoReqBody)) {
      const data = getFromSessionStorage(HOTEL_INFO_REQUEST_BODY);
      dispatch(setHotelInfoReqBody(data));
    }
  }, [dispatch]);

  useEffect(() => {
    const parsedContent = parseDescription(Description);
    setParsedDescription(parsedContent);
  }, [Description]);

  const handleDefaultRoomSelection = () => {
    const { ResultIndex, CategoryId, HotelCode } = hotelInfoReqBody;
    const pricePolicyReq = getPricePolicyReqBody(
      hotelInfoResult,
      defaultSelectedRooms,
      HotelCode,
      endUserIp,
      ResultIndex,
      NoOfRooms,
      CategoryId,
      guestNationality
    );
    dispatch(setSelectedRoomInfo(defaultSelectedRooms));
    setToSessionStorage(PRICE_PLOICY_REQUEST_BODY, pricePolicyReq);
    dispatch(setPricePolicyReqBody(pricePolicyReq));
    navigate(HOTEL_REVIEW.replace(":hotelId", HotelCode));
  };

  const scrollToSection = () => {
    if (roomOptionsRef.current) {
      roomOptionsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const mappedContactDetails = [
    {
      key: "phone",
      label: "Phone Number(s)",
      value: HotelContactNo,
    },
    {
      key: "email",
      label: "Email",
      value: Email,
    },
    {
      key: "fax",
      label: "Fax Number",
      value: FaxNumber,
    },
    {
      key: "hotelCode",
      label: "Hotel Code",
      value: HotelCode,
    },
  ].filter(({ value }) => value);

  return (
    <div className="flex items-center min-h-[80vh]">
      <Helmet>
        <title>{brandName} | Hotel Details</title>
      </Helmet>
      <Spinner
        name={FETCH_HOTEL_INFO}
        size="w-10 h-10"
        message={"Fetching hotel details..."}
        spinnerClassName="w-full"
      >
        {!isEmpty(get(selectedHotelInfo, "HotelRoomInfo.HotelInfoResult")) && (
          <div>
            <header className="relative bg-primary-800">
              <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-primary-900/50"></div>
              <div className="container px-8 pt-8 pb-16 mx-auto relative">
                <h4 className="text-xl text-white mb-1 font-bold">
                  Hotel Information
                </h4>
              </div>
            </header>
            <main className="pb-16 relative">
              <div className="container px-8 mx-auto -mt-8">
                <div className="grid grid-cols-12 gap-8 relative">
                  <div className="col-span-12 xl:col-span-8 flex flex-col gap-2">
                    <div className="flex flex-col gap-8">
                      <div>
                        {!isEmpty(Images) && (
                          <div className="flex h-80">
                            <div className="w-full md:w-2/3 bg-contrast-200 relative rounded-lg mr-2">
                              <Carousel
                                className="rounded-lg"
                                navigation={() => ""}
                              >
                                {Images.map((imageUrl, index) => (
                                  <img
                                    key={uuid()}
                                    src={imageUrl}
                                    alt={`Slide ${index + 1}`}
                                    className="h-full w-full object-cover"
                                  />
                                ))}
                              </Carousel>
                            </div>
                            {Images?.length > TWO && (
                              <div className="w-1/3 hidden md:flex flex-col gap-5">
                                <div className="max-h-[47%] w-full h-full bg-contrast-200  rounded-lg relative">
                                  <img
                                    src={Images?.[1]}
                                    className="h-full w-full object-cover rounded-lg"
                                    alt={HotelName}
                                  />
                                </div>
                                <div className="max-h-[47%] w-full h-full bg-contrast-200 rounded-lg relative">
                                  <img
                                    src={Images?.[2]}
                                    className="h-full w-full object-cover rounded-lg"
                                    alt={HotelName}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="text-xl font-black mt-4 leading-none">
                      <span>{HotelName}</span>
                      <span className="mx-3">{renderStars(StarRating)}</span>
                    </div>
                    <div className="flex gap-1">
                      <span>
                        <RenderSVG
                          Svg={LocationMarker}
                          width="18"
                          height="18"
                          className="text-primary-600"
                        />
                      </span>
                      <span className="text-xs text-contrast-500">
                        {getFormattedAddress(Address)}
                      </span>
                    </div>
                    {!isEmpty(mappedContactDetails) && (
                      <div className="mt-4">
                        <div className="font-black leading-none text-lg">
                          Hotel Contact Details
                        </div>
                        <div className="mt-1">
                          {mappedContactDetails.map(({ key, label, value }) => (
                            <div key={key} className="text-sm font-semibold">
                              {label}:{" "}
                              <span className="font-normal text-xs text-contrast-500">
                                {value}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="mt-4">
                      <div className="font-black leading-none text-lg">
                        About {HotelName}
                      </div>
                      <div className="mt-1 text-xs text-contrast-600">
                        {parsedDescription}
                      </div>
                    </div>
                    <AmentiesCard />
                    <HotelRoomOptions
                      roomOptionsRef={roomOptionsRef}
                      roomCombinations={RoomCombination}
                      isFixedCombination={isFixedCombination}
                      categoryId={CategoryId}
                    />
                  </div>
                  <div className="col-span-12 xl:col-span-4 sticky top-0">
                    <PriceBreakdownCard
                      defaultSelectedRooms={defaultSelectedRooms}
                      noOfNights={NoOfNights}
                      noOfRooms={NoOfRooms}
                      handleDefaultRoomSelection={handleDefaultRoomSelection}
                      scrollToSection={scrollToSection}
                    />
                  </div>
                </div>
              </div>
            </main>
          </div>
        )}
      </Spinner>
    </div>
  );
};

export default HotelDetails;
