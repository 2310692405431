export const getRearrangedFlights = (flightsList = [], selectedFlightIdToMoveOnTop) => {
  const specifiedFlightIndex = flightsList.findIndex(
    (flight) => flight.flightId === selectedFlightIdToMoveOnTop
  );
  
  if (specifiedFlightIndex === -1) return flightsList;

  const specifiedFlight = flightsList[specifiedFlightIndex];
  const restFlights = [
    ...flightsList.slice(0, specifiedFlightIndex),
    ...flightsList.slice(specifiedFlightIndex + 1)
  ];

  return [specifiedFlight, ...restFlights];
};
