import { createAsyncThunk } from "@reduxjs/toolkit";
import asyncAction from "../../infrastructure/asyncAction";
import { REQUEST_METHODS, SEARCH_SECTION } from "../../constants";
import { SPINNER_NAMES } from "../../components/organisms/Spinner";

const { DELETE, POST, GET } = REQUEST_METHODS;
const { FLIGHT_CANCELLATION, BOOKING_REFUND, PROCESS_PAYMENT, FETCH_USER_BOOKING_INFO } = SPINNER_NAMES;
const { HOTEL } = SEARCH_SECTION;

export const deleteBookingById = createAsyncThunk(
  "delete-booking-by-id",
  async (params, thunkArgs) => {
    const { id } = params;
    return await asyncAction({
      url: `user/bookings/${id}`,
      methodType: DELETE,
      errorMessage: "Failed to Cancel bookings.",
      ...thunkArgs,
    });
  }
);

export const getAmadeusRefundCharges = createAsyncThunk(
  "get-amadeus-refund",
  async ({ body }, thunkArgs) => {
    return await asyncAction({
      url: "initRefund",
      methodType: POST,
      body: JSON.stringify(body),
      spinner: FLIGHT_CANCELLATION,
      errorMessage: "Failed to fetch refund details.",
      ...thunkArgs,
    });
  }
);


export const getTBORefundCharges = createAsyncThunk(
  "get-tbo-refund",
  async ({ body }, thunkArgs) => {
    return await asyncAction({
      url: "cancellation-charges",
      methodType: POST,
      body: JSON.stringify(body),
      spinner: FLIGHT_CANCELLATION,
      errorMessage: "Failed to fetch refund details.",
      ...thunkArgs,
    });
  }
);


export const processRefund = createAsyncThunk(
  "refund-flight-booking",
  async ({ body, tavaBookingId }, thunkArgs) => {
    return await asyncAction({
      url: `void/${tavaBookingId}`,
      methodType: POST,
      body: JSON.stringify(body),
      spinner:BOOKING_REFUND,
      errorMessage: "Failed in refund processing.",
      ...thunkArgs,
    });
  }
);

export const processPayment = createAsyncThunk(
    "process-payment",
    async ({ body }, thunkArgs) => {
      return await asyncAction({
        url: 'payment',
        methodType: POST,
        body: JSON.stringify(body),
        spinner: PROCESS_PAYMENT,
        errorMessage: "Unable to process payment currently. Please try again later.",
        ...thunkArgs,
      });
    }
);

export const getUserBookingInfo = createAsyncThunk(
    "get-user-booking-information",
    async ({ queryParams }, thunkArgs) => {
      const { division, id } = queryParams
      const url = division === HOTEL ? `hotel/bookings/${id}` : `bookings/${id}`

      return await asyncAction({
        url: url,
        methodType: GET,
        spinner: FETCH_USER_BOOKING_INFO,
        isAuthRequired: true,
        abortOnPageChange: false,
        errorMessage: "Failed to fetch booking information. Please try again later.",
        ...thunkArgs,
      });
    }
);
