import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ProfilePageSkeleton = () => (
  <SkeletonTheme baseColor="#e5e7eb" highlightColor="#9ca3af">
    <div className="w-full p-2">
      <div className="text-center">
        <Skeleton height={60} width={"95%"} borderRadius="0.5rem" />
      </div>
      <div className="pl-5">
        <Skeleton height={40} width={120} />
      </div>
    </div>
  </SkeletonTheme>
);

export default ProfilePageSkeleton;
