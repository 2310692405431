import { Helmet } from "react-helmet";
import TravellerInformation from "./TravellerInformation";
import config from "../../../config.json";

const { brandName } = config;

const FlightBooking = () => {
  return (
    <div className="overflow-y-auto scroll-bar bg-contrast-50">
      <Helmet>
        <title>{brandName} | Flight Booking</title>
      </Helmet>
      <TravellerInformation />
    </div>
  );
};

export default FlightBooking;
