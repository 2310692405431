export const SPINNER_NAMES = {
  FETCH_AMEDUES_FLIGHTS: "fetchAmadeusFlights",
  FETCH_TBO_FLIGHTS: "fetchTboFlights",
  SIGNIN: "signin",
  SEARCH: "search",
  PRICE: "price",
  BOOK_FLIGHT: "book",
  SPECIAL_SERVICES: "specialServices",
  SIGNUP: "signUp",
  MAIN_SPINNER: "mainSpinner",
  BOOKINGS: "bookings",
  FETCH_LOGS: "fetchLogs",
  FLIGHT_PAYMENT: "payment",
  VERIFY_OTP: "verifyOtp",
  SEND_OTP: "sendOtp",
  AUTH: "auth",
  PAYMENT_METHOD: "paymentMethod",
  FETCH_HOTEL_RESULTS: "fetchHotelResults",
  FETCH_HOTEL_INFO: "fetchHotelInfo",
  BOOK_CALLBACK: "bookCallback",
  HOTEL_BOOK_CALLBACK: "hotelBookCallback",
  PENDING: "pending",
  FETCH_USER: "fetchUser",
  ADD_TRAVELER: "addTraveler",
  DELETE_TRAVELER: "deleteTraveler",
  UPDATE_TRAVELER: "updateTraveler",
  FETCH_LOCATIONS: "fetchLocations",
  FETCH_ADMIN_BOOKING_INFO: "fetchAdminBookingInfo",
  SUBMIT_QUERY: "submitQuery",
  FLIGHT_CANCELLATION: "flightCancellation",
  BOOKING_REFUND: "flightRefund",
  VERIFY_HOTEL_PRICE_PLOICY: "verifyHotelPricePolicy",
  PROCESS_PAYMENT: "processPayment",
  FETCH_USER_BOOKING_INFO: "fetchUserBookingInfo",
  GENERATE_VOUCHER: "generateVoucher",
  HOTEL_BOOKING: "initiateHotelBooking"
};
