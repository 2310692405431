import { useCallback } from "react";
import ReactPaginate from "react-paginate";
import {
  DEFAULT_VALUES,
  DEFAULT_LIMIT,
  DATA_COUNT_OPTIONS,
} from "../../../constants";
import { RenderSVG, ChevronDown, ChevronUp } from "../../../assets/icons";

const { ZERO, EMPTY_STRING, TWO, ONE, EMPTY_ARRAY } = DEFAULT_VALUES;

const LimitSelection = ({ options = EMPTY_ARRAY, selectedValue, onChange }) => (
  <select
    className="px-4 py-1 rounded border"
    onChange={(e) => onChange(e.target.value)}
    value={selectedValue}
  >
    {options.map(({ limit, label }) => (
      <option
        key={limit}
        value={limit}
        className="text-black active:bg-primary-300 checked:bg-primary-300"
      >
        {label}
      </option>
    ))}
  </select>
);

const Pagination = ({
  totalPage,
  currentPage,
  setCurrentPage,
  totalCount,
  limit,
  setLimit,
}) => {
  const handleOnPageChange = (e) => setCurrentPage(e.selected);

  const handlePrevious = () =>
    setCurrentPage((current) => (current === ZERO ? ZERO : current - ONE));

  const handleNext = () =>
    setCurrentPage((current) =>
      current === totalPage - ONE ? totalPage - ONE : current + ONE
    );

  const handleLimitUpdate = useCallback(
    (limit) => {
      setLimit(limit);
      setCurrentPage(ZERO);
    },
    [limit, currentPage]
  );

  return (
    totalCount > DEFAULT_LIMIT && (
      <div className="flex justify-between items-center">
        <div />
        <div className="flex item-center">
          <div className="flex py-4 justify-center items-center">
            <div className="flex items-center justify-center">
              <button
                className="button bg-primary size-5 rounded-full border-light"
                onClick={handlePrevious}
                disabled={currentPage === ZERO}
              >
                <RenderSVG
                  Svg={ChevronDown}
                  alt="arrow-left"
                  className="rotate-90"
                />
              </button>
            </div>
            <div className="col-auto">
              <ReactPaginate
                forcePage={currentPage}
                nextLabel={EMPTY_STRING}
                previousLabel={EMPTY_STRING}
                containerClassName="flex justify-center items-center"
                breakLabel="..."
                breakClassName="text-contrast-600 pt-1"
                pageClassName="mx-2 pt-1 px-2"
                activeClassName="flex items-center jsutify-center rounded-full border-light bg-primary-600 text-contrast-200"
                onPageChange={handleOnPageChange}
                pageRangeDisplayed={TWO}
                marginPagesDisplayed={TWO}
                pageCount={totalPage}
                renderOnZeroPageCount={null}
              />
            </div>
            <div className="flex">
              <button
                className="button -blue-1 size-40 rounded-full border-light"
                onClick={handleNext}
                disabled={currentPage >= totalPage - ONE}
              >
                <RenderSVG
                  Svg={ChevronUp}
                  alt="arrow-left"
                  className="rotate-90"
                />
              </button>
            </div>
          </div>
        </div>
        <div className="ml limitSelection">
          <LimitSelection
            options={DATA_COUNT_OPTIONS}
            selectedValue={limit}
            onChange={handleLimitUpdate}
          />
        </div>
      </div>
    )
  );
};

export default Pagination;
