import { createBrowserRouter } from "react-router-dom";
import RouteLayout from "./RouteLayout";
import AuthenticateRoute from "./infrastructure/Authentication";
import SearchFlights from "./screens/Home";
import FlightResults from "./screens/FlightResults";
import Profile from "./screens/Profile";
import Admin from "./screens/Admin";
import MyTrips from "./screens/MyTrips";
import Payment from "./screens/Booking/FlightBookings/Payment";
import BookingResult from "./screens/Booking/BookingResult";
import PageNotFound from "./screens/PageNotFound";
import HotelResults from "./screens/HotelResults";
import HotelInfo from "./screens/HotelInfo";
import HotelReview from "./screens/HotelReview";
import HotelBookingResult from "./screens/HotelBooking";
import { ROUTES, AUTH_ROLES } from "./constants";
import Dashboard from "./screens/Admin/Dashboard/Dashboard";
import { Bookings, FlightBookingInfoScreen } from "./screens/Admin/Bookings";
import { APILogs } from "./screens/Admin/APILogs";
import FooterLinkContent from "./screens/Footer/FooterLinkContent";
import { ContactUs } from "./screens/Footer";
import FlightBooking from "./screens/Booking/FlightBookings";
import HotelPayment from "./screens/HotelBooking/HotelPayment";
import UserBookingDetails from "./screens/MyTrips/UserBookingDetails";

const {
  HOME,
  FLIGHT_RESULTS,
  ADMIN,
  ADMIN_DASHBOARD,
  PROFILE,
  USER_BOOKINGS,
  FLIGHT_PRICE,
  FLIGHT_PAYMENT,
  FLIGHT_CONFIRM,
  NOT_FOUND,
  HOTEL_RESULTS,
  HOTEL_INFO,
  HOTEL_REVIEW,
  HOTEL_CONFIRM,
  ADMIN_BOOKINGS,
  ADMIN_API_LOGS,
  ADMIN_BOOKING_INFO,
  PRIVACY_POLICY,
  PAYMENT_SECURITY,
  COOKIE_POLICY,
  CANCELLATION_AND_REFUND_POLICY,
  TERMS_AND_CONDITION,
  CONTACT_US,
  SHIPPING_AND_DELIVERY,
  HOTEL_PAYMENT,
  HOTEL_NON_VOUCHERED_PAYMENT,
  BOOKING_INFO_ROUTE
} = ROUTES;
const { ADMIN: ADMIN_AUTH_ROLE, USER } = AUTH_ROLES;

const router = createBrowserRouter([
  {
    path: HOME,
    element: <RouteLayout />,
    errorElement: <PageNotFound />,
    children: [
      {
        index: true,
        element: <SearchFlights />,
      },
      {
        path: FLIGHT_RESULTS,
        element: <FlightResults />,
      },
      {
        path: PROFILE,
        element: (
          <AuthenticateRoute
            element={<Profile />}
            roles={[ADMIN_AUTH_ROLE, USER]}
          />
        ),
      },
      {
        path: PRIVACY_POLICY,
        element: <FooterLinkContent />,
      },
      {
        path: PAYMENT_SECURITY,
        element: <FooterLinkContent />,
      },
      {
        path: COOKIE_POLICY,
        element: <FooterLinkContent />,
      },
      {
        path: CANCELLATION_AND_REFUND_POLICY,
        element: <FooterLinkContent />,
      },
      {
        path: TERMS_AND_CONDITION,
        element: <FooterLinkContent />,
      },
      {
        path: CONTACT_US,
        element: <ContactUs />,
      },
      {
        path: SHIPPING_AND_DELIVERY,
        element: <FooterLinkContent />,
      },
      {
        path: ADMIN,
        element: (
          <AuthenticateRoute element={<Admin />} roles={[ADMIN_AUTH_ROLE]} />
        ),
        children: [
          {
            path: ADMIN_DASHBOARD,
            element: <Dashboard />,
          },
          {
            path: ADMIN_BOOKINGS,
            element: <Bookings />,
          },
          {
            path: ADMIN_API_LOGS,
            element: <APILogs />,
          },
          {
            path: ADMIN_BOOKING_INFO,
            element: <FlightBookingInfoScreen />,
          },
        ],
      },
      {
        path: USER_BOOKINGS,
        element: <AuthenticateRoute element={<MyTrips />} />,
      },
      {
        path: BOOKING_INFO_ROUTE,
        element: <AuthenticateRoute element={< UserBookingDetails/>} />,
      },
      {
        path: FLIGHT_PRICE,
        element: <FlightBooking />,
      },
      {
        path: FLIGHT_PAYMENT,
        element: <Payment />,
      },
      {
        path: FLIGHT_CONFIRM,
        element: <BookingResult />,
      },
      {
        path: HOTEL_PAYMENT,
        element: <HotelPayment />,
      },
      {
        path: HOTEL_NON_VOUCHERED_PAYMENT,
        element: <HotelPayment />
      },
      {
        path: HOTEL_RESULTS,
        element: <HotelResults />,
      },
      {
        path: HOTEL_INFO,
        element: <HotelInfo />,
      },
      {
        path: HOTEL_CONFIRM,
        element: <HotelBookingResult />,
      },
      {
        path: NOT_FOUND,
        element: <PageNotFound />,
      },
      {
        path: HOTEL_REVIEW,
        element: <HotelReview />,
      },
    ],
  },
]);

export default router;
