import { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import {
  getAirlineIconUrlByCarrierCode,
  getFormattedArrivalAndDepartureDate,
  getLayoverDuration,
  getTotalTimeDifference,
  getCityNameByIata,
} from "../../../helper";
import { selectIsPrePackagedFlights } from "../../../screens/FlightResults";
import { RenderSVG, FlightDarkIcon } from "../../../assets/icons";
import { DEFAULT_VALUES } from "../../../constants";

const { ONE, ZERO } = DEFAULT_VALUES;

const RenderSegments = ({ segments, layoverDuration, baggage }) => {
  const { t } = useTranslation();
  const showPackages = useSelector(selectIsPrePackagedFlights);

  return segments.map(
    (
      {
        carrierCode,
        carrierName,
        flightNumber,
        departure,
        arrival,
        cabinClass,
        aircraftCode,
      },
      index
    ) => (
      <div
        className="flex flex-col justify-around w-full p-2 pb-6 bg-white rounded-lg"
        key={departure.iataCode}
      >
        <div className="grid grid-cols-12 gap-x-1">
          <div
            className={classNames("items-center mb-3 col-span-12", {
              "md:col-span-2": showPackages,
              "md:col-span-11 lg:col-span-4 2xl:col-span-2": !showPackages,
            })}
          >
            <img
              className="rounded border-contrast-300 w-12 h-12"
              src={getAirlineIconUrlByCarrierCode(carrierCode)}
              alt={`${carrierCode}_logo`}
            />
            <div className="text-sm text-contrast-900 font-semibold">
              <div>{carrierName}</div>
              {carrierCode} {flightNumber} {aircraftCode}
            </div>
          </div>
          <div
            className={classNames("grid grid-cols-12 col-span-12", {
              "md:col-span-6": showPackages,
              "md:col-span-12 lg:col-span-7 2xl:col-span-6": !showPackages,
            })}
          >
            <div className="col-span-3">
              <div className="font-semibold">
                {departure.time}
                &nbsp;
              </div>
              <div className="text-contrast-800 text-xs">
                {getFormattedArrivalAndDepartureDate(departure.date)}
              </div>
              <div className="font-semibold">
                {getCityNameByIata(departure.iataCode)}
              </div>
              {departure.terminal && (
                <div className="text-contrast-800 text-xs">
                  {t("flightResults.journeyDetails.terminal")}&nbsp;
                  {departure.terminal}
                </div>
              )}
              {departure.airportName && (
                <div className="text-contrast-800 text-xs">
                  {departure.airportName}
                </div>
              )}
            </div>
            <div
              className={classNames("col-span-6 text-center", {
                "md:col-span-6": !showPackages,
              })}
            >
              <div className="text-contrast-500 px-4 md:px-0">
                {getTotalTimeDifference(
                  departure.iataCode,
                  departure.date,
                  departure.time,
                  arrival.iataCode,
                  arrival.date,
                  arrival.time
                )}
              </div>
              <div className="border-t-2 border-orange-500 my-2 w-4/5 h-6 mx-auto flex justify-center relative">
                <RenderSVG
                  Svg={FlightDarkIcon}
                  className="rotate-90 absolute bottom-3.5"
                  alt="Flight-Icon"
                />
              </div>
            </div>
            <div className="col-span-3">
              <div className="font-semibold">{arrival.time}</div>
              <div className="text-contrast-800 text-xs">
                {getFormattedArrivalAndDepartureDate(arrival.date)}
              </div>
              <div className="font-semibold">
                {getCityNameByIata(arrival.iataCode)}
              </div>
              {arrival.terminal && (
                <div className="text-contrast-800 text-xs">
                  {t("flightResults.journeyDetails.terminal")}&nbsp;
                  {arrival.terminal}
                </div>
              )}
              {arrival.airportName && (
                <div className="text-contrast-800 text-xs">
                  {arrival.airportName}
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              "grid grid-cols-12 col-span-12 border-t border-dashed border-gray-700 mt-2 pt-1",
              {
                "md:col-span-4 md:border-none md:pt-0 md:mt-0": showPackages,
                "md:col-span-12 2xl:col-span-4 2xl:border-none 2xl:mt-0 2xl:pt-0 2xl:gap-x-2":
                  !showPackages,
              }
            )}
          >
            <div
              className={classNames("col-span-3 md:overflow-hidden", {
                "md:!col-span-4": showPackages,
                "lg:!col-span-4": !showPackages,
              })}
            >
              {(baggage.weight || baggage.numberOfBags) && (
                <div className="text-sm">
                  <div className="font-semibold">
                    {t("flightResults.journeyDetails.baggage")}
                  </div>
                  <div className="text-contrast-500 whitespace-nowrap">
                    {baggage.weight || baggage.numberOfBags}
                  </div>
                </div>
              )}
            </div>
            <div
              className={classNames("col-span-6", {
                "md:!col-span-4": showPackages,
                "mx-auto lg:!col-span-5 lg:!mx-0 2xl:!col-span-4 2xl:!mx-auto":
                  !showPackages,
              })}
            >
              {baggage.cabinBaggage && (
                <div className="text-sm w-fit">
                  <div className="font-semibold">
                    {t("flightResults.journeyDetails.checkIn")}
                  </div>
                  <div className="text-contrast-500">
                    {baggage.cabinBaggage}
                  </div>
                </div>
              )}
            </div>
            <div
              className={classNames("col-span-3", {
                "md:!col-span-4": showPackages,
                "lg:ml-2 2xl:!col-span-4 2xl:ml-0": !showPackages,
              })}
            >
              <div className="text-sm">
                <div className="font-semibold">
                  {t("flightResults.journeyDetails.cabin")}
                </div>
                <div className="text-contrast-500">{cabinClass}</div>
              </div>
            </div>
          </div>
        </div>
        {!isEmpty(layoverDuration) && segments[index + ONE] && (
          <div className="flex items-center mt-4">
            <div className="flex-grow border-t-2 border-gray-300"></div>
            <div className="rounded p-2 mx-2">
              <div className="flex items-center">
                <div className="font-semibold text-primary-900 text-sm">
                  {t("flightResults.journeyDetails.changeOfPlanes")}
                </div>
                <div className="flex items-center">
                  <div className="flex-grow">
                    <div className="border-t-2 border-gray-300"></div>
                  </div>
                  <div className="ml-2 text-sm">
                    {t("flightResults.journeyDetails.layoverDuration")}:
                  </div>
                  <div className="ml-2 font-semibold text-sm">
                    {layoverDuration[index]}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow border-t-2 border-gray-300"></div>
          </div>
        )}
      </div>
    )
  );
};

const JourneyDetails = ({ itineraries, segments, baggage }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(ONE);

  const layoverDuration =
    itineraries.length > ONE
      ? itineraries.map((itinerary) => getLayoverDuration(itinerary.segments))
      : [getLayoverDuration(segments)];

  return (
    <>
      <div className="bg-gray-50">
        <div
          className={classNames("p-2 font-semibold border-b-[1px] bg-white", {
            "flex rounded py-3 font-normal text-black":
              itineraries.length > ONE,
          })}
        >
          {itineraries.length > ONE
            ? itineraries.map((itinerary, index) => (
                <button
                  key={itinerary.segments[ZERO].flightNumber}
                  className={classNames(
                    "flex text-sm sm:text-xs cursor-pointer rounded px-4 py-2",
                    {
                      "bg-primary-600 text-white": activeTab === index + ONE,
                    }
                  )}
                  onClick={() => setActiveTab(index + ONE)}
                >
                  {t("flightResults.journeyDetails.trip")} {index + ONE}
                </button>
              ))
            : t("flightResults.journeyDetails.flightDetails")}
        </div>
      </div>
      {itineraries.map((itinerary, index) => (
        <div key={index}>
          {index + ONE === activeTab && (
            <RenderSegments
              segments={itinerary.segments}
              layoverDuration={layoverDuration[index]}
              duration={itinerary.duration}
              baggage={baggage}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default JourneyDetails;
