export const getLeadGuestInfo = (primaryRoomPassengers) => {
    const { Email, FirstName, Phoneno, Title } = primaryRoomPassengers.find(passenger => passenger?.LeadPassenger)

    return {
        profileDetails: {
            title: Title,
            email: Email,
            phoneNumber: Phoneno,
            firstName: FirstName
        }
    }
}
