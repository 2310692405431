export const MODALS = {
  LOGIN_MODAL: "loginModal",
  SIGNUP_MODAL: "sigupModal",
  WELCOME_CARD_MODAL: "welcomeCardModal",
  API_FAILURE_MODAL: "apiFailureModal",
  LAST_NAME_AMENDMENT_MODAL: "LastNameAmendmentModal",
  FLIGHT_REISSUANCE_MODAL: "flightReIssuanceModal",
  REFUND_MODAL: "refundModal",
  SESSION_EXPIRED_MODAL: "sessionExpiredModal",
  BOOKING_CANCELLATION_MODAL: "bookingCancellationModal",
  AMENITIES_MODAL: "amenitiesModal",
  HOTEL_DETAILS_CHANGE_MODAL: "hotelDetailsChangeModal",
  HOTEL_FACILITIES_MODAL: "hotelFacilitiesModal",
  BAGGAGE_SELECTION_MODAL: "baggageSelectionModal",
};
