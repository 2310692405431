import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useCollapse } from "react-collapsed";
import { ChevronDown, ChevronUp, RenderSVG } from "../../../assets/icons";
import { DEFAULT_VALUES } from "../../../constants";

const { EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;

const TravelerDetails = ({ travelerData = EMPTY_ARRAY }) => {
  const { t } = useTranslation();
  const { isExpanded, getToggleProps, getCollapseProps } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  return (
    <div className="bg-white rounded-xl border border-contrast-300 overflow-hidden">
      <div className="px-6 py-4 flex items-center border-b border-contrast-300 bg-contrast-100">
        <h4 className="text-base font-bold text-contrast-900 flex-1">
          {t("bookingResult.travelers")}
        </h4>
        <h4 className="flex gap-3 items-center text-base font-medium text-contrast-900">
          <span>{travelerData.length}</span>
          <button className="p-2 cursor-pointer shrink-0" {...getToggleProps()}>
            <RenderSVG
              Svg={isExpanded ? ChevronUp : ChevronDown}
              className="text-contrast-800"
              width="20"
            />
          </button>
        </h4>
      </div>
      <div
        className="flex flex-col divide-y divide-contrast-300"
        {...getCollapseProps()}
      >
        {travelerData.map(
          ({
            id,
            title = EMPTY_STRING,
            givenName = EMPTY_STRING,
            familyName = EMPTY_STRING,
            travelerType = EMPTY_STRING,
            gender = EMPTY_STRING,
            email = EMPTY_STRING,
            phoneNumber = EMPTY_STRING,
          }) => (
            <div key={id} className="divide-y divide-contrast-300">
              <div className="flex items-center flex-wrap gap-2 justify-between px-6 py-6">
                <div className="flex flex-col gap-1">
                  <h6 className="text-sm font-bold text-contrast-900">{`${title} ${givenName} ${familyName}`}</h6>
                  <div className="text-xs text-contrast-500">
                    <span>
                      {email}, {phoneNumber}
                    </span>
                  </div>
                </div>

                <span className="text-xs font-semibold text-primary-800 bg-primary-100 px-3 py-1 rounded-3xl capitalize">
                  {t(`bookingResult.travelerType.${travelerType}`)} -{" "}
                  {t(`profilePage.${gender}`)}
                </span>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default memo(TravelerDetails);
