export const generateTavaBookingId = () => {
  // Function to generate a random alphanumeric string of 4 chars/digits
  const generateRandomString = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  // Function to get the current date and time in a specific format
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = String(now.getFullYear()).slice(2);
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  };

  // Generate a random alphanumeric string
  const randomString = generateRandomString(4);

  // Get the current date and time
  const dateTimeSegment = getCurrentDateTime();

  // Combine segments to create Tava booking ID
  const bookingID = `TAVA_${dateTimeSegment}${randomString}`;

  return bookingID;
};
