import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { Helmet } from "react-helmet";
import {
  getFromSessionStorage,
  loadScript,
  getFormattedLongDate,
  setToSessionStorage,
  getNextDate,
  renderStars,
  getFormattedAddress,
  getFutureDateAfterDays,
  mapHotelBookingAPIReq
} from "../../helper";
import {
  selectHotelInfo,
  selectPricePolicyInfo,
  selectPricePolicyReqBody,
  selectSelectedRoomInfo,
} from "../HotelInfo";
import { actions as hotelActions } from "../HotelInfo/hotelInfo.reducer";
import { actions as hotelBookingActions } from "../HotelBooking/hotelBooking.reducer";
import { Check, RenderSVG } from "../../assets/icons";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import ErrorMessage from "../../components/atoms/ErrorMessage";
import GuestDetailsSection from "./GuestDetailsSection";
import { selectCountryInfo, selectUserInfo } from "../Profile";
import PriceBreakdownSection from "./PriceBreakdownSection";
import HotelDetailsChangeModal from "../../components/organisms/AppModals/HotelDetailsChangeModal/HotelDetailsChangeModal";
import CancellationPolicyInfo from "./CancellationPolicyInfo";
import { setSelectedModal } from "../../components/organisms/Modal";
import { MODALS } from "../../components/organisms/AppModals";
import {
  selectSelectedPromoCode,
  setPromoCode,
} from "../Booking/FlightBookings";
import PromoCodeCard from "../../components/molecules/PromoCodeCard";
import {
  initiateHotelBooking,
  selectLeadGuestInfo,
  selectOtherGuests,
  setOtherGuests,
} from "../HotelBooking";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  ROUTES,
  BOOKING_TYPES,
  RAZORPAY_CHECKOUT_SCRIPT_URL,
} from "../../constants";
import config from "../../config.json";

const { brandName } = config;
const { EMPTY_OBJECT, EMPTY_ARRAY, ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { PRICE_PLOICY_REQUEST_BODY, BOOKING_INFORMATION } = CACHE_KEYS;
const { VERIFY_HOTEL_PRICE_PLOICY, HOTEL_BOOKING } = SPINNER_NAMES;
const { LOGIN_MODAL, HOTEL_DETAILS_CHANGE_MODAL } = MODALS;
const {
  HOTEL_CONFIRM,
  HOTEL_PAYMENT,
  TERMS_AND_CONDITION,
  CANCELLATION_AND_REFUND_POLICY,
} = ROUTES;
const { NON_VOUCHER_BOOKING, VOUCHER_BOOKING } = BOOKING_TYPES;
const ERROR_VISIBILITY_DURATION = 3000;
const { AMENITIES_MODAL } = MODALS;

const { setPricePolicyReqBody } = hotelActions;
const { setHotelBookReqBody, setHotelBookRes } = hotelBookingActions;

const hotelGuidelines = [
  {
    id: 1,
    data: "Passport, Aadhar, Driving License and Govt. ID are accepted as ID proof(s)",
  },
];

const HotelReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const leadGuestFormRef = useRef(null);
  const guestDetailsRef = useRef(null);
  const userInfo = useSelector(selectUserInfo);
  const searchFilters = useSelector(selectHotelSearchFilters);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const selectedRoomDetails = useSelector(selectSelectedRoomInfo);
  const pricePolicyReqBody = useSelector(selectPricePolicyReqBody);
  const pricePolicyInfo = useSelector(selectPricePolicyInfo);
  const leadGuest = useSelector(selectLeadGuestInfo);
  const otherGuests = useSelector(selectOtherGuests);
  const appliedPromoCode = useSelector(selectSelectedPromoCode);
  const selectedHotelInfo = useSelector(selectHotelInfo);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [bookingType, setBookingType] = useState(VOUCHER_BOOKING);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPaymentButtonClicked, setIsPaymentButtonClicked] = useState(false);
  const [isBookingInProcess, setIsBookingInProcess] = useState(false);
  const isUserAuthenticated = !!userInfo?.id;
  const selectedOtherGuests = otherGuests.filter((guest) => guest.isSelected);
  const isPromoCodeApplied = !isEmpty(appliedPromoCode);
  const nextDate = getNextDate(new Date());
  const { email: loggedInUserEmail } = userInfo || {};

  const blockRoomRes = get(
    pricePolicyInfo,
    "PriceAndCancellationPolicyDetails.PriceAndCancellationPolicyDetail",
    EMPTY_OBJECT
  );
  const hotelInfo = get(blockRoomRes, "BlockRoomResult", EMPTY_OBJECT);
  const hotelImages = get(
    selectedHotelInfo,
    "HotelRoomInfo.HotelInfoResult.HotelDetails.Images",
    EMPTY_OBJECT
  );
  const hotelRoomsDetails = get(
    blockRoomRes,
    "BlockRoomResult.HotelRoomsDetails",
    EMPTY_ARRAY
  );
  const { ValidationAtConfirm = {}, ValidationAtVoucher = {} } =
    get(blockRoomRes, "BlockRoomResult.ValidationInfo") || {};

  const isPANRequired =
    ValidationAtConfirm?.IsPANMandatory || ValidationAtVoucher?.IsPANMandatory;
  const isPassportRequired =
    ValidationAtConfirm?.IsPassportMandatory ||
    ValidationAtVoucher?.IsPassportMandatory;
  const isSamePANForAllAllowed =
    ValidationAtConfirm?.IsSamePANForAllAllowed ||
    ValidationAtVoucher?.IsSamePANForAllAllowed;
  const isAllGuestDetailsRequired = hotelRoomsDetails.some(
    ({ RequireAllPaxDetails }) => RequireAllPaxDetails
  );

  const lowestLastCancellationDate = hotelRoomsDetails.reduce(
    (minDate, roomDetail) => {
      const roomLastVoucherDate = new Date(roomDetail.LastVoucherDate);
      return roomLastVoucherDate < minDate ? roomLastVoucherDate : minDate;
    },
    nextDate
  );

  const toShowNonVoucherBooking = lowestLastCancellationDate < new Date();

  const { CancellationPolicies = [] } = selectedRoomDetails[ZERO] || {};
  const {
    CheckInDate = "",
    NoOfNights = ZERO,
    NoOfRooms = ZERO,
    RoomGuests = [],
  } = searchFilters || {};
  const {
    AddressLine1,
    HotelName,
    StarRating,
    HotelPolicyDetail = "",
    IsCancellationPolicyChanged,
    IsHotelPolicyChanged,
    IsPriceChanged,
  } = hotelInfo || {};
  const hotelCode = get(pricePolicyReqBody, "HotelCode", EMPTY_STRING);
  const freeCancellationBeforeDate = CancellationPolicies[ZERO]?.FromDate;
  const totalGuests = RoomGuests.reduce(
    (total, guestObject) =>
      total + guestObject["NoOfAdults"] + guestObject["NoOfChild"],
    ZERO
  );

  const roomAndBedType = (roomDetail) =>
    roomDetail.RoomTypeName?.split(",") || [];
  const getGuestsCount = (guests = []) => {
    return guests.reduce(
      (total, guestObject) =>
        total + guestObject["NoOfAdults"] + guestObject["NoOfChild"],
      ZERO
    );
  };

  const resetGuestsSelection = () => {
    const updatedOtherGuests = otherGuests.map((guest) => ({
      ...guest,
      isSelected: false,
      roomIndex: null,
    }));
    dispatch(setOtherGuests(updatedOtherGuests));
  };

  useEffect(() => {
    resetGuestsSelection();
    if (isEmpty(pricePolicyReqBody)) {
      const data = getFromSessionStorage(PRICE_PLOICY_REQUEST_BODY);
      dispatch(setPricePolicyReqBody(data));
    }
    return () => {
      dispatch(setPromoCode());
    };
  }, [dispatch]);
  useEffect(() => {
    if (IsCancellationPolicyChanged || IsHotelPolicyChanged)
      dispatch(setSelectedModal(HOTEL_DETAILS_CHANGE_MODAL));
  }, [dispatch, hotelInfo]);

  useEffect(() => {
    const leadGuestFirstName = get(leadGuest, "profileDetails.firstName", "");
    isPaymentButtonClicked && leadGuestFirstName && initiatePayment();
  }, [leadGuest, isPaymentButtonClicked, isSpinnerActive]);

  useEffect(() => {
    isPromoCodeApplied && setBookingType(VOUCHER_BOOKING);
  }, [isPromoCodeApplied]);

  const handleOptionChange = (e) => {
    setBookingType(e.target.value);
  };

  const showErrorMessage = (msg) => {
    setErrorMessage(msg);
    setTimeout(() => {
      setErrorMessage("");
    }, ERROR_VISIBILITY_DURATION);
  };

  const getPaymentButtonText = () => {
    if (bookingType !== NON_VOUCHER_BOOKING) return t("hotelResult.payNow");
    return isUserAuthenticated
      ? t("hotelResult.bookNow")
      : t("hotelResult.loginInToBookNow");
  };

  const verifyGuestCountValidation = () => {
    if (totalGuests > ONE && selectedOtherGuests.length !== totalGuests - ONE) {
      const remainingGuests = totalGuests - selectedOtherGuests.length - ONE;
      showErrorMessage(
        `Please add ${remainingGuests} other guests before proceeding`
      );
    } else {
      setIsPaymentButtonClicked(true);
      setErrorMessage("");
    }
  };

  const initiatePayment = async () => {
    const loadedScript = await loadScript(RAZORPAY_CHECKOUT_SCRIPT_URL);
    if (!loadedScript || isBookingInProcess) return;
    setIsBookingInProcess(true);

    const bookingAPIRequest = mapHotelBookingAPIReq({
      pricePolicyReqBody,
      blockRoomRes,
      userCountryInfo: selectedCountryInfo,
      bookingType,
      leadGuest,
      otherGuests: selectedOtherGuests,
      isSamePANForAllAllowed,
      RoomGuests,
      loggedInUserEmail,
    });
    const {
      bookingRequest: { tavaBookingId },
    } = bookingAPIRequest;

    dispatch(setHotelBookReqBody(bookingAPIRequest));
    const initiateBookingRequest = {
      requestBody: {
        ...bookingAPIRequest,
      },
    };

    dispatch(initiateHotelBooking(initiateBookingRequest)).then((res) => {
        setIsPaymentButtonClicked(false);
        setIsBookingInProcess(false);
        if (!res.payload) return;
        const updatedBookingAPIRes = {
          ...res.payload,
          tavaBookingId,
        };
        setToSessionStorage(BOOKING_INFORMATION, updatedBookingAPIRes);
        if (bookingType != NON_VOUCHER_BOOKING)
          navigate(HOTEL_PAYMENT.replace(":hotelId", hotelCode));
        dispatch(setHotelBookRes(updatedBookingAPIRes));
    });

    if (bookingType === NON_VOUCHER_BOOKING)
      navigate(HOTEL_CONFIRM.replace(":hotelId", hotelCode));
  };

  const handlePayment = () => {
    if (!isUserAuthenticated && bookingType === NON_VOUCHER_BOOKING) {
      dispatch(setSelectedModal(LOGIN_MODAL));
      return;
    }
    const { isValid, handleSubmit } = leadGuestFormRef.current || {};
    handleSubmit();
    isValid
      ? verifyGuestCountValidation()
      : guestDetailsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const nightLabel = NoOfNights > 1 ? "Nights" : "Night";
  const guestLabel = getGuestsCount(RoomGuests) > 1 ? "Guests" : "Guest";
  const roomLabel = NoOfRooms > 1 ? "Rooms" : "Room";

  return (
    <div className="flex items-center min-h-[80vh]">
      <Helmet>
        <title>{brandName} | Review Hotel</title>
      </Helmet>
      <Spinner
        name={VERIFY_HOTEL_PRICE_PLOICY}
        spinnerClassName="w-full"
        size="w-10 h-10"
        message={"Fetching Room Details..."}
      >
        {!isEmpty(hotelRoomsDetails) && (
          <div>
            <header className="relative bg-primary-800">
              <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-r from-contrast-900/0 to-primary-900/50"></div>
              <div className="container px-8 pt-8 pb-16 mx-auto relative">
                <h4 className="text-xl text-white mb-1 font-bold">
                  Review your Booking
                </h4>
              </div>
            </header>
            <main className="pb-16 relative">
              <div className="container px-8 mx-auto -mt-8">
                <div className="grid grid-cols-12 gap-8 relative">
                  <div className="col-span-12 xl:col-span-8 flex flex-col gap-4">
                    <div className="flex flex-col gap-8">
                      <div className="bg-white rounded-lg border border-dashed border-contrast-300 shadow-sm">
                        <div className="flex gap-6 px-4 py-3 border-b border-dashed border-contrast-300 justify-between">
                          <div className="flex-1">
                            <h4 className="text-contrast-900 font-bold text-base mb-2">
                              {HotelName}
                            </h4>
                            <p className="text-contrast-500 text-xs mb-2">
                              {getFormattedAddress(AddressLine1)}
                            </p>
                            <div className="flex gap-2 items-center mb-2">
                              <div className="flex items-center gap-0.5">
                                {renderStars(StarRating)}
                              </div>
                            </div>
                          </div>
                          <div className="shrink-0">
                            <img
                              src={hotelImages?.[ZERO]}
                              alt={HotelName}
                              className="w-full rounded-lg h-28"
                            />
                          </div>
                        </div>
                        <div className="p-4 bg-primary-100/30">
                          <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-8 2xl:col-span-8">
                              <div className="flex gap-6 justify-between items-center">
                                <div className="">
                                  <span className="text-contrast-500 text-xs font-medium">
                                    Check In
                                  </span>
                                  <h5 className="text-sm  text-contrast-900 mb-1">
                                    {CheckInDate}
                                  </h5>
                                </div>
                                <div className="icon shrink-0">
                                  <div className="bg-contrast-200 font-medium text-contrast-700 rounded-2xl px-3 py-0.5 text-[10px]">
                                    {NoOfNights} {nightLabel}
                                  </div>
                                </div>
                                <div className="">
                                  <span className="text-contrast-500 text-xs font-medium">
                                    Check Out
                                  </span>
                                  <h5 className="text-sm  text-contrast-900 mb-1">
                                    {getFutureDateAfterDays(
                                      CheckInDate,
                                      NoOfNights
                                    )}
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="col-span-12 md:col-span-4 2xl:col-span-4 flex gap-4">
                              <div className="flex-1 text-end">
                                <h5 className="text-xs text-contrast-800 font-bold mb-1">
                                  {NoOfNights} {nightLabel} |{" "}
                                  {getGuestsCount(RoomGuests)} {guestLabel} |{" "}
                                  {NoOfRooms} {roomLabel}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="px-4 py-4 border-t border-dashed border-contrast-300">
                          <div className="flex items-center justify-between mb-3">
                            <div className="">
                              <h4 className="text-base font-bold text-contrast-900 flex-1">
                                {roomAndBedType(selectedRoomDetails)[ZERO]}
                              </h4>
                              <p className="text-xs text-contrast-800">
                                {getGuestsCount(RoomGuests)} Guest
                              </p>
                            </div>
                            <button
                              onClick={() =>
                                dispatch(setSelectedModal(AMENITIES_MODAL))
                              }
                              className="font-semibold text-primary-700 hover:opacity-90 text-xs"
                            >
                              See Inclusions
                            </button>
                          </div>
                          <ul className="flex flex-col gap-1 font-medium mb-3">
                            <li className="flex items-center gap-2">
                              <div className="p-0.5 bg-contrast-500 rounded-full"></div>
                              <span className="text-xs text-contrast-500">
                                Room with free cancellation
                              </span>
                            </li>
                            <li className="flex items-center gap-2">
                              <img
                                src="./images/icon/cross-red.svg"
                                width="8"
                                alt=""
                              />
                            </li>
                            <li className="flex items-center gap-2">
                              <RenderSVG
                                Svg={Check}
                                width="14"
                                className="text-green-700"
                              />
                              <span className="text-xs text-green-700">
                                Free cancellation before{" "}
                                {getFormattedLongDate(
                                  freeCancellationBeforeDate,
                                  {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                              </span>
                            </li>
                          </ul>
                          <CancellationPolicyInfo
                            cancellationPolicies={CancellationPolicies}
                          />
                        </div>

                        <div className="p-4 bg-amber-50">
                          <div className="flex items-center mb-3">
                            <h4 className="text-base font-bold text-amber-500 flex-1 justify-between">
                              Important Information
                            </h4>
                          </div>
                          <ul className="list-disc text-contrast-600 text-xs pl-5 flex flex-col gap-1">
                            {hotelGuidelines.map(({ id, data }) => (
                              <li key={id} className="list-item">
                                {data}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <GuestDetailsSection
                      leadGuestFormRef={leadGuestFormRef}
                      guestDetailsRef={guestDetailsRef}
                      isPANRequired={isPANRequired}
                      isPassportRequired={isPassportRequired}
                      isSamePANForAllAllowed={isSamePANForAllAllowed}
                      isAllGuestDetailsRequired={isAllGuestDetailsRequired}
                    />
                    <div className="rounded-lg border border-contrast-300 shadow-sm bg-white p-4">
                      <h4 className="text-base font-bold text-contrast-900 flex-1 mb-6">
                        Payment Options
                      </h4>
                      <div className="flex flex-col gap-4">
                        <div className="checbox-tab">
                          <label className="cursor-pointer relative">
                            <input
                              type="radio"
                              className="peer hidden"
                              onChange={handleOptionChange}
                              name="payment option"
                              value={VOUCHER_BOOKING}
                              checked={bookingType === VOUCHER_BOOKING}
                            />
                            <div className="w-4 h-4 shrink-0 rounded-full grid place-content-center  border-[5px] absolute left-3 top-1/2 -translate-y-1/2 peer-checked:border-primary-600"></div>
                            <div className="flex flex-col gap-1 border-2 p-4 rounded-lg pl-10 peer-checked:bg-blue-50 peer-checked:border-primary-600 peer-checked:text-primary-600">
                              <h6 className="text-sm font-semibold flex uppercase">
                                Make full payment
                              </h6>
                              <span className="text-xs flex">
                                Cancel for free any time before{" "}
                                {getFormattedLongDate(
                                  CancellationPolicies[ZERO]?.FromDate,
                                  {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                              </span>
                            </div>
                          </label>
                        </div>

                        {!toShowNonVoucherBooking && (
                          <div
                            className={classNames("checbox-tab", {
                              "bg-contrast-100": isPromoCodeApplied,
                            })}
                          >
                            <label className="cursor-pointer relative">
                              <input
                                type="radio"
                                className="peer hidden"
                                onChange={handleOptionChange}
                                name="payment option"
                                value={NON_VOUCHER_BOOKING}
                                checked={bookingType === NON_VOUCHER_BOOKING}
                                disabled={isPromoCodeApplied}
                              />
                              <div className="w-4 h-4 shrink-0 rounded-full grid place-content-center  border-[5px] absolute left-3 top-1/2 -translate-y-1/2 peer-checked:border-primary-600"></div>
                              <div className="flex flex-col gap-1 border-2 p-4 rounded-lg pl-10 peer-checked:bg-blue-50 peer-checked:border-primary-600 peer-checked:text-primary-600">
                                <h6 className="text-sm font-semibold flex uppercase">
                                  Book with ₹0 payment
                                </h6>

                                <span className="text-xs flex">
                                  {isPromoCodeApplied
                                    ? "You need remove the coupon code to avail this option"
                                    : "Confirm your booking and Pay Later"}
                                </span>
                              </div>
                            </label>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="">
                      <p className="text-xs mb-3">
                        I agree to Bancstac's{" "}
                        <button
                          className="text-primary-700"
                          onClick={() =>
                            window.open(TERMS_AND_CONDITION, "_blank")
                          }
                        >
                          User Agreement
                        </button>{" "}
                        and{" "}
                        <button
                          className="text-primary-700"
                          onClick={() =>
                            window.open(
                              CANCELLATION_AND_REFUND_POLICY,
                              "_blank"
                            )
                          }
                        >
                          Cancellation Policy
                        </button>
                      </p>
                      <button
                        className={classNames(
                          "py-3 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium w-52 h-11 disabled:cursor-not-allowed",
                          {
                            "opacity-50": isSpinnerActive,
                          }
                        )}
                        onClick={handlePayment}
                        disabled={isSpinnerActive}
                      >
                        <Spinner
                          name={HOTEL_BOOKING}
                          setIsSpinnerActive={setIsSpinnerActive}
                        >
                          {getPaymentButtonText()}
                        </Spinner>
                      </button>
                      <ErrorMessage errorMessage={errorMessage} />
                    </div>
                  </div>
                  <div className="col-span-12 xl:col-span-4 sticky top-0">
                    <PriceBreakdownSection />
                    <PromoCodeCard />
                  </div>
                </div>
              </div>
            </main>
          </div>
        )}
        <HotelDetailsChangeModal
          hasPriceChanged={IsPriceChanged}
          havePoliciesChanged={
            IsHotelPolicyChanged || IsCancellationPolicyChanged
          }
          updatedCancellationPolicy={HotelPolicyDetail}
          updatedPrice={get(
            hotelInfo,
            "HotelRoomsDetails.0.Price.PublishedPriceRoundedOff"
          )}
        />
      </Spinner>
    </div>
  );
};

export default HotelReview;
