import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  getFormattedDuration,
  getLayoverDuration,
  calculateTotalDuration,
  priceFormatter,
  getCityNameByIata,
} from "../../helper";
import FlightSearchResultCardTabs from "./FlightSearchResultCardTabs";
import PackagedFlightDetails from "./PackagedFlightDetails";
import { getStops } from "../../utils/flights.utils";
import { DEFAULT_VALUES, INDEX, FLIGHT_SERVICE_TYPE } from "../../constants";

const { ZERO, ONE, EMPTY_OBJECT, EMPTY_STRING } = DEFAULT_VALUES;
const { LAST } = INDEX;
const { AMADEUS } = FLIGHT_SERVICE_TYPE;

const RoundTripCard = ({ flightData }) => {
  const {
    flight,
    getFlightDetailsToggleProps,
    getFlightDetailsCollapseProps,
    isFlightsDetailsExpanded,
    totalPrice,
    isSelectedFlight,
    hasHighCO2Emission,
    handleSelectPackage,
    currency,
  } = flightData;
  const { itineraries, source, baggage } = flight || EMPTY_OBJECT;
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        "border rounded-lg transition duration-1000 ease-in-out",
        {
          "border-primary-600 bg-primary-100": isSelectedFlight,
          "border-contrast-200 bg-white hover:border-contrast-400":
            !isSelectedFlight,
        }
      )}
    >
      <div className="flex p-4 flex-wrap">
        {itineraries.map((itinerary, index) => {
          const { segments, duration } = itinerary;
          const startLocation = segments[ZERO];
          const { noOfAvailableSeats, departure = EMPTY_OBJECT } =
            startLocation;
          const finalDestination = segments.at(LAST);
          const { arrival } = finalDestination;
          const totalNumberOfStops = segments.length - ONE;
          const layoverDuration = getLayoverDuration(segments);

          const getNoOfSeats = () => {
            if (noOfAvailableSeats)
              return `${noOfAvailableSeats} ${
                noOfAvailableSeats > ONE
                  ? t("flightResults.seatsLeft")
                  : t("flightResults.seatLeft")
              }`;
          };

          return (
            <div
              key={`${departure.date}${departure.time}`}
              className="flex flex-col gap-4 flex-1 justify-between mb-3 md:mb-0"
            >
              <div
                className={classNames(
                  "pr-4 mr-4 flex flex-wrap gap-4 justify-between col-span-6",
                  {
                    "sm:border-r border-dashed border-gray-700": index === ZERO,
                  }
                )}
              >
                <PackagedFlightDetails
                  arrival={arrival}
                  startLocation={startLocation}
                  hasHighCO2Emission={hasHighCO2Emission}
                  baggage={baggage}
                  getNoOfSeats={getNoOfSeats}
                />
                <div className="text-end sm:text-start whitespace-nowrap">
                  <div className="text-md text-gray-900 font-semibold text-center mb-1">
                    {source === AMADEUS
                      ? getFormattedDuration(duration)
                      : calculateTotalDuration(layoverDuration, duration)}
                    <div className="text-xs text-gray-600 font-bold">
                      {getCityNameByIata(departure.iataCode)}-
                      {getCityNameByIata(arrival.iataCode)}
                    </div>
                  </div>
                </div>
                <div className="flex whitespace-nowrap">
                  {!totalNumberOfStops ? (
                    <h5 className="text-gray-900 font-semibold mb-1">
                      {t("flightResults.stopTypes.nonStop")}
                    </h5>
                  ) : (
                    <div>
                      <h5 className="flex text-gray-900 font-semibold mb-1">
                        {totalNumberOfStops} {t("flightResults.stopTypes.stop")}
                      </h5>
                      <div className="text-xs text-gray-600">
                        {getStops(segments)}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <div className="flex justify-between lg:flex-col lg:justify-start lg:ml-auto gap-1 items-center lg:items-stretch lg:w-auto w-full py-2 lg:py-0 lg:-mt-1 flex-wrap">
          <div className="flex items-center justify-center text-md sm:text-end text-primary-600 font-semibold">
            {currency}&nbsp;
            {priceFormatter(totalPrice)}
          </div>
          <div className="flex flex-row">
            <div className="flex flex-row gap-3 justify-end mb-2 border-b-contrast-200">
              <div className="flex">
                <button
                  className={classNames(
                    "py-2 px-4 flex items-center gap-2 rounded-md hover:bg-primary-300 active:bg-primary-200 border-none shadow-sm text-sm text-primary-700 font-medium whitespace-nowrap",
                    {
                      "bg-primary-200": isSelectedFlight,
                      "bg-primary-100": !isSelectedFlight,
                    }
                  )}
                  onClick={handleSelectPackage}
                >
                  {t("flightResults.selectFlight")}
                </button>
              </div>
            </div>
          </div>
          <div {...getFlightDetailsToggleProps()}>
            <div className="flex justify-end text-primary-600 text-sm cursor-pointer d-block lg:d-none mr-[15px] mb-2 font-semibold">
              {isFlightsDetailsExpanded
                ? t("flightResults.hideDetails")
                : t("flightResults.viewDetails")}
            </div>
          </div>
        </div>
      </div>
      {isFlightsDetailsExpanded && (
        <div {...getFlightDetailsCollapseProps()}>
          <div className="rounded-lg border-t-2 bg-gray-50">
            <FlightSearchResultCardTabs flight={flight} />
          </div>
        </div>
      )}
    </div>
  );
};

export default RoundTripCard;
