import { get, isEmpty, cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import TravelerDetailsCard from "../../components/organisms/Traveler/TravelerDetailsCard";
import {
  ChevronDown,
  ChevronUp,
  ExclamationCircleIcon,
  RenderSVG,
  PlusWhite,
} from "../../assets/icons";
import { DEFAULT_VALUES, TRAVELER_TYPE } from "../../constants";
import { selectTravelerInfo, deleteTraveler, updateTraveler } from "./index";
import { mapTravelerReqBody } from "../../helper";
import ConfirmationModal from "../../components/organisms/AppModals/ConfirmationModal";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import ManageProfileTraveler from "./ManageProfileTraveler";
import ProfilePageSkeleton from "../../components/organisms/AppSkeletons/ProfilePageSkeleton";

const { EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { ADULT, CHILD, HELD_INFANT } = TRAVELER_TYPE;
const { FETCH_USER } = SPINNER_NAMES;

const INITIAL_TRAVELER_DETAILS = {
  [ADULT]: EMPTY_ARRAY,
  [CHILD]: EMPTY_ARRAY,
  [HELD_INFANT]: EMPTY_ARRAY,
};

const RenderTraveler = ({
  traveler,
  handleEditTraveler,
  updatePrimaryTraveler,
  removeTraveler,
}) => {
  const PROFILE = "Profile";
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 100,
    defaultExpanded: false,
  });

  const handleRemoveTraveler = () => {
    setIsModalOpen(true);
    const firstName = get(traveler, "profileDetails.firstName", EMPTY_STRING);
    const lastName = get(traveler, "profileDetails.lastName", EMPTY_STRING);
    const data = {
      type: "Cancel",
      img: ExclamationCircleIcon,
      header: t("travelerInfo.removeTraveler"),
      description: t("travelerInfo.confirmMsg", { firstName, lastName }),
      button: t("travelerInfo.remove"),
    };
    setModalData(data);
  };

  const {
    profileDetails: { title, firstName, middleName, lastName },
  } = traveler;

  return (
    <>
      <div
        key={traveler.id}
        className="rounded-lg border flex-col justify-center items-center my-3 mx-4 bg-white border-contrast-200"
      >
        <div className="flex p-4 justify-between flex-col md:flex-row items-center gap-4 w-full min-h-[100%]">
          <div className="w-full items-center justify-center gap-2 px-0 text-contrast-900 text-lg font-normal">
            <div className="w-[210px] truncate">
              {title && `${title}.`}&nbsp;
              {firstName}&nbsp;
              {middleName}&nbsp;
              {lastName}&nbsp;
            </div>
          </div>
          <div className="justify-between items-center gap-4 flex">
            <div className="justify-between items-center gap-4 flex flex-col sm:flex-row">
              {traveler.travelerType === ADULT && (
                <div className="flex px-4 border-r border-contrast-200 justify-between items-end">
                  <div className="justify-start items-center gap-2 flex">
                    <div className="flex left-10 flex-col justify-center items-start">
                      <div className="flex text-contrast-900 text-sm font-medium leading-tight nowrap">
                        {t("profilePage.primary")}
                      </div>
                    </div>

                    <div className="relative w-12 h-6 p-0.5 rounded-xl justify-start items-center flex">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={traveler.isPrimary}
                        readOnly
                      />
                      <div
                        onClick={() =>
                          !traveler.isPrimary && updatePrimaryTraveler(traveler)
                        }
                        className="w-11 h-6 bg-primary-200 rounded-full peer peer-focus:ring-primary-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[3px] after:bg-white after:border-contrast-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary-600 cursor-pointer"
                      ></div>
                    </div>
                  </div>
                </div>
              )}
              <div className="flex px-4 justify-center items-center gap-4">
                <div className="px-[11px] py-[7px] h-7 bg-white rounded shadow border border-red-600 justify-center items-center flex">
                  <button
                    className="text-red-600 h-7 text-xs font-medium font-['Inter'] leading-none"
                    onClick={() => handleRemoveTraveler()}
                  >
                    {t("profilePage.actions.remove")}
                  </button>
                </div>
                <button
                  onClick={() => handleEditTraveler(traveler.travelerId)}
                  className="px-[11px] py-[7px] h-7 bg-white rounded shadow border border-contrast-200 justify-center items-center flex text-contrast-700 text-xs font-medium"
                >
                  {t("profilePage.actions.edit")}
                </button>
              </div>
            </div>
            <div
              className="p2 justify-center items-center flex"
              {...getToggleProps()}
            >
              <RenderSVG
                className="w-5 h-5 relative"
                Svg={isExpanded ? ChevronUp : ChevronDown}
                alt="chevron-up-down"
                color="bg-gray-700"
              />
            </div>
          </div>
        </div>
        <div {...getCollapseProps()}>
          <TravelerDetailsCard
            traveler={traveler}
            shouldShowDocumentSection
            shouldShowPassportDetails
          />
        </div>
      </div>
      {isModalOpen && (
        <ConfirmationModal
          data={modalData}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          removeTraveler={removeTraveler}
          source={PROFILE}
          travelerId={traveler.travelerId}
        />
      )}
    </>
  );
};

const RenderTravelerSection = ({
  label,
  values,
  updatePrimaryTraveler,
  removeTraveler,
}) => {
  const { t } = useTranslation();
  const [showManageTraveler, setShowManageTraveler] = useState(false);
  const [currentTravelerId, setCurrentTravelerId] = useState(null);

  const TRAVELER_MAPPING = {
    [ADULT]: t("travelerInfo.adult"),
    [CHILD]: t("travelerInfo.child"),
    [HELD_INFANT]: t("travelerInfo.infant"),
  };

  const AGE_MAPPING = {
    [ADULT]: t("searchSection.ages.adult"),
    [CHILD]: t("searchSection.ages.children"),
    [HELD_INFANT]: t("searchSection.ages.infants"),
  };

  const openManageTravelerModal = (travelerId) => {
    setShowManageTraveler(true);
    setCurrentTravelerId(travelerId);
  };

  return (
    <>
      <div key={label} className="border rounded-lg">
        <div className="flex justify-between border-b p-2 font-semibold">
          <div className="text-contrast-900 flex flex-row gap-2">
            {TRAVELER_MAPPING[label]} {AGE_MAPPING[label]}
          </div>
        </div>
        <Spinner name={FETCH_USER} loaderComponent={<ProfilePageSkeleton />}>
          <div className="p-2">
            {!isEmpty(values) ? (
              values.map((value) => (
                <RenderTraveler
                  key={value.travelerId}
                  traveler={value}
                  handleEditTraveler={openManageTravelerModal}
                  updatePrimaryTraveler={updatePrimaryTraveler}
                  removeTraveler={removeTraveler}
                />
              ))
            ) : (
              <div className="text-center p-4">
                {t("travelerInfo.noTravelers")}
              </div>
            )}
            <div>
              <button
                className="py-2 px-4 rounded-md bg-primary-600 hover:bg-primary-700 text-primary-700 text-md leading-tight mx-4"
                onClick={() => openManageTravelerModal()}
              >
                <RenderSVG
                  className="w-5 h-5 pr-1 inline"
                  Svg={PlusWhite}
                  alt="plus"
                />
                <span className="text-white">
                  {t("travelerInfo.add")} {TRAVELER_MAPPING[label]}
                </span>
              </button>
            </div>
          </div>
        </Spinner>
      </div>
      {showManageTraveler && (
        <ManageProfileTraveler
          travelerType={label}
          showManageTraveler={showManageTraveler}
          setShowManageTraveler={setShowManageTraveler}
          travelerId={currentTravelerId}
        />
      )}
    </>
  );
};

const ProfileTravelers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedTravelerInfo = useSelector(selectTravelerInfo);
  const [travelers, setTravelers] = useState(EMPTY_ARRAY);
  const [updatedTravelers, setUpdatedTravelers] = useState(EMPTY_OBJECT);

  useEffect(() => {
    setTravelers(selectedTravelerInfo);

    const newUpdatedTravelers = selectedTravelerInfo.reduce((acc, traveler) => {
      const travelerType = traveler.travelerType;
      if (!travelerType) return acc;
      acc[travelerType] = [...(acc[travelerType] || []), traveler];
      return acc;
    }, cloneDeep(INITIAL_TRAVELER_DETAILS));

    setUpdatedTravelers(newUpdatedTravelers);
  }, [selectedTravelerInfo]);

  const updatePrimaryTraveler = (selectedTraveler) => {
    const { travelerId } = selectedTraveler;
    const primaryTraveler = travelers.find((traveler) => traveler.isPrimary);
    if (travelerId === primaryTraveler?.travelerId) return;
    dispatch(
      updateTraveler({
        body: mapTravelerReqBody({ ...selectedTraveler, isPrimary: true }),
        id: travelerId,
      })
    ).then((updatePrimaryTravelerRes) => {
      if (primaryTraveler && updatePrimaryTravelerRes.payload) {
        dispatch(
          updateTraveler({
            body: mapTravelerReqBody({
              ...primaryTraveler,
              isPrimary: false,
            }),
            id: primaryTraveler.travelerId,
          })
        );
      }
    });
  };

  const removeTraveler = (travelerId) => {
    const currentTraveler = travelers.find(
      (traveler) => traveler.travelerId === travelerId
    );

    const updatedPrimaryTravaler =
      currentTraveler.isPrimary &&
      travelers.find(
        (traveler) =>
          traveler.travelerType === ADULT && traveler.travelerId !== travelerId
      );

    if (currentTraveler.isPrimary) {
      if (updatedPrimaryTravaler)
        dispatch(
          updateTraveler({
            body: mapTravelerReqBody({
              ...updatedPrimaryTravaler,
              isPrimary: true,
            }),
            id: updatedPrimaryTravaler.travelerId,
          })
        ).then((res) => res.payload && dispatch(deleteTraveler(travelerId)));
      else toast.error("Can not delete primary traveler.");
    } else dispatch(deleteTraveler(travelerId));
  };

  return (
    <div className="w-full">
      <div className="col-span-12 lg:col-span-7 bg-white border border-contrast-200 w-full rounded-2xl">
        <div className="p-6 flex items-center border-b border-contrast-200">
          <h4 className="text-xl font-bold text-contrast-900 flex-1">
            {t("profilePage.travelers")}
          </h4>
        </div>
        <div className="p-4 flex flex-col gap-4">
          {Object.entries(updatedTravelers).map(([key, values]) => (
            <RenderTravelerSection
              key={key}
              label={key}
              values={values}
              updatePrimaryTraveler={updatePrimaryTraveler}
              removeTraveler={removeTraveler}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileTravelers;
