import { createSlice } from "@reduxjs/toolkit";
import { getHotelInfo, verifyHotelPricePolicy } from "./hotelInfo.actions";

const initialState = {
  hotelInfo: {},
  hotelInfoReqBody: {},
  selectedRoomInfo: [],
  pricePolicyReqBody: {},
  pricePolicyInfo: {},
};

const slice = createSlice({
  name: "hotelInfo",
  initialState,
  reducers: {
    setHotelInfoReqBody(state, action) {
      if (!action.payload) return;
      state.hotelInfoReqBody = action.payload;
    },
    setSelectedRoomInfo(state, action) {
      state.selectedRoomInfo = action.payload;
    },
    setPricePolicyReqBody(state, action) {
      state.pricePolicyReqBody = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHotelInfo.fulfilled, (state, action) => {
        if (!action.payload) return;
        state.hotelInfo = action.payload;
      })
      .addCase(verifyHotelPricePolicy.fulfilled, (state, action) => {
        state.pricePolicyInfo = action.payload;
      });
  },
});

export default slice;
export const { name, reducer, actions } = slice;
