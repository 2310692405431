import { uniqueId } from "lodash";
import { getCityNameByIata, getLayoverDuration } from "../helper";

const getStops = (segments) =>
  getLayoverDuration(segments).map((duration, index) => (
    <>
      <div key={uniqueId()}>{duration}</div>
      <div>{getCityNameByIata(segments[index].arrival.iataCode)}</div>
    </>
  ));

export { getStops };
