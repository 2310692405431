import reducers from "./flightResults.reducer";

export const selectSlice = (state) => state[reducers.name];

export const selectFlightPriceInfo = (state) =>
  selectSlice(state).flightPriceInfo;

export const selectFlightInfo = (state) =>
  selectSlice(state).selectedFlightInfo;

export const selectShouldShowSpinner = (state) =>
  selectSlice(state).shouldShowSpinner;

export const selectCurrentFlightType = (state) =>
  selectSlice(state).currentFlightType;

export const selectFlightPriceReq = (state) =>
  selectSlice(state).flightPriceReq;

export const selectSelectedTripType = (state) => selectSlice(state).tripType;

export const selectRoundTripResultsFormat = (state) =>
  selectSlice(state).roundTripResultFormat;

export const selectSelectedFlightId = (state) =>
  selectSlice(state).selectedFlightId;

export const selectIsInternationalFlight = (state) =>
  selectSlice(state).isInternationalFlight;

export const selectIsPrePackagedFlights = (state) =>
  selectSlice(state).isPrePackaged;
