import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import get from "lodash/get";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import LogResultTable from "./LogResultsTable";
import { getLogsInformation, selectlogsInformation } from "../index";
import LoadingSpinner, {
  SPINNER_NAMES,
} from "../../../components/organisms/Spinner";
import AdminPageHeader from "../Widgets/AdminPageHeader";
import Pagination from "../Widgets/Pagination";
import { DEFAULT_VALUES } from "../../../constants";
import config from "../../../config.json";

const { EMPTY_STRING, TEN, ZERO, ONE, EMPTY_OBJECT } = DEFAULT_VALUES;
const { FETCH_LOGS } = SPINNER_NAMES;
const { brandName } = config;

const initialValues = {
  corelationId: EMPTY_STRING,
};

const validationSchema = yup.object({
  corelationId: yup.string().required("CorelationId is required"),
});

const APILogs = () => {
  const dispatch = useDispatch();
  const apiLogs = useSelector(selectlogsInformation);

  const {
    count,
    totalPage,
    currentPage: apiCurrentPage,
    size,
  } = get(apiLogs, "countInfo", EMPTY_OBJECT);

  const [currentPage, setCurrentPage] = useState(
    apiCurrentPage ? apiCurrentPage - ONE : ZERO
  );
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [limit, setLimit] = useState(size || TEN);
  const [corelationId, setCorelationId] = useState(EMPTY_STRING);

  useEffect(() => {
    dispatch(
      getLogsInformation({
        corelationId,
        page: currentPage + ONE,
        size: limit,
      })
    );
  }, [corelationId, currentPage, limit]);

  const handleSubmit = (values) => setCorelationId(values.corelationId);

  const handleInputChange = (e) => {
    const { value } = e.target;
    !value && setCorelationId();
  };

  return (
    <div className="w-full px-8">
      <Helmet>
        <title>{brandName} | Admin API Logs</title>
      </Helmet>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleBlur, isValid }) => (
          <Form>
            <div>
              <AdminPageHeader title="Monitor Log Query" />
              <div className="border border-gray-300 rounded bg-white flex items-center p-2 gap-2">
                <div className="flex flex-col flex-grow">
                  <Field
                    type="text"
                    id="corelationId"
                    name="corelationId"
                    onChange={(e) => {
                      handleChange(e);
                      handleInputChange(e);
                    }}
                    onBlur={handleBlur}
                    placeholder=" Session/Correlation ID:"
                    className="w-full border border-gray-300 rounded-md placeholder:text-xs placeholder:text-gray-600 placeholder:mt-0"
                  />
                </div>
                <button
                  type="submit"
                  className={classNames(
                    "px-4 py-2 text-white cursor-pointer rounded-md bg-primary-600",
                    {
                      "opacity-50": !isValid,
                      "opacity-100": isValid,
                    }
                  )}
                  disabled={!isValid || isSpinnerActive}
                >
                  Search
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="flex flex-col align-middle w-full min-h-[70vh]">
        <LoadingSpinner
          name={FETCH_LOGS}
          setIsSpinnerActive={setIsSpinnerActive}
          message="Fetching Results"
        >
          <div className="border border-gray-300 rounded bg-white mt-3">
            <div className="mt-4 mx-4">
              <LogResultTable data={apiLogs?.result} keyField="entityId" />
              <Pagination
                totalPage={totalPage}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalCount={count}
                limit={limit}
                setLimit={setLimit}
              />
            </div>
          </div>
        </LoadingSpinner>
      </div>
    </div>
  );
};

export default APILogs;
