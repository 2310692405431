import { useEffect } from "react";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { DEFAULT_VALUES } from "../../../constants";
import { ChevronDown, Checkbox, RenderSVG, Star } from "../../../assets/icons";

const { EMPTY_ARRAY } = DEFAULT_VALUES;

const StarRatingFilters = ({
  starRatings,
  filters,
  setFilters,
  resetFilters,
  setResetFilters,
}) => {
  const { t } = useTranslation();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  useEffect(() => {
    if (resetFilters) {
      setFilters({ ...filters, starRatings: EMPTY_ARRAY });
      setResetFilters(false);
    }
  }, [resetFilters]);

  const isChecked = (stars) => filters.starRatings.includes(stars);

  const handleCheckboxChange = (stars) => {
    let updatedStarRatings;
    if (isChecked(stars))
      updatedStarRatings = filters.starRatings.filter(
        (itemStars) => itemStars != stars
      );
    else updatedStarRatings = [...filters.starRatings, stars];
    setFilters({ ...filters, starRatings: updatedStarRatings });
  };

  return (
    <>
      <div className="flex items-center gap-2" {...getToggleProps()}>
        <h4 className="flex-1 text-lg font-semibold text-contrast-800">
          {t("hotelResults.filters.starRatings")}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            alt="Expand Icon"
            className={classNames({ "rotate-180": isExpanded })}
          />
        </span>
      </div>
      <div {...getCollapseProps()}>
        <div className="pt-6">
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(starRatings)
              .reverse()
              .map(([stars, count]) => {
                const checked = isChecked(stars);
                return (
                  <div className="col-span-2" key={stars}>
                    <div className="checbox-tab">
                      <label className="cursor-pointer">
                        <input
                          type="checkbox"
                          className="peer hidden"
                          id={stars}
                          value={stars}
                          onChange={() => handleCheckboxChange(stars)}
                          checked={checked}
                        />
                        <div
                          className={classNames(
                            "flex items-center gap-2 border-2 p-4 rounded-lg",
                            {
                              "bg-primary-100 border-primary-600 text-primary-900":
                                checked,
                              "border-contrast-200 text-contrast-900": !checked,
                            }
                          )}
                        >
                          <div
                            className={classNames(
                              "w-4 h-4 rounded grid place-content-center border",
                              {
                                "bg-primary-600 border-primary-600": checked,
                                "border-contrast-300": !checked,
                              }
                            )}
                          >
                            <RenderSVG
                              Svg={Checkbox}
                              width="9"
                              height="9"
                              alt="Checkbox Icon"
                            />
                          </div>
                          <div className="w-full flex justify-between text-sm font-medium">
                            <span className="flex flex-center">
                              {stars}{" "}
                              <RenderSVG
                                Svg={Star}
                                className="w-4 inline mx-1"
                                alt="Star icon"
                              />
                            </span>
                            <span className="text-contrast-500">{count}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default StarRatingFilters;
