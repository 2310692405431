import { FreeLabel, RenderSVG } from "../assets/icons";
import {
  CACHE_KEYS,
  CURRENCY_SYMBOLS,
  DEFAULT_CURRENCY,
  DEFAULT_VALUES,
  DEFAULT_CURRENCY_CODE,
} from "../constants";
import { getFromSessionStorage } from "./getDataFromBrowserStorage";
import config from "../config.json";

const { ZERO } = DEFAULT_VALUES;
const { INR } = CURRENCY_SYMBOLS;
const { CURRENCY_INFO } = CACHE_KEYS;
const { platformFee, taxes } = config;

export const getPriceWithConversion = (amount, exchangeRate) =>
  amount * exchangeRate;

export const formatPriceWithCommas = (price) =>
  price.replace(/(\d)(?=(\d\d)+\d(\.\d+)?$)/g, "$1,");

export const priceFormatter = (price) => {
  const currencyInfo = getFromSessionStorage(CURRENCY_INFO);
  const {
    code: currencyCode,
    symbol: currencySymbol,
    exchangeRate,
  } = currencyInfo || DEFAULT_CURRENCY;

  const isIndianSystem = currencySymbol === INR;
  if (price) {
    if (currencyCode !== DEFAULT_CURRENCY_CODE) {
      price = getPriceWithConversion(price, exchangeRate);
    }
    let formattedPrice = Number(Number(price).toFixed(2)).toString();
    // Use regex to add commas based on the place value system
    formattedPrice = isIndianSystem
      ? formatPriceWithCommas(formattedPrice)
      : formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return formattedPrice;
  } else return price;
};

export const showSSRPrice = (price, currencyCode) => {
  if (typeof price === "number" && price > ZERO)
    return (
      <div>
        {CURRENCY_SYMBOLS[currencyCode]}
        {priceFormatter(price)}
      </div>
    );
  return <RenderSVG Svg={FreeLabel} className="h-8 w-8" alt="freeLabel" />;
};

export const getPlatformFees = () => {
  const currencyInfo = getFromSessionStorage(CURRENCY_INFO);
  const { exchangeRate } = currencyInfo || DEFAULT_CURRENCY;
  const taxPercentage = parseFloat(taxes) / 100;
  const taxAmount = parseFloat(platformFee) * taxPercentage;
  const totalPlatformFees = parseFloat(platformFee) + taxAmount;
  return {
    convertedPrice: parseFloat(
      getPriceWithConversion(totalPlatformFees, exchangeRate).toFixed(3)
    ),
    basePrice: totalPlatformFees,
  };
};
