import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCollapse } from "react-collapsed";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import classNames from "classnames";
import {
  getPricePolicyReqBody,
  priceFormatter,
  getFromSessionStorage,
  setToSessionStorage,
} from "../../../helper";
import { selectHotelSearchFilters } from "../../organisms/Search";
import {
  selectHotelInfo,
  selectHotelInfoReqBody,
  setPricePolicyReqBody,
  setSelectedRoomInfo,
} from "../../../screens/HotelInfo";
import {
  Bed,
  ChevronDown,
  ChevronUp,
  RenderSVG,
  WhiteChevronDown,
  WhiteChevronUp,
} from "../../../assets/icons";
import { selectCountryInfo } from "../../../screens/Profile";
import {
  DEFAULT_VALUES,
  CURRENCY_SYMBOLS,
  ROUTES,
  DEFAULT_USER_IP_ADDRESS,
  CACHE_KEYS,
} from "../../../constants";

const { ZERO, ONE, TWO, EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } =
  DEFAULT_VALUES;
const { INR } = CURRENCY_SYMBOLS;
const { HOTEL_REVIEW } = ROUTES;
const { HOTEL_SEARCH_FILTERS, PRICE_PLOICY_REQUEST_BODY } = CACHE_KEYS;
const ALL_ROOM_OPTIONS = "All";

const InfoTag = ({ children }) => {
  return (
    <div className="w-fit px-2 py-0.5 font-medium bg-[#FFEDD5] text-[#7C2D12] text-xs rounded-full">
      {children}
    </div>
  );
};

const RoomOptionCard = ({
  roomOption,
  currencySymbol,
  isCombination = false,
  handleSelectRoom = () => {},
}) => {
  const { getToggleProps, getCollapseProps, isExpanded } = useCollapse({
    duration: 300,
  });
  const {
    CancellationPolicies,
    CancellationPolicy,
    IsPANMandatory,
    IsPassportMandatory,
  } = roomOption;
  const { Charge } = CancellationPolicies[ZERO] || {};
  const cancellationMsgArr = CancellationPolicy?.split("#^#")
    [ONE]?.replaceAll("#!#")
    .split("|")
    .filter((item) => item && item !== "undefined");

  const getTaxesAndFees = (roomOption) => {
    const feesAndTaxes = (
      roomOption.Price.Tax +
      roomOption.Price.OtherCharges +
      roomOption.Price.AgentCommission
    ).toFixed(TWO);
    return feesAndTaxes;
  };

  const getRoomAndBedType = (roomOption) => roomOption.RoomTypeName.split(",");

  const requiredDocuments = [
    {
      key: "passport",
      label: "Passport Required",
      shouldShow: IsPassportMandatory,
    },
    {
      key: "pan",
      label: "PAN Required",
      shouldShow: IsPANMandatory,
    },
  ];

  return (
    <div
      className={classNames("lg:col-span-7 px-6 py-4 flex flex-col gap-4", {
        "border border-primary-200 rounded-md": !isCombination,
      })}
    >
      <div
        className={classNames("w-full flex flex-col sm:flex-row items-start", {
          "my-1": isCombination,
        })}
      >
        <div className="flex flex-col sm:flex-row w-full">
          <div className="w-full md:w-1/2 text-left flex flex-col justify-center">
            <div className="mt-0 w-full text-contrast-900 text-left flex-col justify-center">
              <div className="flex text-xs sm:text-xs md:text-sm lg:text-base font-semibold">
                {getRoomAndBedType(roomOption)[ZERO]}
              </div>
              <div className="flex text-xs md:text-sm text-contrast-600 gap-2">
                <RenderSVG
                  Svg={Bed}
                  className="w-4 sm:w-7 h-4 sm:h-7"
                  alt="bed-icon"
                />
                <p className="flex pt-1 text-sm">
                  {getRoomAndBedType(roomOption)[ONE]}
                </p>
              </div>
              <div className="flex space-y-1">
                <ul className="list-inside list-disc">
                  {roomOption.Amenities.map((amenityStr) =>
                    amenityStr.split(",").map((amenity) => (
                      <li
                        key={amenity}
                        className="list-item text-xs text-contrast-600"
                      >
                        {amenity}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3 text-left flex flex-col justify-center">
            <div className="flex flex-row items-center">
              <div className="flex text-xs sm:text-sm md:text-sm lg:text-base sm:font-semibold text-primary-600 whitespace-nowrap">
                {currencySymbol}
                {priceFormatter(roomOption.Price.RoomPrice)}
              </div>
              <div className="flex text-xs ml-3 text-primary-600 whitespace-nowrap">
                + {currencySymbol}
                {priceFormatter(getTaxesAndFees(roomOption))} taxes & fees
              </div>
            </div>
          </div>
          {!isCombination && (
            <div className="w-full md:w-1/4 text-left flex flex-col py-2 md:items-end">
              <button
                className="py-2 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-xs md:text-sm text-white font-medium"
                onClick={handleSelectRoom}
              >
                Select Room
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col sm:flex-row text-start sm:items-center gap-1">
          <InfoTag>
            Cancellable{" "}
            {!!Charge && (
              <span className="px-1 whitespace-nowrap">with deductions*</span>
            )}
          </InfoTag>
          {requiredDocuments.map(({ key, label, shouldShow }) => (
            <span key={key}>{shouldShow && <InfoTag>{label}</InfoTag>}</span>
          ))}
        </div>
        <div {...getToggleProps()}>
          <span className="flex gap-2 items-center text-sm text-primary-600">
            View Details
            <RenderSVG
              Svg={isExpanded ? ChevronUp : ChevronDown}
              className="w-4 h-4 text-black"
              alt={isExpanded ? "Up Icon" : "Down Icon"}
            />
          </span>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="px-4 py-4 bg-amber-50 rounded-lg border-amber-600/50 border">
          <div className="text-xs md:text-sm font-bold">
            Cancellation Policy
          </div>
          <ul className="list-disc text-xs text-contrast-700 px-4">
            {cancellationMsgArr.map((msg) => (
              <li key={msg} className="list-item">
                {msg}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const HotelRoomOptions = ({
  roomOptionsRef,
  roomCombinations,
  isFixedCombination,
  categoryId,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedHotelInfo = useSelector(selectHotelInfo);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const hotelInfoReqBody = useSelector(selectHotelInfoReqBody);
  const selectedHotelSearchFilters =
    useSelector(selectHotelSearchFilters) ||
    getFromSessionStorage(HOTEL_SEARCH_FILTERS) ||
    EMPTY_OBJECT;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const {
    ip: endUserIp = DEFAULT_USER_IP_ADDRESS,
    code: guestNationality = "IN",
  } = selectedCountryInfo || {};
  const hotelInfoResult = get(
    selectedHotelInfo,
    "HotelRoomInfo.HotelInfoResult",
    {}
  );
  const roomInfo = get(
    selectedHotelInfo,
    "HotelRoomInfo.GetHotelRoomResult.HotelRoomsDetails",
    EMPTY_ARRAY
  );

  const { NoOfRooms } = selectedHotelSearchFilters || EMPTY_OBJECT;

  const isCombination = isFixedCombination && parseInt(NoOfRooms) > ONE;

  const filteredRoomOptions = selectedOption
    ? selectedOption === ALL_ROOM_OPTIONS
      ? roomInfo.filter((roomInfo) => roomInfo.CategoryId === categoryId)
      : roomInfo.filter(
          (roomInfo) =>
            roomInfo.RoomTypeName.split(",")[ZERO] === selectedOption &&
            roomInfo.CategoryId === categoryId
        )
    : roomInfo.filter((roomInfo) => roomInfo.CategoryId === categoryId);

  let filteredRoomCombination = [];
  roomCombinations.map((comboRoom) => {
    const indexExist = filteredRoomOptions.find(
      (room) => room.RoomIndex === comboRoom.RoomIndex[ZERO]
    );
    if (indexExist) {
      filteredRoomCombination = [...filteredRoomCombination, comboRoom];
    }
  });

  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);
  const roomOptions = [
    ALL_ROOM_OPTIONS,
    ...Array.from(
      new Set(
        roomInfo.map((roomOption) => roomOption.RoomTypeName.split(",")[ZERO])
      )
    ),
  ];

  const handleRoomTypeSelection = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleRoomSelection = (roomInfo) => {
    const { ResultIndex, CategoryId, HotelCode } = hotelInfoReqBody;
    const pricePolicyReq = getPricePolicyReqBody(
      hotelInfoResult,
      roomInfo,
      HotelCode,
      endUserIp,
      ResultIndex,
      NoOfRooms,
      CategoryId,
      guestNationality
    );
    dispatch(
      setSelectedRoomInfo(Array.isArray(roomInfo) ? roomInfo : [roomInfo])
    );
    setToSessionStorage(PRICE_PLOICY_REQUEST_BODY, pricePolicyReq);
    dispatch(setPricePolicyReqBody(pricePolicyReq));
    navigate(HOTEL_REVIEW.replace(":hotelId", HotelCode));
  };

  const handleSelectCombo = (comboRoom) => {
    const roomOptions = comboRoom.RoomIndex.map((roomIndex) =>
      filteredRoomOptions.find((room) => room.RoomIndex === roomIndex)
    );
    handleRoomSelection(roomOptions);
  };

  return (
    <div className="w-full h-full justify-center item-center my-1 sm:my-4">
      <p ref={roomOptionsRef} className="text-lg mb-1 font-bold">
        Select Rooms
      </p>
      <div className="flex flex-col w-full gap-2">
        <div className="border border-primary-400 rounded-md bg-primary-600">
          <div className="flex w-full">
            <div className="w-1/2 sm:w-1/3 px-2 sm:px-6 pt-4 text-contrast-900 text-left rounded-sm">
              <div className="relative inline-block text-left">
                <div className="mb-4 gap-2">
                  <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    className="flex justify-center w-full rounded-md border border-contrast-200 text-sm font-medium text-contrast-700 outline-none border-none"
                  >
                    <p className="text-sm text-white">Room Types</p>
                    {roomInfo.length > ONE && (
                      <RenderSVG
                        Svg={isOpen ? WhiteChevronUp : WhiteChevronDown}
                        className="w-5 h-5"
                        alt={isOpen ? "Up Icon" : "Down Icon"}
                      />
                    )}
                  </button>
                </div>

                {roomInfo.length > ONE && isOpen && (
                  <div className="origin-top-right absolute right-0 left-4 w-56 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 border border-primary-100">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {roomOptions.map((option) => (
                        <div
                          key={option}
                          className="flex px-4 py-2 text-sm text-contrast-700 font-semibold hover:bg-primary-100 hover:text-contrast-900 cursor-pointer"
                          role="menuitem"
                          onClick={() => handleRoomTypeSelection(option)}
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                "hidden md:block h-11 w-1/3 p-4 ml-40 text-left rounded-sm"
              }
            >
              <p className="text-sm text-white font-medium">Price</p>
            </div>
          </div>
        </div>
        {isFixedCombination &&
          filteredRoomCombination.map((comboRoom, index) => {
            return (
              <div
                key={uuid()}
                className={classNames("", {
                  "border border-primary-200 rounded-md my-2": isCombination,
                })}
              >
                {isCombination && (
                  <div className="flex justify-between p-4 items-center border-b border-dashed">
                    <p className="text-base font-semibold">Combo {index + 1}</p>
                    <button
                      className="bg-primary-600 text-sm text-white px-4 py-2 rounded-md"
                      onClick={() => handleSelectCombo(comboRoom)}
                    >
                      Select Combo
                    </button>
                  </div>
                )}
                <div className="divide-y divide-gray-200 gap-2">
                  {comboRoom.RoomIndex.map((roomIndex) => {
                    const roomOption = filteredRoomOptions.find(
                      (room) => room.RoomIndex === roomIndex
                    );
                    return (
                      <div key={roomOption.RoomIndex}>
                        <RoomOptionCard
                          key={roomOption.RoomIndex}
                          roomOption={roomOption}
                          currencySymbol={currencySymbol}
                          isCombination={isCombination}
                          handleSelectRoom={() =>
                            handleRoomSelection(roomOption)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        {!isFixedCombination && //TODO: Need to handle for open combination case.
          filteredRoomOptions.map((roomOption) => (
            <RoomOptionCard
              key={roomOption.RoomIndex}
              roomOption={roomOption}
              currencySymbol={currencySymbol}
              handleSelectRoom={() => handleRoomSelection(roomOption)}
            />
          ))}
      </div>
    </div>
  );
};

export default HotelRoomOptions;
