import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import get from "lodash/get";
import {
  selectSelectedLCCMeals,
  selectSelectedNonLCCMeals,
  selectSelectedTravelers,
} from "../index";
import { RenderSVG, Plus, Minus } from "../../../../assets/icons";
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_VALUES,
  INDEX,
} from "../../../../constants";
import { Tooltip } from "react-tooltip";
import { showSSRPrice } from "../../../../helper";
import { actions } from "../flightBookings.reducer";
import { selectCountryInfo } from "../../../Profile";

const { setSelectedLCCMeals, setSelectedNonLCCMeals } = actions;

const { ZERO, ONE, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;
const { LAST } = INDEX;

const MealsCard = ({ data, isLCC }) => {
  const dispatch = useDispatch();
  const {
    FlightNumber,
    Code,
    resultIndex,
    AirlineDescription,
    Description,
    Price,
  } = data;
  const { t } = useTranslation();
  const selectedTravelers = useSelector(selectSelectedTravelers);
  const selectedLCCMeals = useSelector(selectSelectedLCCMeals);
  const selectedNonLCCMeals = useSelector(selectSelectedNonLCCMeals);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencyCode = get(
    selectedCountryInfo,
    "currency.code",
    DEFAULT_CURRENCY_CODE
  );

  let currentFlightMeals = isLCC
    ? get(selectedLCCMeals, FlightNumber, EMPTY_ARRAY)
    : get(selectedNonLCCMeals, resultIndex, EMPTY_ARRAY);

  const mealCount = currentFlightMeals.filter(
    (meal) => meal.Code === Code
  ).length;

  const isAddButtonDisabled =
    selectedTravelers.length > currentFlightMeals.length;

  const getErrorMessage = () =>
    !isAddButtonDisabled
      ? `Sorry, you cannot select more than ${selectedTravelers.length} meals`
      : EMPTY_STRING;

  const handleMealSelection = useCallback(
    (isAdd) => {
      const updatedMealSelection = [...currentFlightMeals];
      if (isAdd) updatedMealSelection.push(data);
      else {
        const itemIndex = updatedMealSelection.findIndex(
          (meal) => meal.Code === Code
        );
        if (itemIndex !== LAST) updatedMealSelection.splice(itemIndex, ONE);
      }

      isLCC
        ? dispatch(
            setSelectedLCCMeals({
              ...selectedLCCMeals,
              [FlightNumber]: updatedMealSelection,
            })
          )
        : dispatch(
            setSelectedNonLCCMeals({
              ...selectedNonLCCMeals,
              [resultIndex]: updatedMealSelection,
            })
          );
    },
    [
      dispatch,
      selectedLCCMeals,
      selectedNonLCCMeals,
      data,
      isLCC,
      currentFlightMeals,
    ]
  );

  return (
    <div>
      <div className="p-3 w-full rounded-lg border border-contrast-200 flex justify-center items-center mt-4">
        <div className="grow shrink basis-0  justify-center items-center gap-2 flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex text-xs">
            <div>{AirlineDescription || Description}</div>
            <div>{showSSRPrice(Price, currencyCode)}</div>
          </div>
        </div>
        <div className="relative">
          <Tooltip id="meal-tooltip" className="!bg-contrast-500 !rounded-lg" />
          <div className="h-full flex gap-1 border border-contrast-200 items-center justify-center p-1 rounded-md">
            {!mealCount ? (
              <button
                className="flex items-center justify-center text-xs disabled:cursor-not-allowed gap-1 px-2 py-1"
                onClick={() => handleMealSelection(ONE)}
                disabled={!isAddButtonDisabled}
                data-tooltip-id="meal-tooltip"
                data-tooltip-content={getErrorMessage()}
              >
                {t("travelerInfo.add")}
              </button>
            ) : (
              <>
                <button
                  disabled={!mealCount}
                  onClick={() => handleMealSelection(ZERO)}
                >
                  <RenderSVG Svg={Minus} height="15" alt="minus" />
                </button>
                <div>{mealCount}</div>
                <button
                  className="disabled:cursor-not-allowed"
                  disabled={!isAddButtonDisabled}
                  onClick={() => handleMealSelection(ONE)}
                  data-tooltip-id="meal-tooltip"
                  data-tooltip-content={getErrorMessage()}
                >
                  <RenderSVG Svg={Plus} height="15" alt="plus" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MealsCard;
