const SMOKING_PREFERENCE_CODES = {
  NoPreference: 0,
  Smoking: 1,
  NonSmoking: 2,
  Either: 3,
};

export const formatRoomInfo = (room) => {
  const {
    RoomIndex,
    RoomTypeCode,
    RoomTypeName,
    RatePlanCode,
    BedTypeCode,
    SmokingPreference,
    HotelSupplements,
    Price = {},
  } = room;
  const { GST, ServiceCharge, TCS, TotalGSTAmount, ...filteredPriceInfo } =
    Price;

  return {
    RoomIndex,
    RoomTypeCode,
    RoomTypeName,
    RatePlanCode,
    BedTypeCode,
    SmokingPreference: SMOKING_PREFERENCE_CODES[SmokingPreference],
    Supplements: HotelSupplements,
    Price: filteredPriceInfo,
  };
};

const getFormattedRoomInfo = (roomInfo) =>
  Array.isArray(roomInfo)
    ? roomInfo.map(formatRoomInfo)
    : [formatRoomInfo(roomInfo)];

export const getPricePolicyReqBody = (
  hotelInfo,
  roomInfo,
  HotelCode,
  endUserIp,
  ResultIndex,
  NoOfRooms,
  CategoryId,
  guestNationality
) => {
  const {
    TraceId,
    HotelDetails: { HotelName },
  } = hotelInfo;

  return {
    ResultIndex,
    HotelCode,
    HotelName,
    GuestNationality: guestNationality,
    NoOfRooms,
    IsVoucherBooking: "true",
    CategoryId,
    EndUserIp: endUserIp,
    TraceId,
    HotelRoomsDetails: getFormattedRoomInfo(roomInfo),
  };
};
