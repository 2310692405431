import { v4 as uuid } from "uuid";
import { ArrowLeft, ArrowRoundTrip, RenderSVG } from "../../../assets/icons";
import config from "../../../config.json";
import { DEFAULT_VALUES, INDEX } from "../../../constants";

const { ZERO } = DEFAULT_VALUES;
const { LAST } = INDEX;

const JourneySummary = ({ journey = [] }) => {
  const { departureDate } = journey[ZERO];

  const lastJourney = journey.at(LAST);
  const { destCode = {}, returnDate } = lastJourney;

  const journeyCities = journey
    .map((journey) => journey.originCode.cityName)
    .concat(destCode.cityName);

  return (
    <div className="flex flex-center 2xl:gap-4 gap-2 me-auto 2xl:text-lg lg:text-md text-sm text-contrast-900">
      {journeyCities.map(
        (city, index) =>
          city && (
            <span key={uuid()} className="flex items-center">
              {index !== ZERO && (
                <RenderSVG
                  Svg={returnDate ? ArrowRoundTrip : ArrowLeft}
                  width={returnDate ? "14" : "18"}
                  height={returnDate ? "14" : "18"}
                  alt="Left Arrow"
                  className="mr-3 text-contrast-900"
                  stroke={config.contrast}
                />
              )}
              <span>{city}</span>
            </span>
          )
      )}
      <span>•</span>
      <span className="whitespace-pre">
        {departureDate}
        {returnDate && <span> - {returnDate}</span>}
      </span>
    </div>
  );
};

export default JourneySummary;
