import { combineReducers } from "redux";
import * as search from "./components/organisms/Search";
import * as spinner from "./components/organisms/Spinner";
import * as pricing from "./screens/FlightResults";
import * as drawers from "./components/organisms/Drawer";
import * as profile from "./screens/Profile";
import * as booking from "./screens/Booking/FlightBookings";
import * as modal from "./components/organisms/Modal/";
import * as admin from "./screens/Admin";
import * as auth from "./screens/Auth";
import * as mytrips from "./screens/MyTrips";
import * as hotels from "./screens/HotelInfo";
import * as hotelBooking from "./screens/HotelBooking";

export default combineReducers({
  [search.name]: search.reducer,
  [pricing.name]: pricing.reducer,
  [booking.name]: booking.reducer,
  [profile.name]: profile.reducer,
  [auth.name]: auth.reducer,
  [drawers.name]: drawers.reducer,
  [modal.name]: modal.reducer,
  [spinner.name]: spinner.reducer,
  [admin.name]: admin.reducer,
  [mytrips.name]: mytrips.reducer,
  [hotels.name]: hotels.reducer,
  [hotelBooking.name]: hotelBooking.reducer,
});
