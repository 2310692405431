import { memo } from "react";
import {
  JourneyDetails,
  PricingSummaryCard,
} from "../../components/molecules/TripOverviewCard";
import Tabs from "../../components/organisms/Tabs/Tabs";
import MiniFareRulesTab from "./MiniFareRulesTab";
import {
  DEFAULT_VALUES,
  FLIGHT_PROVIDERS,
  MINI_FARE_RULES,
  SEARCH_RESULT_CARD_TABS,
} from "../../constants";

const { ZERO, ONE, TWO, THREE, FOUR } = DEFAULT_VALUES;
const { FLIGHT_DETAILS, FARE_SUMMARY, CANCELLATION, DATE_CHANGE } =
  SEARCH_RESULT_CARD_TABS;
const { CANCELLATION: CANCELLATION_RULE, REISSUE } = MINI_FARE_RULES;
const { TBO } = FLIGHT_PROVIDERS;

const FlightSearchResultCardTabs = ({ flight }) => {
  const { source, miniFareRules, itineraries, baggage, price } = flight;
  const isSourceTBO = source === TBO;
  const showMiniFareRulesTab = isSourceTBO && miniFareRules;

  let tabs = [
    {
      id: ONE,
      default: true,
      title: FLIGHT_DETAILS,
      element: (
        <JourneyDetails
          itineraries={itineraries}
          segments={itineraries[ZERO].segments}
          baggage={baggage}
        />
      ),
    },
    {
      id: TWO,
      title: FARE_SUMMARY,
      element: <PricingSummaryCard pricingInfo={price} />,
    },
  ];

  if (showMiniFareRulesTab)
    tabs = [
      ...tabs,
      {
        id: THREE,
        title: CANCELLATION,
        element: <MiniFareRulesTab flight={flight} type={CANCELLATION_RULE} />,
      },
      {
        id: FOUR,
        title: DATE_CHANGE,
        element: <MiniFareRulesTab flight={flight} type={REISSUE} />,
      },
    ];

  return <Tabs tabs={tabs} className="!p-3 !bg-gray-50" />;
};

export default memo(FlightSearchResultCardTabs);
