import React from "react";
import { get } from "lodash";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { DEFAULT_VALUES, CACHE_KEYS } from "../../../../constants";
import { getFromSessionStorage } from "../../../../helper";
import config from "../../../../config.json";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ROUTES } from "../../../../constants";
import { bookCallback } from "../index";
import { completePayment } from "../flightBookings.actions";
import { DEFAULT_CURRENCY_CODE, BOOKING_STATUS } from "../../../../constants";
import {
  setBookCallbackResponse,
  selectSelectedTravelers,
  updateBookingStatus,
  reissuanceCallback,
} from "../index";
import { selectCountryInfo } from "../../../Profile";
import { selectFlightPriceReq } from "../../../FlightResults";

const { brandName } = config;
const { EMPTY_STRING, ZERO } = DEFAULT_VALUES;
const { CANCELLED } = BOOKING_STATUS;

const ADULT_TRAVELER_TYPE = "Adult";

const { EMPTY_OBJECT, EMPTY_ARRAY } = DEFAULT_VALUES;
const { BOOKING_INFORMATION } = CACHE_KEYS;
const { FLIGHT_CONFIRM } = ROUTES;

const Payment = () => {
  const bookingInformation = getFromSessionStorage(BOOKING_INFORMATION);
  const { session } = bookingInformation;
  const paymentPageUrl = get(session, "paymentPageUrl", null);
  const IFRAME_HEIGHT = "100%";
  const IFRAME_WIDTH = "100%";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const selectedCountryInfo = useSelector(selectCountryInfo);
  const selectedTravelersInfo = useSelector(selectSelectedTravelers);
  const flightPriceReq = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;

  const currency = get(
    selectedCountryInfo,
    "currency.code",
    DEFAULT_CURRENCY_CODE
  );
  const { isReissuanceFlight, reissuanceDetails } = bookingInformation;

  const id = get(session, "id", EMPTY_STRING);
  const tavaBookingId = get(bookingInformation, "tavaBookingId", EMPTY_STRING);

  const firstAdultTraveler = selectedTravelersInfo.find(
    (traveler) => traveler.travelerType === ADULT_TRAVELER_TYPE
  );

  get(firstAdultTraveler, "profileDetails", EMPTY_OBJECT);
  const { source, endUserIp, resultIndex, traceId } =
    flightPriceReq[ZERO] || EMPTY_OBJECT;

  const [showFrame, setShowFrame] = useState(true);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    const clientRef = params.get("clientRef");
    const reqid = params.get("reqid");
    if (clientRef && reqid) {
      dispatch(completePayment({ clientRef: clientRef, reqid: reqid })).then(
        ({ payload }) => {
          if (payload) {
            const { responseCode, transactionId } = payload;
            const bookCallbackQueryParams = {
              source,
              paymentSessionId: id,
              tavaBookingId,
              paymentGateway: "BANCSTAC",
              currency,
            };
            const updateBookingStatusRequest = {
              source,
              bookingStatus: CANCELLED,
              paymentStatus: CANCELLED,
            };
            if (transactionId && responseCode === "00") {
              dispatch(setBookCallbackResponse(EMPTY_OBJECT));
              const paymentId = transactionId;
              const queryParams = { ...bookCallbackQueryParams, paymentId };
              const reissuanceCallbackRequest = isReissuanceFlight && {
                queryParams,
                requestBody: {
                  ...reissuanceDetails,
                  endUserIp,
                  ResultIndex: resultIndex,
                  TraceId: traceId,
                  tavaBookingId,
                  isRefundable: false,
                },
              };
              isReissuanceFlight
                ? dispatch(reissuanceCallback(reissuanceCallbackRequest))
                : dispatch(
                    bookCallback({
                      queryParams,
                    })
                  );
              // navigate(FLIGHT_CONFIRM);
              window.top.location.href = FLIGHT_CONFIRM;
            } else {
              dispatch(setBookCallbackResponse(EMPTY_OBJECT));
              dispatch(
                updateBookingStatus({
                  requestBody: updateBookingStatusRequest,
                  id: tavaBookingId,
                })
              );
              // navigate(FLIGHT_CONFIRM);
              window.top.location.href = FLIGHT_CONFIRM;
            }
          }
        }
      );
    }
  }, EMPTY_ARRAY);

  useEffect(() => {
    if (location.search) {
      setShowFrame(false);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>{brandName} | Flight Payment</title>
      </Helmet>
      {showFrame && (
        <div style={{ height: "100vh" }}>
          <iframe
            src={paymentPageUrl}
            width={IFRAME_WIDTH}
            height={IFRAME_HEIGHT}
          />
        </div>
      )}
    </>
  );
};

export default Payment;
