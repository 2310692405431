import { get } from "lodash";
import { removeLeadingZerosFromDate } from "./dateFormatter";
import { DEFAULT_VALUES } from "../constants";

const { ZERO, ONE } = DEFAULT_VALUES;

export const getUniqueFlightResults = (flights = []) => {
  const uniqueFlightsMap = new Map();

  flights.forEach((flight) => {
    let flightKey = "";
    const { itineraries = [] } = flight;

    itineraries.forEach((itinerary) => {
      const { segments = [] } = itinerary;
      const firstSegment = segments[ZERO];
      const lastSegment = segments[segments.length - ONE];
      const {
        carrierName,
        departure: { time: departureTime, date: departureDate },
        flightNumber,
      } = firstSegment;
      const {
        arrival: { time: arrivalTime, date: arrivalDate },
      } = lastSegment;
      const formattedDepartureDate = removeLeadingZerosFromDate(departureDate);
      const formattedArrivalDate = removeLeadingZerosFromDate(arrivalDate);

      flightKey += `${carrierName.trim()}_${flightNumber}_${departureTime}_${formattedDepartureDate}_${arrivalTime}_${formattedArrivalDate}`;
    });

    if (uniqueFlightsMap.has(flightKey)) {
      const existingFlight = uniqueFlightsMap.get(flightKey);
      const currentFlightPrice = get(flight, "price.totalPrice", ZERO);
      const existingFlightPrice = get(existingFlight, "price.totalPrice", ZERO);
      if (currentFlightPrice < existingFlightPrice) {
        uniqueFlightsMap.set(flightKey, flight);
      }
    } else {
      uniqueFlightsMap.set(flightKey, flight);
    }
  });

  return Array.from(uniqueFlightsMap.values());
};
