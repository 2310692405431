import { useState, useEffect } from "react";
import classNames from "classnames";
import get from "lodash/get";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { fetchBookings, selectBookings } from "..";
import BookingTable from "./BookingTable";
import AdminPageHeader from "../Widgets/AdminPageHeader";
import Pagination from "../Widgets/Pagination";
import LoadingSpinner, {
  SPINNER_NAMES,
} from "../../../components/organisms/Spinner";
import { DEFAULT_VALUES, ADMIN_BOOKING_TABS } from "../../../constants";
import BookingFilter from "./BookingFilter";
import config from "../../../config.json";

const { ZERO, ONE, EMPTY_OBJECT, EMPTY_STRING, FIFTEEN } = DEFAULT_VALUES;
const { BOOKINGS } = SPINNER_NAMES;
const { brandName } = config;
const GLOBAL = "q";

const Bookings = () => {
  const dispatch = useDispatch();
  const allBookings = useSelector(selectBookings);
  const {
    count,
    totalPage,
    currentPage: apiCurrentPage,
    size,
  } = get(allBookings, "countInfo", EMPTY_OBJECT);

  const [activeFilter, setActiveFilter] = useState(EMPTY_STRING);
  const [filterValue, setFilterValue] = useState(EMPTY_STRING);
  const [limit, setLimit] = useState(size || FIFTEEN);
  const [currentPage, setCurrentPage] = useState(
    apiCurrentPage ? apiCurrentPage - ONE : ZERO
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const status = get(params, "status", "All");

  useEffect(() => {
    let params = {};
    if (status && status !== "All") params.status = status.toUpperCase();
    const tabIndex = ADMIN_BOOKING_TABS.findIndex((item) => item === status);
    tabIndex != -ONE &&
      dispatch(
        fetchBookings({
          page: currentPage + 1,
          pageSize: limit,
          ...params,
        })
      );
  }, [currentPage, limit]);

  const handleTabChange = (status) => {
    let params = {};
    if (status && status !== "All") params.status = status.toUpperCase();
    setSearchParams({ status });
    dispatch(
      fetchBookings({
        page: ONE,
        pageSize: limit,
        ...params,
      })
    );
  };

  const handleFilterSearch = (activeFilter, filterValue) => {
    let updatedParams = status && status !== "All" ? { ...params } : {};
    if (activeFilter !== EMPTY_STRING)
      updatedParams[activeFilter] = filterValue;
    dispatch(
      fetchBookings({
        page: ONE,
        pageSize: limit,
        ...updatedParams,
      })
    );
  };

  return (
    <div className="px-8">
      <Helmet>
        <title>{brandName} | Admin Bookings</title>
      </Helmet>
      <AdminPageHeader title="Booking History" />
      <div className="flex sm:flex-row flex-col gap-6 p-2 justify-between no-scrollbar">
        <div className="col-auto gap-4 flex flex-nowrap px-2">
          {ADMIN_BOOKING_TABS.map((tabStatus) => (
            <button
              key={tabStatus}
              className={classNames(
                "text-contrast-900 font-medium text-lg hover:text-contrast-500",
                {
                  "focus:underline underline-offset-8 text-primary-600 ":
                    tabStatus === status,
                }
              )}
              onClick={() => handleTabChange(tabStatus)}
            >
              {tabStatus}
              {}
            </button>
          ))}
        </div>
        <div className="flex">
          <BookingFilter setActiveFilter={setActiveFilter} />
          <div className="flex">
            <input
              type="text"
              className="form-control block w-full bg-contrast-100 text-sm py-2 px-3 border-2 border-gray-300 placeholder:text-blue-gray-500 disabled:cursor-not-allowed"
              placeholder={
                activeFilter === GLOBAL
                  ? "Search Globally"
                  : `Enter ${activeFilter}`
              }
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              disabled={!activeFilter}
            />
            <button
              className="py-[10px] px-4 rounded-r-lg bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-600 shadow-sm text-sm text-white font-medium disabled:cursor-not-allowed disabled:opacity-70"
              onClick={() => handleFilterSearch(activeFilter, filterValue)}
              disabled={!filterValue}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full min-h-[70vh]">
        <LoadingSpinner name={BOOKINGS} message="Fetching Results">
          <div className="rounded-b px-4">
            <BookingTable data={allBookings?.result} />
            <Pagination
              totalPage={totalPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalCount={count}
              limit={limit}
              setLimit={setLimit}
            />
          </div>
        </LoadingSpinner>
      </div>
    </div>
  );
};

export default Bookings;
