import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { RenderSVG } from "../../../assets/icons";
import config from "../../../config.json";
import { INDEX, LINK_SECTION, ROUTES } from "../../../constants";

const { logoWithBrand } = config;
const {
  HOME,
  PRIVACY_POLICY,
  PAYMENT_SECURITY,
  COOKIE_POLICY,
  CANCELLATION_AND_REFUND_POLICY,
  CONTACT_US,
  TERMS_AND_CONDITION,
  SHIPPING_AND_DELIVERY,
} = ROUTES;
const { THIRD } = INDEX;

const FeatureCard = ({ title, content, logo, imageType }) => (
  <div className="w-full md:w-1/3 px-4 mb-4 ">
    <div className="flex gap-3  mb-2">
      {imageType === "image" ? (
        <img src={logo} width={25} height={20} alt="icons" />
      ) : (
        <RenderSVG Svg={logo} width={35} height={30} alt="" />
      )}
      <h2 className=" text-white text-xl font-extrabold">{title}</h2>
    </div>
    <p className="text-white text-xs">{content}</p>
  </div>
);

const LinkList = ({ title, links, t }) => (
  <div className="mb-2 md:p-8 p-4">
    <h3 className="font-extrabold mb-3 md:mr-2 text-base ">{t(title)}</h3>
    <div
      className={classNames("text-sm ", {
        "md:grid md:grid-cols-2 gap-x-16": links.length > THIRD,
      })}
    >
      {links?.map(({ name, route }) => (
        <div key={name} className="text-xs sm:text-sm text-contrast-600 mb-3">
          <Link to={route} className="hover:text-primary-400">
            {t(name)}
          </Link>
        </div>
      ))}
    </div>
  </div>
);

const Footer = () => {
  const path = window.location.pathname;
  const { t } = useTranslation();

  return (
    [
      HOME,
      PRIVACY_POLICY,
      PAYMENT_SECURITY,
      COOKIE_POLICY,
      CANCELLATION_AND_REFUND_POLICY,
      CONTACT_US,
      TERMS_AND_CONDITION,
      SHIPPING_AND_DELIVERY,
    ].includes(path) && (
      <footer className="border-t border-contrast-200 text-contrast-600 bg-white w-full cursor-default font-medium">
        <div className="flex lg:px-10 sm:px-6 bg-contrast-200 text-black ">
          <div className="w-full  px-4 lg:px-12 py-2 flex">
            <div className="h-8 md:p-8 md:w-64 sm:w-52 w-28 py-5">
              <img src={logoWithBrand} alt="brand-icon" />
            </div>
            {LINK_SECTION.map(({ title, links }) => (
              <LinkList key={title} title={title} links={links} t={t} />
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between gap-4 lg:gap-10 bg-white md:px-6 md:py-2 p-2">
          <div className="flex flex-row md:text-base text-xs">
          </div>
          <div className="md:text-base text-xs">
            Powered by {" "}
            <a
              className="text-indigo-600 hover:text-indigo-800 underline"
              href="https://bancstac.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bancstac
            </a>
          </div>
        </div>
      </footer>
    )
  );
};

export default Footer;
