import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import TravelerDetailsCard from "../../../components/organisms/Traveler/TravelerDetailsCard";
import GstDetailCard from "../../../components/organisms/Traveler/GstDetailCard";
import {
  ChevronDown,
  ChevronUp,
  Plus,
  RenderSVG,
  EditIcon,
} from "../../../assets/icons";
import {
  DEFAULT_VALUES,
  CACHE_KEYS,
  TRAVELER_TYPE,
  TBO_FARE_TYPES,
  FLIGHT_SERVICE_TYPE,
  INDEX,
} from "../../../constants";
import { selectTravelerInfo } from "../../Profile";
import { setToSessionStorage } from "../../../helper";
import ManageBookingTraveler from "./ManageBookingTraveler";
import {
  selectSelectedTravelers,
  setSelectedTravelers,
  selectBookingTravelers,
  setBookingTravelers,
} from "./index";
import {
  selectFlightPriceInfo,
  selectIsInternationalFlight,
  selectFlightPriceReq,
} from "../../FlightResults";
import ErrorMessage from "../../../components/atoms/ErrorMessage";
import ContactInformation from "./ContactInformation";
import AddressInformation from "./AddressInformation";
import { selectAuthInfo } from "../../Auth";
import { selectSearchFilters } from "../../../components/organisms/Search";

const {
  ZERO,
  EMPTY_OBJECT,
  TIMEOUT_DURATION,
  EMPTY_STRING,
  MILLISECONDS_PER_YEAR,
} = DEFAULT_VALUES;
const { LAST } = INDEX;
const { ADULT, CHILD, HELD_INFANT } = TRAVELER_TYPE;
const { TRAVELERS_INFORMATION } = CACHE_KEYS;
const { REGULAR, SENIOR_CITIZEN } = TBO_FARE_TYPES;
const { AMADEUS } = FLIGHT_SERVICE_TYPE;

const MIN_AGE_FOR_SENIOR_CITIZEN = 60;

const travelerTypeKeys = {
  Adult: "adult",
  Child: "children",
  Infant: "infants",
};

const RenderTraveler = ({
  traveler,
  handleEditTraveler,
  checkboxDisabled,
  handleTravelerSelection,
  documentsRequired,
  isReissuanceFlight,
}) => {
  const selectedFlightPriceInfo = useSelector(selectFlightPriceInfo);
  const selectedTravelers = useSelector(selectSelectedTravelers);

  const { profileDetails = {}, travelerId = EMPTY_STRING } = traveler;
  const { title, firstName, middleName, lastName } = profileDetails;
  const fareType = get(selectedFlightPriceInfo, "0.fareType", REGULAR);
  const showFareCategoryDocuments = fareType !== REGULAR;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 100,
    defaultExpanded: false,
  });

  const isPaxPresent = Boolean(
    selectedTravelers.find((pax) => pax.travelerId === travelerId)
  );

  return (
    <div
      key={travelerId}
      className={classNames(
        "rounded-lg border flex-col justify-center items-center my-3 mx-4",
        {
          "bg-primary-100 border-primary-600": isPaxPresent,
          "bg-white border-contrast-200": !isPaxPresent,
        }
      )}
    >
      <div className="flex p-4 justify-between flex-col md:flex-row items-center gap-4 w-full min-h-[100%]">
        <div className="flex items-center justify-center gap-2 px-0 text-contrast-900 text-lg font-normal flex-wrap">
          <input
            className={classNames(
              "w-6 h-6 rounded-md cursor-pointer disabled:bg-contrast-300 disabled:cursor-not-allowed",
              {
                "bg-primary-600": isPaxPresent,
              }
            )}
            type="checkbox"
            value={isPaxPresent}
            checked={isPaxPresent}
            disabled={checkboxDisabled && !isPaxPresent}
            onChange={(e) =>
              handleTravelerSelection(traveler, e.target.checked)
            }
          />
          <div className="flex flex-wrap truncate">
            {title && `${title}.`}&nbsp;
            {firstName}&nbsp;
            {middleName}&nbsp;
            {lastName}&nbsp;
          </div>
        </div>
        {!isReissuanceFlight && (
          <div className="justify-between items-center gap-4 flex">
            <button
              onClick={() => handleEditTraveler(travelerId)}
              className="flex text-contrast-700 text-xs py-2 px-4 items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm font-medium"
            >
              Edit
            </button>
            <div
              className="p2 justify-center items-center flex"
              {...getToggleProps()}
            >
              <RenderSVG
                className="w-5 h-5 relative"
                Svg={isExpanded ? ChevronUp : ChevronDown}
                alt="chevron-up-down"
                color="bg-gray-700"
              />
            </div>
          </div>
        )}
      </div>
      <div {...getCollapseProps()}>
        <TravelerDetailsCard
          traveler={traveler}
          shouldShowPassportDetails={documentsRequired}
          showFareCategoryDocuments={showFareCategoryDocuments}
        />
      </div>
    </div>
  );
};

const RenderTravelerSection = ({
  label,
  values,
  documentsRequired,
  isReissuanceFlight,
  isGoingToNepal,
  validationSchema,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showManageTraveler, setShowManageTraveler] = useState(false);
  const [currentTravelerId, setCurrentTravelerId] = useState(null);
  const [showError, setShowError] = useState(false);
  const selectedTravelerDetails = useSelector(selectSelectedTravelers);
  const selectedFlightPriceInfo = useSelector(selectFlightPriceInfo);
  const [errorMessage, setErrorMessage] = useState(null);
  const isInternationalFlight = useSelector(selectIsInternationalFlight);
  const flightPriceReq = useSelector(selectFlightPriceReq) || EMPTY_ARRAY;
  const searchFilters = useSelector(selectSearchFilters);

  const { passengersCount = EMPTY_OBJECT } = searchFilters || EMPTY_OBJECT;
  const { source = EMPTY_STRING, fareType = REGULAR } = get(
    selectedFlightPriceInfo,
    "0",
    EMPTY_OBJECT
  );
  const isInternationalAMFlight = source === AMADEUS && isInternationalFlight;
  const isFareTypeDocumentRequired = fareType !== REGULAR;
  const travelerCountKey = travelerTypeKeys[label];
  const travelerCount = +passengersCount[travelerCountKey];

  const {
    IsPanRequiredAtBook,
    IsPanRequiredAtTicket,
    IsPassportRequiredAtBook,
    IsPassportRequiredAtTicket,
  } = documentsRequired;

  const TRAVELER_MAPPING = {
    [ADULT]: t("travelerInfo.adult"),
    [CHILD]: t("travelerInfo.child"),
    [HELD_INFANT]: t("travelerInfo.infant"),
  };

  const AGE_MAPPING = {
    [ADULT]: t("searchSection.ages.adult"),
    [CHILD]: t("searchSection.ages.children"),
    [HELD_INFANT]: t("searchSection.ages.infants"),
  };

  const selectedTravelersCount = selectedTravelerDetails.filter(
    ({ travelerType }) => travelerType === label
  ).length;

  const isCheckboxDisabled = travelerCount <= selectedTravelersCount;

  const openManageTravelerModal = (travelerId) => {
    setShowManageTraveler(true);
    setCurrentTravelerId(travelerId);
  };

  const showErrorMessage = () => {
    setShowError(true);
    setTimeout(() => setShowError(false), TIMEOUT_DURATION);
  };

  const lastFlightSegment =
    flightPriceReq
      .slice(LAST)
      [ZERO]?.price?.itineraries?.slice(LAST)
      [ZERO]?.segments?.slice(LAST)[ZERO] || {};

  const lastDepartureDate = get(
    lastFlightSegment,
    "departure.date",
    EMPTY_STRING
  );
  const handleTravelerSelection = (traveler, isSelected) => {
    let updatedSelectedTravelers;
    if (isSelected) {
      const passportDetails = Object.values(traveler.passportDetails).every(
        (value) => value
      );
      const documentDetails =
        traveler.document &&
        Object.values(traveler.document).every((value) => value);
      const maxTravelerAges = {
        Adult: 150,
        Child: 12,
        Infant: 2,
      };
      let isAgeInvalid = false;
      const date = traveler.profileDetails.dateOfBirth;
      const startDate = new Date(date);
      const endDate = new Date(lastDepartureDate);
      const timeDifference = endDate.getTime() - startDate.getTime();
      const yearsDifference = timeDifference / MILLISECONDS_PER_YEAR;
      isAgeInvalid =
        yearsDifference > (maxTravelerAges[traveler.travelerType] || 0);
      if (isAgeInvalid) {
        setErrorMessage(
          traveler.travelerType +
            " age must be below " +
            maxTravelerAges[traveler.travelerType] +
            " years on the last departure date."
        );
      }

      const isPassportDetailsMissing =
        (IsPassportRequiredAtBook ||
          IsPassportRequiredAtTicket ||
          isInternationalAMFlight ||
          (isGoingToNepal && traveler.travelerType !== HELD_INFANT)) &&
        !passportDetails;
      const isDocumentDetailsMissing =
        isFareTypeDocumentRequired && !documentDetails;
      const isInvalidDateOfBirth =
        fareType === SENIOR_CITIZEN &&
        Math.abs(
          moment(new Date(), "YYYY-MM-DD").diff(
            traveler.profileDetails.dateOfBirth,
            "years"
          )
        ) < MIN_AGE_FOR_SENIOR_CITIZEN;
      if (
        isPassportDetailsMissing ||
        isDocumentDetailsMissing ||
        isInvalidDateOfBirth ||
        isAgeInvalid
      ) {
        openManageTravelerModal(traveler.travelerId);
        return;
      }
      const isPanCardRequired = IsPanRequiredAtBook || IsPanRequiredAtTicket;
      const isPassportRequired =
        IsPassportRequiredAtBook || IsPassportRequiredAtTicket;
      const travelerWithRequirements = {
        ...traveler,
        isPanCardRequired,
        isPassportRequired,
      };
      updatedSelectedTravelers = [
        ...selectedTravelerDetails,
        travelerWithRequirements,
      ];
    } else {
      updatedSelectedTravelers = selectedTravelerDetails.filter(
        ({ travelerId }) => travelerId !== traveler.travelerId
      );
    }
    dispatch(setSelectedTravelers(updatedSelectedTravelers));
    setToSessionStorage(TRAVELERS_INFORMATION, updatedSelectedTravelers);
  };

  const RenderAddNewTraveler = () => (
    <button
      className="py-2 px-4 rounded-md bg-primary-100 hover:bg-primary-200 text-primary-700 text-md leading-tight mx-4"
      onClick={() =>
        isCheckboxDisabled ? showErrorMessage() : openManageTravelerModal()
      }
    >
      <RenderSVG className="w-5 h-5 pr-1 inline" Svg={Plus} alt="plus" />
      <span>
        {t("travelerInfo.add")} {TRAVELER_MAPPING[label]}
      </span>
    </button>
  );
  if (travelerCount)
    return (
      <>
        <div key={label} className="border rounded-lg">
          <div className="flex justify-between border-b p-2 font-semibold">
            <div className="text-contrast-900 flex flex-row gap-2">
              {TRAVELER_MAPPING[label]} {AGE_MAPPING[label]}
              {isCheckboxDisabled && showError && (
                <ErrorMessage
                  errorMessage={`You have already selected ${travelerCount} ${
                    TRAVELER_MAPPING[label]
                  }${travelerCount > 1 ? "s" : ""}.
                  Please unselect before adding/selecting another one.`}
                  timeoutDuration={3000}
                />
              )}
            </div>
            <div className="flex row">
              <span className="text-contrast-900">{`${selectedTravelersCount}/${travelerCount}`}</span>
              &nbsp;
              <span className="text-contrast-400 font-normal">
                {t("travelerInfo.added")}
              </span>
            </div>
          </div>
          <div className="p-2">
            {!isEmpty(values)
              ? values.map((value) => (
                  <RenderTraveler
                    key={value.travelerId}
                    traveler={value}
                    handleEditTraveler={openManageTravelerModal}
                    handleTravelerSelection={handleTravelerSelection}
                    checkboxDisabled={isCheckboxDisabled}
                    documentsRequired={
                      IsPassportRequiredAtBook || IsPassportRequiredAtTicket
                    }
                    isReissuanceFlight={isReissuanceFlight}
                  />
                ))
              : !isReissuanceFlight && (
                  <div className="p-4 flex justify-center content-center">
                    <RenderAddNewTraveler />
                  </div>
                )}
            {!isEmpty(values) && !isReissuanceFlight && (
              <div>
                <RenderAddNewTraveler />
              </div>
            )}
          </div>
        </div>
        {showManageTraveler && (
          <ManageBookingTraveler
            travelerType={label}
            showManageTraveler={showManageTraveler}
            setShowManageTraveler={setShowManageTraveler}
            travelerId={currentTravelerId}
            documentsRequired={documentsRequired}
            dateErrorMessage={errorMessage}
            setDateErrorMessage={setErrorMessage}
            returnDate={lastDepartureDate}
            isGoingToNepal={isGoingToNepal}
            validationSchema={validationSchema}
          />
        )}
      </>
    );
};

const BookingTravelers = ({
  selectionProgress,
  setSelectionProgress,
  contactDefaultValues,
  addressDefaultValues,
  gstFormRef,
  addressFormRef,
  contactFormRef,
  isReissuanceFlight,
  isGoingToNepal,
  travelersSortTravelerType,
  travelerValidationSchema = {},
}) => {
  const dispatch = useDispatch();
  const { getCollapseProps, getToggleProps, setExpanded, isExpanded } =
    useCollapse({
      duration: 400,
      defaultExpanded: false,
    });
  const { t } = useTranslation();
  const selectedTravelerInfo = useSelector(selectTravelerInfo);
  const selectedBookingTraveler = useSelector(selectBookingTravelers);
  const selectedFlightPriceInfo = useSelector(selectFlightPriceInfo);
  const selectedAuthInfo = useSelector(selectAuthInfo);
  const [bookingRequirements, setBookingRequirements] = useState(EMPTY_OBJECT);

  const { GSTAllowed } = bookingRequirements;

  useEffect(() => {
    const profileTravelers = !isEmpty(selectedTravelerInfo)
      ? selectedTravelerInfo
      : selectedBookingTraveler;

    dispatch(setBookingTravelers(profileTravelers));
  }, [dispatch, selectedAuthInfo, selectedTravelerInfo]);

  useEffect(() => {
    const bookingRequirements = get(
      selectedFlightPriceInfo,
      "0.documentsRequired",
      EMPTY_OBJECT
    );

    const documentRequirement = Object.entries(bookingRequirements).reduce(
      (documentRequirement, [travelerType, documentsRequired]) => {
        documentRequirement[travelerType] = documentsRequired;
        return documentRequirement;
      },
      {}
    );
    setBookingRequirements(documentRequirement);
  }, [selectedFlightPriceInfo]);

  useEffect(() => {
    setExpanded(!selectionProgress?.travelerSelection);
  }, [selectionProgress?.travelerSelection]);

  return (
    <div className="w-full">
      <fieldset disabled={isReissuanceFlight}>
        <div
          className={classNames(
            "text-contrast-900 text-lg font-bold p-6 border bg-white border-contrast-200 flex justify-between",
            {
              "rounded-t-lg": isExpanded,
            },
            {
              "rounded-lg": !isExpanded,
            }
          )}
        >
          <span>{t("travelerInfo.title")}</span>
          {selectionProgress?.travelerSelection && (
            <button
              onClick={() =>
                setSelectionProgress({
                  ...selectionProgress,
                  travelerSelection: false,
                })
              }
            >
              <RenderSVG
                Svg={EditIcon}
                className="w-6 h-6"
                alt="Toggle dropdown"
                {...getToggleProps()}
              />
            </button>
          )}
        </div>
        <div
          className="col-span-12 lg:col-span-7 bg-white border border-contrast-200 w-full rounded-b-lg"
          {...getCollapseProps()}
        >
          <div className="p-4 flex flex-col gap-3">
            {Object.entries(travelersSortTravelerType).map(([key, values]) => (
              <RenderTravelerSection
                key={key}
                label={key}
                values={values}
                documentsRequired={bookingRequirements}
                isReissuanceFlight={isReissuanceFlight}
                isGoingToNepal={isGoingToNepal}
                validationSchema={travelerValidationSchema[key]}
              />
            ))}
          </div>
          {GSTAllowed && (
            <div className="border-t border-contrast-200">
              <GstDetailCard
                documentsRequired={bookingRequirements}
                gstFormRef={gstFormRef}
              />
            </div>
          )}
          <div className={classNames("pt-1 border-t border-contrast-200")}>
            <ContactInformation
              defaultValues={contactDefaultValues}
              contactFormRef={contactFormRef}
            />
          </div>
          <div className="my-2 border-t border-contrast-200">
            <AddressInformation
              defaultValues={addressDefaultValues}
              addressFormRef={addressFormRef}
            />
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default BookingTravelers;
