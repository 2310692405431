import { MODALS } from "../AppModals/modals.constants";
import LogInAndSignupModal from "../../../screens/Auth/AuthModals/LogInAndSignupModal";
import WelcomeModal from "../../../screens/Auth/AuthModals/WelcomeModal";
import APIFailureModal from "./APIFailureModal/APIFailureModal";
import LastNameAmendmentModal from "../Traveler/LastNameAmendmentModal";
import FlightReIssuanceModal from "./FlightReIssuanceModal";
import RefundModal from "./RefundModal";
import SessionExpiredModal from "../../../infrastructure/SessionProvider/SessionExpiredModal";
import BookingCancellationModal from "./FlightCancellationModal";
import AmenitiesModal from "./AmenitiesModal";
import { ROUTES } from "../../../constants";
import HotelFacilitiesModal from "./HotelFacilityModal/HotelFacilityModal";

const {
  LOGIN_MODAL,
  SIGNUP_MODAL,
  WELCOME_CARD_MODAL,
  API_FAILURE_MODAL,
  LAST_NAME_AMENDMENT_MODAL,
  FLIGHT_REISSUANCE_MODAL,
  REFUND_MODAL,
  SESSION_EXPIRED_MODAL,
  BOOKING_CANCELLATION_MODAL,
  AMENITIES_MODAL,
  HOTEL_FACILITIES_MODAL,
} = MODALS;
const { SIGNUP } = ROUTES;

const renderModal = (name) =>
  ({
    [LOGIN_MODAL]: <LogInAndSignupModal />,
    [SIGNUP_MODAL]: <LogInAndSignupModal enroll={SIGNUP} />,
    [WELCOME_CARD_MODAL]: <WelcomeModal />,
    [LAST_NAME_AMENDMENT_MODAL]: <LastNameAmendmentModal />,
    [API_FAILURE_MODAL]: <APIFailureModal />,
    [FLIGHT_REISSUANCE_MODAL]: <FlightReIssuanceModal />,
    [REFUND_MODAL]: <RefundModal />,
    [SESSION_EXPIRED_MODAL]: <SessionExpiredModal />,
    [BOOKING_CANCELLATION_MODAL]: <BookingCancellationModal />,
    [AMENITIES_MODAL]: <AmenitiesModal />,
    [HOTEL_FACILITIES_MODAL]: <HotelFacilitiesModal />,
  }[name] || null);

export default renderModal;

export { MODALS };
