import { get } from "lodash";
import { v4 as uuid } from "uuid";
import { generateTavaBookingId } from "./generateBookingId";
import { mapGuestDetails } from "./mapGuestDetails";
import {
  DEFAULT_VALUES,
  DEFAULT_CURRENCY_CODE,
  BOOKING_TYPES,
  CACHE_KEYS,
  SEARCH_SECTION
} from "../constants";
import { getPriceWithConversion } from "./priceFormatter";
import { getFromSessionStorage, getSurchargesAmount } from "./index";

const { ZERO, ONE, EMPTY_ARRAY, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { NON_VOUCHER_BOOKING } = BOOKING_TYPES;
const { CURRENCY_INFO } = CACHE_KEYS;
const { HOTEL } = SEARCH_SECTION;

export const mapPaymentReq = ({ userCountryInfo, blockRoomRes, leadGuest }) => {
  const code = get(userCountryInfo, "currency.code", DEFAULT_CURRENCY_CODE);
  const { exchangeRate } = getFromSessionStorage(CURRENCY_INFO);

  const baseURL = window.location.href.replace(
    window.location.pathname,
    EMPTY_STRING
  );
  const roomDetails = get(
    blockRoomRes,
    "BlockRoomResult.HotelRoomsDetails",
    EMPTY_ARRAY
  );
  const totalPublishedPrice = roomDetails.reduce(
    (total, room) => total + room.Price.PublishedPriceRoundedOff,
    0
  );
  const totalAmount =
    totalPublishedPrice + getSurchargesAmount(totalPublishedPrice);
  const {
    email,
    phoneNumber: contactNo,
    firstName: name,
  } = get(leadGuest, "profileDetails", EMPTY_OBJECT);

  return {
    division: "HOTEL",
    successUrl: `${baseURL}/booking-confirmed`,
    cancelUrl: `${baseURL}/booking-failed`,
    lineItems: [
      {
        price_data: {
          currency: code,
          product_data: {
            name,
            images: EMPTY_ARRAY,
            description: EMPTY_STRING,
            metadata: {
              id: ONE.toString(),
            },
          },
          unit_amount: (totalAmount * 100).toString(),
        },
        quantity: ONE,
      },
    ],
    selectedPaymentPortal: "RAZORPAY",
    cardInfo: {
      billerDetails: {
        billerAddress: EMPTY_STRING,
        billerCity: EMPTY_STRING,
        billerCountry: EMPTY_STRING,
        billerPin: EMPTY_STRING,
        billerState: EMPTY_STRING,
      },
      cardDetails: {
        number: EMPTY_STRING,
        name: EMPTY_STRING,
        expiry_month: EMPTY_STRING,
        expiry_year: EMPTY_STRING,
        cvv: EMPTY_STRING,
      },
      fetchBinFailed: null,
      saveCard: null,
    },
    checkoutId: EMPTY_STRING,
    payMode: EMPTY_STRING,
    payOption: EMPTY_STRING,
    networkConsent: false,
    deviceFingerPrintID: EMPTY_STRING,
    mcid: EMPTY_STRING,
    enforceOtpOnPage: false,
    version: "1.5",
    msgId: EMPTY_STRING,
    operation: "PAYMENT_INIT",
    requestDate: new Date().toISOString().split(".")[ZERO],
    validateOnly: false,
    requestData: {
      clientId: "14000004",
      clientIdHash: EMPTY_STRING,
      transactionType: "PURCHASE",
      transactionAmount: {
        totalAmount: totalAmount,
        paymentAmount: totalAmount,
        serviceFeeAmount: ZERO,
        currency: code,
      },
      redirect: {
        returnUrl: EMPTY_STRING,
        cancelUrl: EMPTY_STRING,
        returnMethod: "GET",
      },
      clientRef: uuid(),
      comment: EMPTY_STRING,
      tokenize: true,
      cssLocation1: EMPTY_STRING,
      cssLocation2: EMPTY_STRING,
      useReliability: true,
      "extraData ": "{1,2,3}",
    },
    amount: getPriceWithConversion(totalAmount, exchangeRate).toFixed(2),
    currency: code,
    email,
    contactNo,
  };
};

const mapBookingRequest = ({
  pricePolicyReqBody,
  leadGuest,
  otherGuests,
  isSamePANForAllAllowed,
  RoomGuests,
  loggedInUserEmail,
}) => {
  const tavaBookingId = generateTavaBookingId();
  const endUserIp = get(pricePolicyReqBody, "EndUserIp", EMPTY_STRING);
  const traceId = get(pricePolicyReqBody, "TraceId", EMPTY_STRING);
  const resultIndex = get(pricePolicyReqBody, "ResultIndex", ZERO);
  const hotelCode = get(pricePolicyReqBody, "HotelCode", ZERO);
  const categoryId = get(pricePolicyReqBody, "CategoryId", EMPTY_STRING);
  const hotelName = get(pricePolicyReqBody, "HotelName", ZERO);
  const guestNationality = get(pricePolicyReqBody, "GuestNationality", ZERO);
  const noOfRooms = get(pricePolicyReqBody, "NoOfRooms", ZERO);
  const isVoucherBooking = get(pricePolicyReqBody, "IsVoucherBooking", ZERO);
  let hotelRoomsDetails = get(
    pricePolicyReqBody,
    "HotelRoomsDetails",
    EMPTY_ARRAY
  );
  hotelRoomsDetails = hotelRoomsDetails.map((room, index) => ({
    ...room,
    HotelPassenger: mapGuestDetails(
      leadGuest,
      otherGuests,
      isSamePANForAllAllowed,
      RoomGuests,
      index
    ),
  }));
  const accountEmail =
    loggedInUserEmail || get(leadGuest, "profileDetails.email", "");

  return {
    tavaBookingId: tavaBookingId,
    accountEmail,
    ResultIndex: resultIndex,
    HotelCode: hotelCode,
    HotelName: hotelName,
    GuestNationality: guestNationality,
    NoOfRooms: noOfRooms,
    IsVoucherBooking: isVoucherBooking,
    CategoryId: categoryId,
    EndUserIp: endUserIp,
    TraceId: traceId,
    HotelRoomsDetails: hotelRoomsDetails,
  };
};

export const mapHotelBookingAPIReq = ({
  pricePolicyReqBody,
  blockRoomRes,
  userCountryInfo,
  bookingType,
  leadGuest,
  otherGuests,
  isSamePANForAllAllowed,
  RoomGuests,
  loggedInUserEmail,
}) => {
  if (bookingType === NON_VOUCHER_BOOKING) {
    pricePolicyReqBody = {
      ...pricePolicyReqBody,
      IsVoucherBooking: "false",
    };
  }

  return {
    paymentRequest: mapPaymentReq({
      userCountryInfo,
      blockRoomRes,
      leadGuest,
    }),

    bookingRequest: mapBookingRequest({
      pricePolicyReqBody,
      leadGuest,
      otherGuests,
      isSamePANForAllAllowed,
      RoomGuests,
      loggedInUserEmail,
    }),
    blockRoomReq: pricePolicyReqBody,
    blockRoomRes,
  };
};
