import { useCollapse } from "react-collapsed";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { DEFAULT_VALUES } from "../../../constants";
import { ChevronDown, Checkbox, RenderSVG } from "../../../assets/icons";

const { EMPTY_ARRAY } = DEFAULT_VALUES;

const CategoryFilters = ({ header, categories, filters, setFilters, resetFilters, setResetFilters }) => {
  const { t } = useTranslation();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 200,
    defaultExpanded: true,
  });

  useEffect(() => {
    if (resetFilters) {
      setFilters({ ...filters, categories: EMPTY_ARRAY });
      setResetFilters(false); 
    }
  }, [resetFilters]);

  const isChecked = (category) => filters.categories.includes(category);

  const handleCheckboxChange = (category) => {
    let updatedCategories;
    if (isChecked(category)) {
      updatedCategories = filters.categories.filter(
        (itemCategory) => itemCategory != category
      );
    } else {
      updatedCategories = [...filters.categories, category];
    }
    setFilters({ ...filters, categories: updatedCategories });
  };

  return (
    <>
      <div className="flex items-center gap-2">
        <h4 className="flex-1 text-lg font-semibold text-contrast-800">
          {t(header)}
        </h4>
        <span {...getToggleProps()}>
          <RenderSVG
            Svg={ChevronDown}
            alt="Expand Icon"
            className={classNames({ "rotate-180": isExpanded })}
          />
        </span>
      </div>
      <div {...getCollapseProps()}>
        <div className="pt-6">
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(categories).map(([category, count]) => {
              const checked = isChecked(category);
              return (
                <div className="col-span-2" key={category}>
                  <div className="checbox-tab">
                    <label className="cursor-pointer">
                      <input
                        type="checkbox"
                        className="peer hidden"
                        id={category}
                        value={category}
                        onChange={() => handleCheckboxChange(category)}
                        checked={checked}
                      />
                      <div
                        className={classNames(
                          "flex items-center gap-2 border-2 p-4 rounded-lg",
                          {
                            "bg-primary-100 border-primary-600 text-primary-900":
                              checked,
                            "border-contrast-200 text-contrast-900": !checked,
                          }
                        )}
                      >
                        <div
                          className={classNames(
                            "w-4 h-4 rounded grid place-content-center border",
                            {
                              "bg-primary-600 border-primary-600": checked,
                              "border-contrast-300": !checked,
                            }
                          )}
                        >
                          <RenderSVG
                            Svg={Checkbox}
                            width="9"
                            height="9"
                            alt="Checkbox Icon"
                          />
                        </div>
                        <div className="w-full flex justify-between text-sm font-medium">
                          <span className="flex flex-center">
                            {category || "Default"}
                          </span>
                          <span className="text-contrast-500">{count}</span>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryFilters;
