import { useFormikContext } from "formik";
import { RenderSVG, ToggleDirection } from "../../../assets/icons";
import { FlightClass, LocationPicker, TravelersCount, DateSelector } from "../../molecules";
import {
  DEFAULT_VALUES,
  LOCATION_TYPE,
  DATE_TYPE_TITLES,
  TRIP_TYPES,
  SEARCH_SECTION,
} from "../../../constants";
import config from "../../../config.json";

const { ZERO } = DEFAULT_VALUES;
const { FROM, TO } = LOCATION_TYPE;
const { DEPARTURE } = DATE_TYPE_TITLES;
const { ONE_WAY } = TRIP_TYPES;
const { FLIGHT } = SEARCH_SECTION;

const OneWaySearch = ({ handleLocationSwap, handleLocationChange, footer }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <div>
      <div className="grid grid-cols-12 gap-3 2xl:gap-4">
        <div className="col-span-12 md:col-span-7 lg:col-span-5 grid grid-cols-2 gap-4 relative">
          <span
            className="absolute z-10 left-1/2 -translate-x-1/2 top-11 sm:top-6 sm:-translate-y-1/2 cursor-pointer rotate-90 sm:rotate-0"
            onClick={() => handleLocationSwap(ZERO, values, setFieldValue)}
          >
            <RenderSVG
              Svg={ToggleDirection}
              width="32"
              height="32"
              alt="Toggle Direction"
              stroke={config.primaryColor}
            />
          </span>
          <LocationPicker
            name={`journey[0].originCode`}
            handleLocationChange={handleLocationChange}
            type={FROM}
            source={FLIGHT}
          />
          <LocationPicker
            name={`journey[0].destCode`}
            handleLocationChange={handleLocationChange}
            type={TO}
            source={FLIGHT}
          />
        </div>
        <div className="col-span-12 md:col-span-5 lg:col-span-2">
          <div className="flex divide-x divide-gray-300 w-full">
            <DateSelector
              showPrices={false}
              placeholder={DEPARTURE}
              name={`journey[0].departureDate`}
              tripType={ONE_WAY}
              showCalendarIcon={true}
              inputClasses="!rounded-md"
              minDate={new Date()}
              height="53px"
              fareCalendarPrices={{
                origin: values.journey[ZERO].originCode.iata,
                destination: values.journey[ZERO].destCode.iata,
              }}
            />
          </div>
        </div>
        <FlightClass />
        <div className="lg:col-span-3 col-span-12 md:col-span-6 flex flex-col md:flex-row gap-3 2xl:gap-4">
          <TravelersCount />
          {footer}
        </div>
      </div>
    </div>
  );
};

export default OneWaySearch;
