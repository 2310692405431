import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { EmailIcon, LocationMarkerLight, RenderSVG } from "../../assets/icons";
import { REGEX } from "../../constants";
import { submitQuery } from "./index";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import Asterisk from "../../components/atoms/Asterisk";
import ErrorMessage from "../../components/atoms/ErrorMessage";

const { PHONE_NUMBER } = REGEX;
const { SUBMIT_QUERY } = SPINNER_NAMES;
const SUCCESS_MSG = "successfully run";
const initialValues = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

const renderFieldError = (errors, touched, name) =>
  errors[name] && touched[name] && <ErrorMessage errorMessage={errors[name]} />;

const ContactUs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);

  const handleFormSubmission = (values, { resetForm }) => {
    dispatch(submitQuery(values)).then((res) => {
      if (res.payload === SUCCESS_MSG) {
        toast.success(t("contactUs.errorMessages.successResponse"));
        resetForm();
      } else toast.error(t("contactUs.errorMessages.errorResponse"));
    });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("contactUs.errorMessages.nameRequired")),
    email: Yup.string()
      .required(t("contactUs.errorMessages.emailRequired"))
      .email(t("contactUs.errorMessages.invalidEmail")),
    phone: Yup.string()
      .required(t("contactUs.errorMessages.phoneNumberRequired"))
      .matches(PHONE_NUMBER, t("contactUs.errorMessages.invalidPhoneNumber")),
    message: Yup.string().required(t("contactUs.errorMessages.message")),
  });

  return (
    <main className="py-16">
      <div className="container px-8 mx-auto">
        <div className="mb-12">
          <h2 className="text-3xl text-gray-900 mb-3 font-bold">
            {t("contactUs.heading")}
          </h2>
          <p className="text-base text-gray-500 mb-8">
            {t("contactUs.subHeading")}
          </p>
        </div>

        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-12 lg:col-span-6">
            <div className="flex flex-col gap-4">
              <div className="bg-gray-100 flex gap-5 p-6 rounded-lg">
                <div className="flex-shrink-0 bg-primary-600 w-10 h-10 rounded-full flex items-center justify-center">
                  <RenderSVG Svg={LocationMarkerLight} />
                </div>
                <div className="flex-1">
                  <h4 className="text-lg text-gray-900 font-bold mb-1">
                    Bancstac
                  </h4>
                  <p className="text-gray-500 text-sm">Colombo, Sri Lanka</p>
                </div>
              </div>

              <div className="bg-gray-100 flex items-center gap-5 p-6 rounded-lg">
                <div className="flex-shrink-0 bg-primary-600 w-10 h-10 rounded-full flex items-center justify-center">
                  <RenderSVG Svg={EmailIcon} />
                </div>
                <div className="flex-1">
                  <h4 className="text-lg text-gray-900 font-bold mb-0">
                    {t("contactUs.emailUs")}
                  </h4>
                  <a
                    href="mailto:support@bancstac.net"
                    className="text-primary-600 hover:opacity-75 text-sm flex"
                  >
                    support@bancstac.net
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-6">
            {
              <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmission}
                validationSchema={validationSchema}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div className="bg-gray-50 border border-primary-600/30 ring-8 ring-primary-600/10 rounded-xl px-6 py-8 shadow-lg">
                      <div className="grid grid-cols-12 gap-6 mb-4">
                        <div className="form-goup col-span-12">
                          <label className="text-sm text-gray-800 mb-1 font-semibold">
                            {t("contactUs.form.name")}
                          </label>
                          <Asterisk />
                          <Field
                            type="text"
                            name="name"
                            className="form-control rounded-lg w-full border-gray-300 text-base py-3 px-4 focus:border-primary-600 focus:ring-primary-600"
                            placeholder={t("contactUs.placeholder.name")}
                          />
                          {renderFieldError(errors, touched, "name")}
                        </div>
                        <div className="form-goup col-span-6">
                          <label className="text-sm text-gray-800 mb-1 font-semibold">
                            {t("contactUs.form.email")}
                          </label>
                          <Asterisk />
                          <Field
                            type="email"
                            name="email"
                            className="form-control rounded-lg w-full border-gray-300 text-base py-3 px-4 focus:border-primary-600 focus:ring-primary-600"
                            placeholder={t("contactUs.placeholder.email")}
                          />
                          {renderFieldError(errors, touched, "email")}
                        </div>
                        <div className="form-goup col-span-6">
                          <label className="text-sm text-gray-800 mb-1 font-semibold">
                            {t("contactUs.form.phoneNumber")}
                          </label>
                          <Asterisk />
                          <Field
                            type="text"
                            name="phone"
                            className="form-control rounded-lg w-full border-gray-300 text-base py-3 px-4 focus:border-primary-600 focus:ring-primary-600"
                            placeholder={t("contactUs.placeholder.phoneNumber")}
                          />
                          {renderFieldError(errors, touched, "phone")}
                        </div>

                        <div className="form-goup col-span-12">
                          <label className="text-sm text-gray-800 mb-1 font-semibold">
                            {t("contactUs.form.message")}
                          </label>
                          <Asterisk />
                          <Field
                            as="textarea"
                            name="message"
                            rows="5"
                            className="form-control rounded-lg w-full border-gray-300 text-base py-3 px-4 focus:border-primary-600 focus:ring-primary-600"
                            placeholder={t("contactUs.placeholder.message")}
                          />
                          {renderFieldError(errors, touched, "message")}
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn py-3 px-4 rounded-md bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium w-full"
                        disabled={isSpinnerActive}
                      >
                        <Spinner
                          name={SUBMIT_QUERY}
                          setIsSpinnerActive={setIsSpinnerActive}
                        >
                          {t("contactUs.form.submit")}
                        </Spinner>
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            }
          </div>
        </div>
      </div>
    </main>
  );
};

export default ContactUs;
