import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get } from "lodash";
import {
  getAirlineIconUrlByCarrierCode,
  priceFormatter,
  getCityNameByIata,
} from "../../helper";
import { selectFlightInfo } from "./index";
import Spinner, {
  SPINNER_NAMES,
  addSpinner,
} from "../../components/organisms/Spinner";
import { selectFlightTokens } from "../../components/organisms/Search";
import { getFormattedPriceRequest } from "../../helper/getFormattedPriceRequest";
import { useCollapse } from "react-collapsed";
import { ChevronDown, ChevronUp, RenderSVG } from "../../assets/icons";
import { selectCountryInfo } from "../Profile";
import Tabs from "../../components/organisms/Tabs/Tabs";
import MiniFareRulesTab from "./MiniFareRulesTab";
import {
  FLIGHTS_RESULT_TYPE,
  FLIGHT_ISOLATED_SECTIONS,
  DEFAULT_VALUES,
  MINI_FARE_RULES,
  CURRENCY_SYMBOLS,
} from "../../constants";

const { OUTBOUND_RESULT } = FLIGHTS_RESULT_TYPE;
const { ZERO, ONE, TWO, THREE, FOUR, EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT } =
  DEFAULT_VALUES;
const { PRICE, PENDING } = SPINNER_NAMES;
const { CANCELLATION: CANCELLATION_RULE, REISSUE } = MINI_FARE_RULES;
const {INR}= CURRENCY_SYMBOLS;
const FLIGHT_DETAILS = "FLIGHT DETAILS";
const FARE_SUMMARY = "FARE SUMMARY";
const CANCELLATION = "CANCELLATION INFO";
const DATE_CHANGE = "DATE CHANGE";

const DEPARTURE = "flightResults.roundTripCard.departure";
const RETURN = "flightResults.roundTripCard.return";

const getTotalPrice = (inboundFlightPrice, outboundFlightPrice) =>
  inboundFlightPrice + outboundFlightPrice;

const calculateTotalPrices = (inboundPrice, outboundPrice) => {
  const sumPrices = (each) => inboundPrice[each] + outboundPrice[each];

  const total = ["totalPrice", "basePrice", "taxPrice"].reduce(
    (acc, each) => ({ ...acc, [each]: sumPrices(each) }),
    {}
  );

  return total;
};

const FlightSegment = ({ segment, direction, selectedFlightInfo }) => {
  const departure = segment.departure;
  const arrival = segment.arrival;

  return (
    <div key={segment.flightNumber}>
      <div className="text-12 text-primary-900 font-bold border-b-2 mb-2  m-2">
        {getCityNameByIata(departure.iataCode)}-
        {getCityNameByIata(arrival.iataCode)}
      </div>
      <div className="flex item-center text-primary-900 items-center gap-2 p-1">
        <div className="thumb flex-shrink-0 flex-col ml-2">
          <img
            src={getAirlineIconUrlByCarrierCode(segment.carrierCode)}
            className="rounded border border-white"
            width="48"
            alt="carrierCode"
          />
        </div>
        <div className="text-10 flex-col font-bold">{segment.carrierName}</div>
        <div className="flex-col justify-center">
          <span>
            {segment.carrierCode} | {segment.flightNumber}
          </span>
        </div>
      </div>
      <div className="flex item-center gap-2 justify-between ml-2 mr-2">
        <div className="flex-col">
          <div>{departure.time}</div>
          {departure.date}
          <div></div>
        </div>
        <div className="flex-col">{segment.duration}</div>
        <div className="flex-col">
          <div>{arrival.time}</div>
          <div>{arrival.date}</div>
        </div>
      </div>
      <div className="font-bold text-sm ml-2">BAGGAGE</div>
      <div className="ml-2">
        {selectedFlightInfo?.[`${direction}bound`]?.flight?.baggage?.weight}
      </div>
    </div>
  );
};

const FlightDetails = ({ selectedFlightInfo }) => {
  const renderSegments = (segments = [], direction) => (
    <div className="border-2 border-primary-300 rounded-xl text-primary-900 m-2 max-h-max">
      {segments.map((segment) => (
        <FlightSegment
          key={segment.flightNumber}
          segment={segment}
          direction={direction}
          selectedFlightInfo={selectedFlightInfo}
        />
      ))}
    </div>
  );

  const { outbound, inbound } = selectedFlightInfo;
  const outboundSegments = get(outbound, "flight.itineraries[0].segments", []);
  const inboundSegments = get(inbound, "flight.itineraries[0].segments", []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 p-2 h-35 border-1 border-primary-300 rounded-xl w-full">
      {renderSegments(outboundSegments, "out")}
      {renderSegments(inboundSegments, "in")}
    </div>
  );
};

const FareSummary = ({ selectedFlightInfo, currency }) => {
  const { outbound, inbound } = selectedFlightInfo;
  const inboundPrice = get(inbound, "flight.price", EMPTY_OBJECT);
  const outboundPrice = get(outbound, "flight.price", EMPTY_OBJECT);
  const priceBreakdown = calculateTotalPrices(inboundPrice, outboundPrice);
  const { totalPrice, basePrice, taxPrice } = priceBreakdown;


  return (
    <div className="border-2 border-primary-300 rounded-xl text-primary-900 m-2 p-3 gap-y-3">
      <div className="text-14 grid grid-cols-2 justify-between">
        <div className="font-bold ">Total Price</div>
        <div>
          {currency} {priceFormatter(parseFloat(totalPrice))}
        </div>
      </div>
      <div className="text-14 grid grid-cols-2 justify-between">
        <div className="text-14 font-bold">Base Price</div>
        <div>
          {currency} {priceFormatter(parseFloat(basePrice))}
        </div>
      </div>
      <div className="text-14 grid grid-cols-2 justify-between">
        <div className="text-14 font-bold">Tax Price</div>
        <div>
          {currency} {priceFormatter(parseFloat(taxPrice))}
        </div>
      </div>
    </div>
  );
};

const JourneySummary = ({ flightType, currency }) => {
  const { t } = useTranslation();
  const selectedFlightInfo = useSelector(selectFlightInfo);
  const {
    flight: {
      itineraries: [{ segments }],
    },
  } = selectedFlightInfo[flightType];
  const departure = segments[ZERO].departure;
  const finalArrival = segments.at(-1).arrival;
  const price = get(selectedFlightInfo, `${flightType}.flight.price`);

  return (
    <div className="flex justify-between">
      <div className="col-span-12 sm:col-span-6 md:col-span-4 flex items-center gap-2 justify-between">
        <div className="thumb flex-shrink-0">
          <img
            src={getAirlineIconUrlByCarrierCode(segments[ZERO].carrierCode)}
            className="rounded"
            width="48"
            alt="carrierCode"
          />
        </div>
        <div className="grid grid-cols-1 gap-5">
          <div className="grid grid-cols">
            <span className="text-xs text-white flex mb-1">
              {t(flightType === OUTBOUND_RESULT ? DEPARTURE : RETURN)} •{" "}
              {getCityNameByIata(departure.iataCode)}-
              {getCityNameByIata(finalArrival.iataCode)}
            </span>
            <h6 className="font-semibold text-white text-lg">
              {departure.time} - {finalArrival.time}
            </h6>
          </div>
        </div>
      </div>
      <div className="grid grid-cols">
        <h6 className="font-semibold text-white text-lg text-center">
          {currency} {priceFormatter(price.totalPrice)}
        </h6>
      </div>
    </div>
  );
};

const ToggleMiniFareRulesDetails = ({
  flight,
  type,
  defaultExpanded = false,
}) => {
  const journeyPoints = get(
    flight,
    "miniFareRules[0][0].JourneyPoints",
    EMPTY_STRING
  );
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    duration: 500,
    defaultExpanded,
  });

  return (
    <div>
      <div className="text-sm flex flex-row justify-between font-semibold w-full text-center p-4 border-b-4 truncate border-contrast-300 text-contrast-600">
        <label>{journeyPoints}</label>
        <div {...getToggleProps()}>
          <div className="flex justify-end text-contrast-600 text-sm cursor-pointer d-block lg:d-none mr-[15px] mb-2 font-semibold transition-all duration-500">
            <RenderSVG
              Svg={isExpanded ? ChevronUp : ChevronDown}
              alt="chevron-up-down"
              height={25}
              width={25}
              className="text-contrast-600"
            />
          </div>
        </div>
      </div>
      <div {...getCollapseProps()} className="border-b-4 border-contrast-300">
        <MiniFareRulesTab flight={flight} type={type} />
      </div>
    </div>
  );
};

const MiniFareRulesSummary = ({ tripInfo, type }) => {
  const inboundData = tripInfo.inbound.flight;
  const outboundData = tripInfo.outbound.flight;

  return (
    <div>
      <ToggleMiniFareRulesDetails
        flight={outboundData}
        type={type}
        defaultExpanded={true}
      />
      <ToggleMiniFareRulesDetails flight={inboundData} type={type} />
    </div>
  );
};

const SelectedFlightsCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const selectedCountryInfo = useSelector(selectCountryInfo);
  const selectedFlightInfo = useSelector(selectFlightInfo);
  const flightTokens = useSelector(selectFlightTokens);
  const { getCollapseProps, isExpanded, getToggleProps } = useCollapse({
    duration: 200,
    defaultExpanded: false,
  });

  const [isSpinnerActive, setIsSpinnerActive] = useState(false);

  const handleBookNow = () => {
    const updatedData = [outbound.flight, inbound.flight];
    getFormattedPriceRequest(updatedData, flightTokens, navigate, dispatch);
    dispatch(addSpinner(PENDING)); // TBD: LOGIC
  };

  const { outbound, inbound } = selectedFlightInfo;
  const inboundFlightPrice = get(inbound, "flight.price.totalPrice", 0);
  const outboundFlightPrice = get(outbound, "flight.price.totalPrice", 0);

  const currency = get(selectedCountryInfo, "currency.symbol", INR);

  const tabs = [
    {
      id: ONE,
      default: true,
      title: FLIGHT_DETAILS,
      element: <FlightDetails selectedFlightInfo={selectedFlightInfo} />,
    },
    {
      id: TWO,
      element: (
        <FareSummary
          selectedFlightInfo={selectedFlightInfo}
          currency={currency}
        />
      ),
      title: FARE_SUMMARY,
    },
    {
      id: THREE,
      element: (
        <MiniFareRulesSummary
          tripInfo={selectedFlightInfo}
          type={CANCELLATION_RULE}
        />
      ),
      title: CANCELLATION,
    },
    {
      id: FOUR,
      title: DATE_CHANGE,
      element: (
        <MiniFareRulesSummary tripInfo={selectedFlightInfo} type={REISSUE} />
      ),
    },
  ];
  return (
    <div className="sticky bottom-2 z-10 w-[98%] max-w-7xl mx-auto bg-primary-900 rounded-2xl border-primary-900 border-2 overflow-hidden">
      <div className="grid grid-cols-12 gap-4 mb-2 p-6">
        {Object.values(FLIGHT_ISOLATED_SECTIONS).map((flightType) => (
          <div className="col-span-12 md:col-span-4">
            <JourneySummary
              key={flightType}
              flightType={flightType}
              currency={currency}
            />
          </div>
        ))}

        <div className="flex col-span-12 md:col-span-4 items-center gap-3 md:justify-center justify-between">
          <div className="text-xl font-bold text-white xl:flex sm:items-center">
            <div className="sm:text-center">
              {currency}
              {priceFormatter(
                parseFloat(
                  getTotalPrice(inboundFlightPrice, outboundFlightPrice)
                ).toFixed(TWO)
              )}
            </div>
          </div>
          <div className="grid-cols">
            <button
              onClick={handleBookNow}
              className="btn btn-primary w-full"
              disabled={isSpinnerActive}
            >
              <Spinner name={PRICE} setIsSpinnerActive={setIsSpinnerActive}>
                {t("flightResults.bookNow")}
              </Spinner>
            </button>
          </div>
          <div {...getToggleProps()}>
            <div className="justify-end grid-cols">
              <RenderSVG
                Svg={isExpanded ? ChevronDown : ChevronUp}
                alt="chevron-up-down"
                height={25}
                width={25}
                color="#FFFFFF"
              />
            </div>
          </div>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className="bg-white p-2 overflow-y-scroll max-h-80">
          <Tabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default SelectedFlightsCard;
