import { memo, useEffect, useState } from "react";
import { isEmpty, get } from "lodash";
import { useSelector } from "react-redux";
import SortingHeaders from "./SortingHeaders";
import NoFlightResult from "./NoFlightResult";
import OneWayCard from "./OneWayCard";
import { SwiperScript } from "../../utils";
import { DEFAULT_VALUES } from "../../constants";
import {
  selectSortedFlights,
  selectFilteredFlights,
} from "../../components/organisms/Search";
import { getRearrangedFlights } from "../../helper";

const { EMPTY_OBJECT } = DEFAULT_VALUES;

const RoundTripFlights = ({ selectedFlightId }) => {
  const sortedFlights = useSelector(selectSortedFlights);
  const filteredFlights = useSelector(selectFilteredFlights);
  const individualSortedFlights = get(sortedFlights, "isolated", EMPTY_OBJECT);
  const individualFilteredFlights = get(
    filteredFlights,
    "isolated",
    EMPTY_OBJECT
  );
  const [flights, setFlights] = useState(individualSortedFlights);

  useEffect(() => {
    SwiperScript();
  }, []);

  useEffect(() => {
    const applySortingLogic = () => {
      let updatedFlights;
      if (
        !isEmpty(individualSortedFlights?.outbound) &&
        !isEmpty(individualSortedFlights?.inbound)
      ) {
        const selectedInbound = selectedFlightId?.inbound;
        const selectedOutbound = selectedFlightId?.outbound;
        if (!selectedInbound && !selectedOutbound) {
          updatedFlights = individualSortedFlights && {
            ...individualSortedFlights,
          };
        } else if (!selectedInbound) {
          const rearrangedOutBoundResult = getRearrangedFlights(
            individualFilteredFlights.outbound,
            selectedOutbound
          );
          updatedFlights = {
            outbound: rearrangedOutBoundResult,
            inbound: individualSortedFlights.inbound,
          };
        } else if (!selectedOutbound) {
          const rearrangedInBoundResult = getRearrangedFlights(
            individualFilteredFlights.inbound,
            selectedInbound
          );
          updatedFlights = {
            outbound: individualSortedFlights.outbound,
            inbound: rearrangedInBoundResult,
          };
        } else {
          const rearrangedOutBoundResult = getRearrangedFlights(
            individualFilteredFlights.outbound,
            selectedOutbound
          );
          const rearrangedInBoundResult = getRearrangedFlights(
            individualFilteredFlights.inbound,
            selectedInbound
          );
          updatedFlights = {
            outbound: rearrangedOutBoundResult,
            inbound: rearrangedInBoundResult,
          };
        }
      }

      setFlights(updatedFlights);
    };

    applySortingLogic();
  }, [selectedFlightId, individualSortedFlights, individualFilteredFlights]);

  if (isEmpty(flights?.outbound) && isEmpty(flights?.inbound))
    return <NoFlightResult showPackages={false} />;

  return (
    <div>
      <div className="swiper flight-results-slider flex flex-row">
        <div className="swiper-wrapper flex justify-between gap-2">
          {Object.entries(flights).map(([flightType, flightsData]) => (
            <div
              className="swiper-slide md:flex-1 md:max-w-[50%]"
              key={flightType}
            >
              <div className="bg-contrast-50 rounded-t-xl border border-contrast-200 mr-1">
                <SortingHeaders type={flightType} />
                <div className="flex flex-col gap-2 max-h-screen overflow-y-scroll no-scrollbar pb-2 px-3 ">
                  {flightsData.map((flight) => (
                    <div key={flight.flightId}>
                      <OneWayCard
                        flight={flight}
                        currentFlightType={flightType}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(RoundTripFlights);
