import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const NavBarSkeleton = () => (
  <SkeletonTheme baseColor="#e5e7eb" highlightColor="#9ca3af">
    <div className="w-full">
      <div className="flex justify-between gap-10 flex-row-reverse">
        <div className="flex pl-10 flex-col-reverse">
          <Skeleton height={45} width={230} borderRadius="0.5rem" />
        </div>
        <div className="hidden sm:block md:block">
          <Skeleton width={60} height={45} borderRadius="0.5rem" />
        </div>
      </div>
    </div>
  </SkeletonTheme>
);

export default NavBarSkeleton;
