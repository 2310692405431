import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import { useCollapse } from "react-collapsed";
import { selectFlightInfo, selectSelectedTripType } from "./index";
import { selectFlightTokens } from "../../components/organisms/Search";
import { getFormattedPriceRequest } from "../../helper/getFormattedPriceRequest";
import { selectCountryInfo } from "../Profile";
import MultiCityCard from "./MultiCityCard";
import RoundTripCard from "./RoundTripCard";
import { DEFAULT_VALUES, CURRENCY_SYMBOLS, TRIP_TYPES, DEFAULT_CURRENCY_CODE } from "../../constants";

const { ZERO, EMPTY_OBJECT, EMPTY_ARRAY, EMPTY_STRING } = DEFAULT_VALUES;
const { MULTI_CITY } = TRIP_TYPES;
const { INR } = CURRENCY_SYMBOLS;

const PackageCard = ({ flight }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedFlight = useSelector(selectFlightInfo);
  const flightTokens = useSelector(selectFlightTokens);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const tripType = useSelector(selectSelectedTripType);

  const {
    getCollapseProps: getFlightDetailsCollapseProps,
    getToggleProps: getFlightDetailsToggleProps,
    isExpanded: isFlightsDetailsExpanded,
  } = useCollapse({
    duration: 200,
    defaultExpanded: false,
  });

  const { price, flightId, carbonEmissionByCabin } = flight || EMPTY_OBJECT;
  const totalPrice = get(price, "totalPrice", ZERO);

  const carbonEmissionsArray = Array.isArray(carbonEmissionByCabin)
    ? carbonEmissionByCabin
    : EMPTY_ARRAY;
  const hasHighCO2Emission = carbonEmissionsArray.some(
    (emission) => emission.quantity > maxQuantityOfCabinCO2EmissionInPound
  );

  const selectedFlightId = get(
    selectedFlight,
    `outbound.flight.flightId`,
    EMPTY_STRING
  );
  const isSelectedFlight = selectedFlightId === flightId;

  const currency = get(selectedCountryInfo, "currency.symbol", INR);

  const currencyCode = get(selectedCountryInfo, "currency.code", DEFAULT_CURRENCY_CODE);

  const handleSelectPackage = () =>
    getFormattedPriceRequest(flight, flightTokens, navigate, dispatch, false);

  const flightData = {
    getFlightDetailsCollapseProps,
    getFlightDetailsToggleProps,
    isFlightsDetailsExpanded,
    flight,
    totalPrice,
    hasHighCO2Emission,
    isSelectedFlight,
    currency,
    currencyCode,
    handleSelectPackage,
  };

  return (
    <>
      {tripType === MULTI_CITY ? (
        <MultiCityCard flightData={flightData} />
      ) : (
        <RoundTripCard flightData={flightData} />
      )}
    </>
  );
};

export default PackageCard;
