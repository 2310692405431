import classNames from "classnames";

const MAX_STAR_RATING = 5;

export const renderStars = (rating) => {
  const filledStars = Math.round(rating);
  return Array.from({ length: MAX_STAR_RATING }, (_, index) => (
    <span
      key={index}
      className={classNames("text-base text-contrast-300 ml-1", {
        "text-yellow-800": index < filledStars,
      })}
    >
      ★
    </span>
  ));
};
