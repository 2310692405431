import React, { memo, useRef } from "react";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useCollapse } from "react-collapsed";
import {
  getAirlineIconUrlByCarrierCode,
  getLayoverDuration,
  getCityNameByIata,
  calculateTotalDuration,
  getFormattedDuration,
  priceFormatter,
} from "../../helper";
import { Cloud, RenderSVG, Baggage } from "../../assets/icons";
import {
  selectFlightInfo,
  setCurrentFlightType,
  setSelectedFlightInfo,
} from "./index";
import { getFormattedPriceRequest } from "../../helper/getFormattedPriceRequest";
import { selectFlightTokens } from "../../components/organisms/Search";
import { selectSelectedTripType } from "./flightResults.selectors";
import FlightSearchResultCardTabs from "./FlightSearchResultCardTabs";
import { selectCountryInfo } from "../Profile";
import { SPINNER_NAMES, addSpinner } from "../../components/organisms/Spinner";
import { getStops } from "../../utils/flights.utils";
import {
  DEFAULT_VALUES,
  INDEX,
  TRIP_TYPES,
  CURRENCY_SYMBOLS,
  FLIGHT_SERVICE_TYPE,
} from "../../constants";

const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { ROUND_TRIP } = TRIP_TYPES;
const { LAST } = INDEX;
const { AMADEUS } = FLIGHT_SERVICE_TYPE;
const { PENDING } = SPINNER_NAMES;
const maxQuantityOfCabinCO2EmissionInPound = 500;

const isBoolean = (value) => typeof value === "boolean";

const OneWayCard = ({ flight, currentFlightType, isReissuanceFlight }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const cardRef = useRef(null);

  const {
    getToggleProps: getTogglePropsForFlightDetails,
    getCollapseProps: getCollapsePropsForFlightDetails,
    isExpanded: isDetailsExpanded,
  } = useCollapse({
    duration: 500,
    defaultExpanded: false,
  });

  const selectedFlightInfo = useSelector(selectFlightInfo);
  const flightTokens = useSelector(selectFlightTokens);
  const tripType = useSelector(selectSelectedTripType);
  const selectedCountryInfo = useSelector(selectCountryInfo);

  const currencySymbol = get(
    selectedCountryInfo,
    "currency.symbol",
    CURRENCY_SYMBOLS.INR
  );

  const {
    itineraries = [],
    price = {},
    baggage,
    carbonEmissionByCabin,
    isRefundable,
    isPanRequiredAtTicket,
    source,
    flightId,
  } = flight || {};

  const { totalPrice = 0 } = price;
  const { segments, duration } = itineraries[ZERO];
  const {
    noOfAvailableSeats = 0,
    flightNumber = "",
    aircraftCode = "",
    carrierCode = "",
    departure = {},
    carrierName = "",
  } = segments[ZERO];
  const finalDestination = segments.at(LAST);
  const { arrival = {} } = finalDestination;
  const { weight, noOfBags } = baggage;
  const totalNoOfStops = segments.length - ONE;
  const layoverDuration = getLayoverDuration(segments);

  const getNoOfSeats = () => {
    if (noOfAvailableSeats)
      return `${noOfAvailableSeats} ${
        noOfAvailableSeats > ONE
          ? t("flightResults.seatsLeft")
          : t("flightResults.seatLeft")
      }`;
  };

  const carbonEmissionsArray = Array.isArray(carbonEmissionByCabin)
    ? carbonEmissionByCabin
    : [];
  const hasHighCO2Emission = carbonEmissionsArray.some(
    (emission) => emission.quantity > maxQuantityOfCabinCO2EmissionInPound
  );

  const selected =
    get(
      selectedFlightInfo,
      `${currentFlightType}.flight.flightId`,
      EMPTY_STRING
    ) === flightId;
  const handleOnCardSelect = () => {
    if (tripType !== ROUND_TRIP) return;
    dispatch(setCurrentFlightType(currentFlightType));
    dispatch(
      setSelectedFlightInfo({
        ...selectedFlightInfo,
        [currentFlightType]: {
          flight,
          selectedCountryInfo,
        },
      })
    );
  };

  const handleFlightSelect = () => {
    dispatch(addSpinner(PENDING));
    getFormattedPriceRequest(
      flight,
      flightTokens,
      navigate,
      dispatch,
      isReissuanceFlight
    );
  };

  const scrollIntoView = () => {
    if (cardRef.current && !isDetailsExpanded) {
      cardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <div
      ref={cardRef}
      className={classNames(
        "border rounded-lg transition duration-1000 ease-in-out",
        {
          "border-primary-600 bg-primary-100": selected,
          "border-contrast-200 bg-white hover:border-contrast-400": !selected,
          "cursor-pointer": tripType === ROUND_TRIP,
        }
      )}
      onClick={handleOnCardSelect}
    >
      <div className="grid grid-cols-12 gap-4 p-4">
        <div
          className={classNames("col-span-12 3xl:col-span-5", {
            "lg:col-span-4": tripType !== ROUND_TRIP,
            "xl:col-span-4 2xl:col-span-4": tripType === ROUND_TRIP,
          })}
        >
          <div className="flex items-center gap-4 mb-4">
            <div className="logo">
              <img
                src={getAirlineIconUrlByCarrierCode(carrierCode)}
                className="rounded border-contrast-300 w-12 h-12"
                alt={`${carrierCode}_logo`}
              />
            </div>
            <div className="flex-1">
              <h5
                className={classNames(
                  "text-lg font-semibold text-contrast-900 mb-1",
                  {
                    "text-sm": tripType === ROUND_TRIP,
                  }
                )}
              >
                {departure.time}
                &nbsp; -&nbsp;
                {arrival.time}
              </h5>
              <p className="text-xs text-contrast-600">
                {carrierName || carrierCode}, {flightNumber}{" "}
                {aircraftCode ? `| ${aircraftCode}` : ""}
              </p>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "col-span-12 3xl:col-span-7 grid grid-cols-12 gap-4",
            {
              "lg:col-span-8": tripType != ROUND_TRIP,
              "md:gap-2": tripType === ROUND_TRIP,
            }
          )}
        >
          <div
            className={classNames(
              "col-span-12 sm:col-span-5 lg:col-span-6 flex gap-4",
              {
                "md:flex-col lg:flex-row md:col-span-6":
                  tripType === ROUND_TRIP,
              }
            )}
          >
            <div className="flex-1">
              <h5 className="text-lg text-contrast-900 font-semibold mb-1">
                {source === AMADEUS
                  ? getFormattedDuration(duration)
                  : calculateTotalDuration(layoverDuration, duration)}
              </h5>
              <p className="text-xs text-contrast-600">
                {getCityNameByIata(departure.iataCode)} - &nbsp;
                {getCityNameByIata(arrival.iataCode)}
              </p>
            </div>
            <div
              className={classNames(
                "md:text-lg flex-1 text-end sm:text-start",
                {
                  "md:text-sm": tripType === ROUND_TRIP,
                }
              )}
            >
              {!totalNoOfStops ? (
                <h5 className="text-contrast-900 font-semibold mb-1">
                  {t("flightResults.stopTypes.nonStop")}
                </h5>
              ) : (
                <div>
                  <h5 className="text-contrast-900 font-semibold mb-1">
                    {totalNoOfStops} {t("flightResults.stopTypes.stop")}
                  </h5>
                  <div className="text-xs text-contrast-600">
                    {getStops(segments)}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              "col-span-12 sm:col-span-7 lg:col-span-6 flex items-start justify-end gap-4 flex-wrap xs:flex-nowrap",
              {
                "md:flex-col lg:flex-row md:col-span-6 md:items-end":
                  tripType === ROUND_TRIP,
              }
            )}
          >
            <div className="sm:text-end pr-2 xs:pr-4 mr-auto sm:mr-0">
              <h5 className="text-lg text-center text-primary-600 font-semibold mb-1">
                {currencySymbol}
                &nbsp;
                {priceFormatter(parseFloat(totalPrice))}
              </h5>
              <div className="text-xs font-semibold whitespace-nowrap text-red-500">
                {getNoOfSeats()}
              </div>
            </div>
            <div className="flex flex-col items-center gap-1">
              <div className="flex gap-1">
                {tripType === ROUND_TRIP ? (
                  <input
                    type="radio"
                    className="my-auto scale-150 cursor-pointer mb-1"
                    checked={selected}
                    readOnly
                  />
                ) : (
                  <button
                    className={classNames(
                      "py-2 px-4 flex items-center gap-2 rounded-md hover:bg-primary-300 active:bg-primary-200 border-none shadow-sm text-sm text-primary-700 font-medium whitespace-nowrap",
                      {
                        "bg-primary-200": selected,
                        "bg-primary-100 ": !selected,
                      }
                    )}
                    onClick={handleFlightSelect}
                  >
                    {isReissuanceFlight
                      ? t("flightResults.reissueFlight")
                      : t("flightResults.selectFlight")}
                  </button>
                )}
              </div>
              <button
                onClick={(e) => {
                  scrollIntoView();
                  e.stopPropagation();
                }}
              >
                <div
                  className="flex justify-end text-primary-600 text-xs whitespace-nowrap cursor-pointer d-block lg:d-none mb-2 font-semibold"
                  {...getTogglePropsForFlightDetails()}
                >
                  {isDetailsExpanded
                    ? t("flightResults.hideDetails")
                    : t("flightResults.viewDetails")}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex mb-2 px-4">
        <div className="flex flex-1 flex-wrap gap-2">
          {hasHighCO2Emission && (
            <div className="rounded-2xl py-0.5 px-2 bg-green-100 text-green-900 text-xs font-medium flex items-center gap-[6px]">
              <RenderSVG Svg={Cloud} width="16" height="16" alt="cloud-icon" />
              <span>{t("flightResults.lowCo2")}</span>
            </div>
          )}
          {(weight || noOfBags) && (
            <div className="text-xs font-semibold whitespace-nowrap bg-gray-200 text-primary-900 rounded-2xl py-0.5 px-2 flex items-center">
              <RenderSVG Svg={Baggage} width="16" height="16" alt="baggage" />
              <span className="ml-2">
                {t("flightResults.baggageAllowed")}: {weight || noOfBags}
              </span>
            </div>
          )}
          {isBoolean(isRefundable) && (
            <div className="text-xs font-medium bg-[#FFEDD5] text-[#7C2D12] rounded-2xl py-0.5 px-2 flex items-center">
              {isRefundable
                ? t("flightResults.refundable")
                : t("flightResults.nonRefundable")}
            </div>
          )}
          {isPanRequiredAtTicket && (
            <div className="text-xs font-medium bg-gray-200 text-black rounded-2xl py-0.5 px-2 flex items-center">
              {t("flightResults.panRequired")}
            </div>
          )}
        </div>
      </div>
      {isDetailsExpanded && (
        <div {...getCollapsePropsForFlightDetails()}>
          <div className="bg-gray-100 rounded-lg border-t-2">
            <FlightSearchResultCardTabs flight={flight} />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(OneWayCard);
