const getProcessRefundRequestMapping = (bookingInfo) => {
  const { pnr } = bookingInfo;
  const refundRequest = {
    pnr,
    bookingId: "",
    isPartialRefund: "",
    refundableAmount: "",
    remark: "",
  };

  return [refundRequest];
};

export default getProcessRefundRequestMapping;
