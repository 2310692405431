import React, { useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scatter } from "react-chartjs-2";
import { get, isEmpty } from "lodash";
import classNames from "classnames";
import memoizeOne from "memoize-one";
import {
  priceFormatter,
  getAirlineIconUrlByCarrierCode,
  getFormattedDuration,
  getFromLocalStorage,
} from "../../helper";
import {
  CACHE_KEYS,
  CURRENCY_SYMBOLS,
  DEFAULT_VALUES,
  TRIP_TYPES,
  TIME_PERIODS,
  FLIGHTS_RESULT_TYPE,
} from "../../constants";
import { selectSearchFilters } from "../../components/organisms/Search";
import {
  RenderSVG,
  ArrowLeft,
  ArrowRoundTrip,
  ChevronUp,
  ChevronDown,
} from "../../assets/icons";
import config from "../../config.json";
import { selectFilteredFlights } from "../../components/organisms/Search/search.selectors";
import { actions as flightActions } from "./flightResults.reducer";
import { actions as searchActions } from "../../components/organisms/Search/search.reducer";
import AVAIALABLE_AIRLINE_LOGOS from "../../assets/json/airlineLogo.json";
import { useTranslation } from "react-i18next";
import { selectCountryInfo } from "../Profile/profile.selector";
import { formatPriceWithCommas } from "../../helper/priceFormatter";

const { setSelectedFlightId, setSelectedFlightInfo } = flightActions;

const { ZERO, EMPTY_STRING, EMPTY_ARRAY } = DEFAULT_VALUES;
const { ROUND_TRIP } = TRIP_TYPES;
const { USER_COUNTRY_INFO } = CACHE_KEYS;
const { PACKAGE_RESULT } = FLIGHTS_RESULT_TYPE;
const { setActiveSortOrder } = searchActions;
const { INR, LKR } = CURRENCY_SYMBOLS;

const DEFAULT_CAREER_CODE = "default";
const CHART = "chart";

const getImage = (carrierCode) => {
  const logoAvailable = AVAIALABLE_AIRLINE_LOGOS[carrierCode];
  const image = new Image(25, 25);
  image.src = memoizeGetAirlineIconUrlByCarrierCode(
    logoAvailable ? carrierCode : DEFAULT_CAREER_CODE
  );
  return image;
};

const memoizeGetAirlineIconUrlByCarrierCode = memoizeOne(
  getAirlineIconUrlByCarrierCode
);

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    const style = {
      background: "rgba(0, 0, 0, 1)",
      borderRadius: "5px",
      color: "white",
      opacity: 1,
      pointerEvents: "auto",
      position: "absolute",
      transform: "translate(-50%, 0)",
      transition: "all .07s ease-in-out",
      marginTop: "25px",
    };
    Object.entries(style).forEach(
      ([key, value]) => (tooltipEl.style[key] = [value])
    );

    const table = document.createElement("table");
    table.style.width = "100%";
    table.style.borderCollapse = "collapse";

    const arrow = document.createElement("div");

    const arrowStyles = ` position: absolute;
                           content: '';
                           border-width: 8px;
                           border-style: solid;
                           border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
                           top: -15px;
                           left: 50%;
                           transform: translateX(-50%);`;

    arrow.style.cssText = arrowStyles;

    tooltipEl.appendChild(arrow);
    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};
let setElement;

const FlightsChart = ({ showPackages, tripType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const searchFilters = useSelector(selectSearchFilters);
  const filteredFlights = useSelector(selectFilteredFlights);

  const [isGraphVisible, setGraphVisibility] = useState(false);
  const selectedCountryInfo = useSelector(selectCountryInfo);
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);

  const isRoundTrip = tripType === ROUND_TRIP;
  const { journeys } = searchFilters;
  const { originCode = {}, destCode = {} } = journeys[ZERO];

  const originIATACode = originCode.iata || "";
  const originCity = originCode.cityName || "";
  const destIATACode = destCode.iata || "";
  const destCity = destCode.cityName || "";

  const getTimeArray = (departureTimes) => {
    const fullDay = [];
    for (let hour = 0; hour <= 24; hour++) {
      const formattedHour = hour.toString().padStart(2, "0");
      fullDay.push(`${formattedHour}:00`);
    }

    const uniqueTimes = [...new Set(departureTimes)];
    const combinedArray = [...new Set([...fullDay, ...uniqueTimes])];

    return combinedArray.sort((a, b) => {
      const [aHour, aMinute] = a.split(":").map(Number);
      const [bHour, bMinute] = b.split(":").map(Number);

      if (aHour !== bHour) return aHour - bHour;
      else return aMinute - bMinute;
    });
  };

  const userCountryInformation = getFromLocalStorage(USER_COUNTRY_INFO);
  const externalTooltipHandler = (context) => {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    const tooltipFadeOutDuration = 5000;

    if (tooltip.opacity === 0) setTooltipFadeOutTimeout();

    tooltipEl.addEventListener("mouseenter", () => {
      clearTimeout(tooltipEl.fadeOutTimeoutId);
    });

    tooltipEl.addEventListener("mouseleave", () => {
      setTooltipFadeOutTimeout();
    });

    function setTooltipFadeOutTimeout() {
      tooltipEl.fadeOutTimeoutId = setTimeout(() => {
        tooltipEl.style.opacity = 0;
      }, tooltipFadeOutDuration);
    }

    if (tooltip.body) {
      const hoveredFlight = tooltip.dataPoints[0].raw;
      const {
        flightId,
        carrierName,
        carrierCode,
        y: price,
        duration,
        x: departureTime,
        layoverAirportDetails,
      } = hoveredFlight;

      const tableRoot = tooltipEl.querySelector("table");
      while (tableRoot?.firstChild && tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      const tableHead = document.createElement("thead");

      let tr = document.createElement("tr");
      const th = document.createElement("th");
      const headerContext = document.createElement("div");

      const image = document.createElement("img");
      const imageURL = getAirlineIconUrlByCarrierCode(
        AVAIALABLE_AIRLINE_LOGOS[carrierCode]
          ? carrierCode
          : DEFAULT_CAREER_CODE
      );
      image.src = imageURL;
      image.style.height = "20px";
      image.style.width = "20px";
      image.style.margin = "5px 0px";

      headerContext.style.display = "flex";

      th.colSpan = 2;
      headerContext.appendChild(image);

      const headerText = document.createElement("span");
      headerText.style.padding = "0px 0px 0px 6px";
      headerText.textContent = carrierName;
      headerContext.appendChild(headerText);
      th.appendChild(headerContext);
      tr.appendChild(th);
      tableHead.appendChild(tr);

      let tableBody = document.createElement("tbody");
      tableBody.style.margin = "5px";

      const row1 = createRow(t("flightChart.departs"), departureTime);
      const row2 = createRow(t("flightChart.duration"), duration);
      const row3 = createRow(
        t("flightChart.price"),
        `${currencySymbol} ${
          currencySymbol === INR || currencySymbol === LKR
            ? formatPriceWithCommas(price)
            : price
        }`
      );
      const row4 = createRow(
        t("flightChart.layover"),
        layoverAirportDetails.size
      );

      tableBody.appendChild(row1);
      tableBody.appendChild(row2);
      tableBody.appendChild(row3);
      tableBody.appendChild(row4);

      if (layoverAirportDetails.size > 0) {
        const layoverDetailsRow = document.createElement("tr");
        const layoverDetailsCell = document.createElement("td");
        layoverDetailsCell.colSpan = 2;
        layoverDetailsCell.style.borderRadius = "5px";
        layoverDetailsCell.style.backgroundColor = "#888a89";
        layoverDetailsCell.style.fontSize = "12px";
        layoverDetailsCell.style.textAlign = "left";
        layoverDetailsCell.style.padding = "5px";
        layoverDetailsCell.style.margin = "5px";
        layoverDetailsCell.textContent = layoverAirportDetails
          .values()
          .next().value;
        layoverDetailsRow.appendChild(layoverDetailsCell);
        tableBody.appendChild(layoverDetailsRow);
      }

      const buttonRow = document.createElement("tr");

      const buttonCell = document.createElement("td");
      buttonCell.colSpan = 2;
      buttonCell.paddingLeft = "10px !important";
      buttonCell.paddingRight = "10px !important";

      const button = document.createElement("button");
      button.textContent = t("flightResults.selectFlight");
      button.type = "submit";
      button.id = flightId;
      button.style.alignItems = "center";
      button.style.width = "100%";
      button.style.backgroundColor = "#4F46E5";
      button.style.borderRadius = "5px";
      button.style.margin = "10px 0px 10px 0px";
      button.style.cursor = "pointer";
      button.style.height = "35px";

      button.addEventListener("click", () => {
        handleOnCLick(setElement);
      });

      buttonCell.appendChild(button);
      buttonRow.appendChild(buttonCell);

      tableBody.appendChild(buttonRow);

      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    function createRow(label, value) {
      const row = document.createElement("tr");

      const labelCell = document.createElement("td");
      labelCell.style.margin = "5px 0px 5px 0px";
      labelCell.style.paddingLeft = "10px";
      labelCell.style.textAlign = "left";
      labelCell.style.fontSize = "12px";
      labelCell.style.whiteSpace = "nowrap";
      labelCell.textContent = label;

      const dataCell = document.createElement("td");
      dataCell.style.margin = "5px 0px 5px 0px";
      dataCell.style.paddingRight = "10px";
      dataCell.style.textAlign = "right";
      dataCell.style.fontSize = "12px";
      dataCell.style.whiteSpace = "nowrap";
      dataCell.textContent = value;

      row.appendChild(labelCell);
      row.appendChild(dataCell);

      return row;
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + "px " + tooltip.options.padding + "px";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.zIndex = "2";
    tooltipEl.style.width = "13rem";
  };

  const getDataPoints = (flights, isRoundTrip, isInbound) => {
    if (!flights) return EMPTY_ARRAY;
    const allDepartureTimes = [];

    const sortedFlightsData = flights
      .reduce((result, flight) => {
        const { flightId, itineraries = [], price } = flight;
        const { segments } = itineraries[ZERO];
        const { carrierCode, carrierName, departure } = segments[ZERO];
        const totalPrice = priceFormatter(
          price.travelerPricing[0].priceDetails.totalPrice
        ).replaceAll(",", "");
        const departureTime = departure.time;
        const excludedIATACodes = new Set([originIATACode, destIATACode]);
        const layoverAirportDetails = new Set();

        segments.forEach((segment) => {
          const departureDetails = `${segment.departure.airportName} (${segment.departure.iataCode})`;
          const arrivalDetails = `${segment.arrival.airportName} (${segment.arrival.iataCode})`;

          // Check if the IATA codes are not in the excluded set
          if (!excludedIATACodes.has(segment.departure.iataCode)) {
            layoverAirportDetails.add(departureDetails);
          }
          if (!excludedIATACodes.has(segment.arrival.iataCode)) {
            layoverAirportDetails.add(arrivalDetails);
          }
        });

        const duration = getFormattedDuration(itineraries[ZERO].duration);
        allDepartureTimes.push(departureTime);
        // Find the existing entry for the carrierCode in the result array
        const existingEntry = result.find(
          (entry) => entry.carrierCode === carrierCode
        );

        // If the entry exists, add the new data to its data array
        if (existingEntry) {
          existingEntry.data.push({
            x: departureTime,
            y: totalPrice,
            carrierCode,
            carrierName,
            duration,
            flightId,
            isRoundTrip,
            isInbound,
            layoverAirportDetails,
          });
        } else {
          // If the entry doesn't exist, create a new entry
          result.push({
            pointStyle: getImage(carrierCode),
            carrierCode,
            data: [
              {
                x: departureTime,
                y: totalPrice,
                carrierCode,
                carrierName,
                duration,
                flightId,
                isRoundTrip,
                isInbound,
                layoverAirportDetails,
              },
            ],
            hitRadius: 10,
          });
        }
        return result;
      }, [])
      .sort((a, b) => {
        const timeA = new Date(a.data[0].x);
        const timeB = new Date(b.data[0].x);
        return timeA - timeB;
      });
    return [sortedFlightsData, allDepartureTimes];
  };

  const handleOnCLick = (elements) => {
    setElement = elements;
    const selectedPointerData = get(elements[0], "element.$context.raw", {});
    const selectedFlightId = get(selectedPointerData, "flightId", EMPTY_STRING);
    const isRoundTrip = get(selectedPointerData, "isRoundTrip", false);
    const isInbound = get(selectedPointerData, "isInbound", false);
    const { isolated = {}, packages = [] } = filteredFlights;
    const { outbound = [], inbound = [] } = isolated;

    const setSelectedFlight = (flights, key) => {
      const selectedFlightData = flights.find(
        (flight) => flight.flightId === selectedFlightId
      );
      dispatch(setSelectedFlightId({ [key]: selectedFlightId }));
      dispatch(setActiveSortOrder({ activeSort: EMPTY_STRING, type: key }));
      dispatch(
        setSelectedFlightInfo({
          [key]: {
            flight: selectedFlightData,
            userCountryInformation,
          },
          key: CHART,
        })
      );
    };

    if (selectedFlightId) {
      if (isRoundTrip && !showPackages) {
        if (!isInbound) return setSelectedFlight(outbound, "outbound");
        else return setSelectedFlight(inbound, "inbound");
      } else {
        const selectedFlightData = packages.find(
          (flight) => flight.flightId === selectedFlightId
        );
        dispatch(setSelectedFlightId({ packages: selectedFlightId }));
        dispatch(
          setActiveSortOrder({ activeSort: EMPTY_STRING, type: PACKAGE_RESULT })
        );
        dispatch(
          setSelectedFlightInfo({
            outbound: {
              flight: selectedFlightData,
              userCountryInformation,
            },
            key: CHART,
          })
        );
      }
    }
  };

  const options = {
    onClick: (event, elements) => handleOnCLick(elements),
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      interaction: {
        intersect: false,
        mode: "point",
      },
      tooltip: {
        enabled: false,
        position: "average",
        external: (context) => externalTooltipHandler(context),
      },
    },
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: t("flightChart.time"),
        },
        ticks: {
          maxTicksLimit: 1000, // Display all available hours
        },
      },
      y: {
        title: {
          display: true,
          text: `${t("flightChart.price")} ${currencySymbol}`,
        },
        ticks: {
          maxTicksLimit: 1000, // Adjust the number of ticks displayed
        },
      },
    },
    onHover: (event, elements) => {
      const chart = event.chart;
      const isHovering = elements && elements.length > 0;
      chart.canvas.style.cursor = isHovering ? "pointer" : "default";

      if (isHovering) {
        setElement = elements;
      }
    },
  };

  const renderScatterChart = (flights, isInbound, isPackage) => {
    const [sortedFlightsData, allDepartureTimes] = getDataPoints(
      flights,
      isRoundTrip,
      isInbound
    );
    return (
      <>
        {isGraphVisible && (
          <div>
            <div>
              {!isPackage && (
                <div className="flex items-center">
                  <button className="flex text-gray-900 rounded-t-lg px-1 mt-2 text-base font-normal mb-2">
                    {isInbound ? originCity : destCity}
                    <RenderSVG
                      Svg={isPackage ? ArrowRoundTrip : ArrowLeft}
                      alt="Left Arrow"
                      className="mx-2 w-4"
                      stroke={config.contrast}
                    />
                    <span>{isInbound ? destCity : originCity}</span>
                  </button>
                  <span>| {flights.length} Flights</span>
                </div>
              )}
            </div>
            <div className="self-stretch h-11 py-2 sm:pr-4 flex-col gap-2 justify-center gradientLeftMargin">
              <div className="sm:justify-between items-start mb-2 flex sm:px-10">
                {TIME_PERIODS.map((timePeriod) => (
                  <div
                    key={timePeriod.period}
                    className="h-4 sm:px-4 justify-center mrginLeft items-start sm:gap-2 flex"
                  >
                    <RenderSVG
                      Svg={timePeriod.icon}
                      alt={timePeriod.period}
                      className="mx-1 sm:mx-2 w-3 sm:w-5"
                    />
                    <div className="text-center text-gray-700 text-xs">
                      {timePeriod.period}
                    </div>
                  </div>
                ))}
              </div>
              <div className="day-gadient h-1 mb-2"></div>
            </div>
            <div className="flex h-80">
              <Scatter
                data={{
                  labels: getTimeArray(allDepartureTimes),
                  datasets: sortedFlightsData,
                }}
                options={options}
                plugins={{
                  tooltip: {
                    enabled: false,
                    position: "average",
                    external: (context) => externalTooltipHandler(context),
                  },
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const renderGraphContent = () => {
    const { isolated = {}, packages = [] } = filteredFlights;
    const areIsolatedFlights = isRoundTrip && !showPackages;
    const flights = areIsolatedFlights ? isolated : packages;

    return (
      <div
        className={classNames(
          "bg-gray-50 border border-gray-200 p-2 rounded-xl h-full w-full mx-auto mb-2",
          {
            "mx-1": areIsolatedFlights,
            "ml-px": !areIsolatedFlights,
          }
        )}
      >
        <div className="flex items-center gap-2">
          <div
            onClick={() => setGraphVisibility(!isGraphVisible)}
            className="self-stretch justify-start items-center inline-flex text-primary-700 hover:text-primary-900 hover:scale-105 hover:underline duration-100 gap-1"
          >
            <RenderSVG
              Svg={isGraphVisible ? ChevronUp : ChevronDown}
              className="w-5 my-auto cursor-pointer"
              stroke={config.contrast}
            />
            <span className="text-lg font-semibold p-1 underline cursor-pointer">
              {isGraphVisible
                ? t("flightResults.hideGraph")
                : t("flightResults.viewGraph")}
            </span>
          </div>
          {!isRoundTrip && (
            <div className="font-semibold">
              {"("}
              {flights.length} Flights {")"}
            </div>
          )}
        </div>
        <div
          className={classNames("w-full mx-4 ml-1", {
            "h-400px": isGraphVisible,
            "h-90px": !isGraphVisible,
          })}
        >
          {areIsolatedFlights ? (
            <>
              {renderScatterChart(flights.outbound, false)}
              {renderScatterChart(flights.inbound, true)}
            </>
          ) : (
            renderScatterChart(flights, true, showPackages)
          )}
        </div>
      </div>
    );
  };

  return (
    !isEmpty(filteredFlights) && (
      <div className="mx-auto w-full">{renderGraphContent()}</div>
    )
  );
};

export default memo(FlightsChart);
