import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Cross, RenderSVG } from "../../../assets/icons";
import StarRatingFilters from "./StarRatingFilters";
import CategoryFilters from "./CategoryFilters";
import PriceFilter from "./PriceFilter";
import { selectSelectedDrawer } from "../../../components/organisms/Drawer";
import { DRAWERS } from "../../../components/organisms/AppDrawers/drawer.constants";
import { isEmpty } from "lodash";

const { SHOW_HOTEL_FILTERS_DRAWER } = DRAWERS;

const HotelFilters = ({
  sortingValues,
  hotelFilters,
  setHotelFilters,
  resetFilters,
  setResetFilters,
}) => {
  const { t } = useTranslation();
  const selectedDrawer = useSelector(selectSelectedDrawer);

  return (
    <div
      className={classNames(
        "-start-80 lg:start-0 top-0 flex flex-col border-gray-200 border-contrast-200 transition-all ease-in-out duration-300 border rounded-lg",
        {
          "absolute w-80 border-r":
            selectedDrawer !== SHOW_HOTEL_FILTERS_DRAWER,
        }
      )}
    >
      {selectedDrawer !== SHOW_HOTEL_FILTERS_DRAWER && (
        <div className="px-6 py-4 flex items-center gap-2 border-b border-contrast-200">
          <h4 className="flex-1 text-2xl font-bold text-contrast-900">
            {t("hotelResults.filter")}
          </h4>
          <span className="lg:hidden flex" type="button">
            <RenderSVG Svg={Cross} alt="Cross Icon" />
          </span>
        </div>
      )}
      <div className="filter-body flex-1 overflow-x-auto overflow-y-auto px-8 divide-y divide-contrast-200 ">
        <div className="py-6">
          <PriceFilter
            header="hotelResults.filters.priceRangeHeader"
            priceData={sortingValues.price}
            filters={hotelFilters}
            setFilters={setHotelFilters}
            resetFilters={resetFilters}
            setResetFilters={setResetFilters}
          />
        </div>
        <div className="py-6">
          <StarRatingFilters
            starRatings={sortingValues.starRatings}
            filters={hotelFilters}
            setFilters={setHotelFilters}
            resetFilters={resetFilters}
            setResetFilters={setResetFilters}
          />
        </div>
        {!isEmpty(sortingValues.categories) && (
          <div className="py-6">
            <CategoryFilters
              header="hotelResults.filters.category.header"
              categories={sortingValues.categories}
              filters={hotelFilters}
              setFilters={setHotelFilters}
              resetFilters={resetFilters}
              setResetFilters={setResetFilters}
            />
          </div>
        )}
        {!isEmpty(sortingValues.locality) && (
          <div className="py-6">
            <CategoryFilters
              header="hotelResults.filters.locality.header"
              categories={sortingValues.locality}
              filters={hotelFilters}
              setFilters={setHotelFilters}
              setResetFilters={setResetFilters}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HotelFilters;
