import { Helmet } from "react-helmet";
import AnalyticsCard from "./AnalyticsCard";
import RecentBooking from "./RecentBooking";
import AnalyticsChart from "./AnalyticsChart";
import { cardsData } from "./mockData";
import AdminPageHeader from "../Widgets/AdminPageHeader";
import config from "../../../config.json";

const { brandName } = config;

const Dashboard = () => (
  <div className="flex flex-col flex-1 px-8">
    <Helmet>
      <title>{brandName} | Admin Dashboard</title>
    </Helmet>
    <AdminPageHeader title={"Dashboard"} />
    <div className="grid grid-cols-12 mb-8 gap-6">
      {cardsData.map((cardData) => (
        <AnalyticsCard key={cardData.id} cardData={cardData} />
      ))}
    </div>
    <div className="mb-8">
      <div className="p-8 bg-white shadow-md rounded-lg">
        <AnalyticsChart />
      </div>
      <div className="p-8 bg-white shadow-md rounded-lg my-4">
        <RecentBooking />
      </div>
    </div>
  </div>
);

export default Dashboard;
