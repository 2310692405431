import { get } from "lodash";
import { DEFAULT_VALUES } from "../../constants";
import { fareRulesMapper } from "./getFormattedAmadeusFareRulesMapping";

const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;

const TOTAL_FARE_AMOUNT_CODE = "712";
const ADULT = "ADT";
const CHILD = "CH";
const INFANT = "IN";
const USER_SPECIFIED_RATE = "USR";

const getOtherMonetaryDetail = (otherMonetary) => {
  return Array.isArray(otherMonetary)
    ? otherMonetary.find(
        (detail) => detail.typeQualifier === TOTAL_FARE_AMOUNT_CODE
      )
    : null;
};

const getRateFromRecievedConversionRate = (data) => {
  if (data.conversionRateDetails.rateType === USER_SPECIFIED_RATE) return 1;
  else return parseFloat(data.conversionRateDetails.pricingAmount);
};

const parseFareAmount = (fareAmount, convertionRate) => {
  const rateMultiplier = getRateFromRecievedConversionRate(convertionRate);
  const otherMonetary = fareAmount.otherMonetaryDetails;
  const monetaryDetailsAmount = parseInt(fareAmount.monetaryDetails.amount);
  let otherMonetaryDetailsAmount;
  let currency;
  let basePrice = monetaryDetailsAmount;
  if (Array.isArray(otherMonetary)) {
    const specificOtherMonetaryDetail = getOtherMonetaryDetail(otherMonetary);
    otherMonetaryDetailsAmount = parseInt(specificOtherMonetaryDetail.amount);
    currency = specificOtherMonetaryDetail.currency;
  } else {
    otherMonetaryDetailsAmount = parseInt(otherMonetary.amount);
    currency = otherMonetary.currency;
  }

  if (rateMultiplier) basePrice = monetaryDetailsAmount * rateMultiplier;

  return {
    basePrice,
    grandTotal: otherMonetaryDetailsAmount,
    currency,
  };
};

const getTravelerType = (travelerCode) => {
  switch (travelerCode) {
    case ADULT:
      return "Adult";
    case INFANT:
      return "Infant";
    case CHILD:
      return "Child";
  }
};

const combineByTravelerType = (travelerPricings, combinedPrice) => {
  const combinedObjects = {};

  travelerPricings.forEach((pricing) => {
    const travelerType = pricing.travelerType;

    if (!combinedObjects[travelerType]) {
      combinedObjects[travelerType] = {
        id: pricing.id,
        fareOption: pricing.fareOption,
        travelerType: travelerType,
        travelersCount: ONE,
        price: {
          currency: pricing.price.currency,
          basePrice: pricing.price.basePrice,
          grandTotal: pricing.price.grandTotal,
          commission: pricing.price.commission,
          taxes: pricing.price.taxes,
        },
      };
    } else {
      combinedObjects[travelerType].travelersCount += ONE;
      combinedObjects[travelerType].price.basePrice += pricing.price.basePrice;
      combinedObjects[travelerType].price.grandTotal +=
        pricing.price.grandTotal;
    }
  });

  const totalPrices = Object.values(combinedObjects).reduce(
    (totals, traveler) => {
      totals.basePrice += traveler.price.basePrice;
      totals.grandTotal += traveler.price.grandTotal;
      return totals;
    },
    { basePrice: ZERO, grandTotal: ZERO }
  );

  const totalFare = { ...combinedPrice, ...totalPrices };

  return {
    combinedTravelersPricing: Object.values(combinedObjects),
    totalFare,
  };
};

const processPricingGroup = (pricingGroup, travelerCount, convertionRate) => {
  const fareInfoGroup = pricingGroup.fareInfoGroup;
  const fareAmount = fareInfoGroup.fareAmount;

  const numberOfUnits =
    pricingGroup.numberOfPax.segmentControlDetails.numberOfUnits;
  const segementLevelGroup = Array.isArray(fareInfoGroup?.segmentLevelGroup)
    ? fareInfoGroup?.segmentLevelGroup[ZERO]
    : fareInfoGroup?.segmentLevelGroup;
  const travelerType =
    segementLevelGroup.ptcSegment.quantityDetails.unitQualifier;
  const { basePrice, grandTotal, currency } = parseFareAmount(
    fareAmount,
    convertionRate
  );

  const combinedPrice = {
    basePrice,
    grandTotal,
    currency,
  };

  const travelerPricing = {
    id: travelerCount + ONE,
    fareOption: EMPTY_STRING,
    travelerType: getTravelerType(travelerType),
    travelerCount: EMPTY_STRING,
    price: {
      currency,
      basePrice,
      grandTotal,
      commission: EMPTY_STRING,
      taxes: EMPTY_STRING,
    },
  };

  return { combinedPrice, travelerPricing, numberOfUnits };
};

const calculateTotalPrices = (flights) => {
  let totalPrices = {
    currency: "INR",
    basePrice: ZERO,
    grandTotal: ZERO,
    commission: ZERO,
    IsLCC: null,
  };
  let totaltaxes = {
    taxAmount: ZERO,
    taxCurrency: EMPTY_STRING,
    otherCharges: ZERO,
  };

  flights.forEach((flight) => {
    totalPrices.basePrice += flight.price.basePrice;
    totalPrices.grandTotal += flight.price.grandTotal;
    totalPrices.commission = flight.price.commission;
    totalPrices.IsLCC = flight.price.IsLCC;
    totaltaxes.taxAmount += flight.taxes.taxAmount;
    totaltaxes.taxCurrency = flight.taxes.taxCurrency;
    totaltaxes.otherCharges += flight.taxes.otherCharges;
  });

  return { totalPrices, totaltaxes };
};

const getFormattedResult = (priceData) => {
  const transformedPriceResponse = JSON.parse(
    JSON.stringify(priceData, (key, value) => {
      if (typeof value === "object" && value?._text) {
        return value?._text;
      }
      return value;
    })
  );
  const formattedResponses = [];
  const transformedPriceData = Array.isArray(transformedPriceResponse)
    ? transformedPriceResponse
    : [transformedPriceResponse];
  transformedPriceData?.forEach((transformedObject, index) => {
    const pricingGroupLevelGroup = get(
      transformedObject?.output?.informativePricing?.input["soap:Envelope"],
      "soap:Body.Fare_InformativePricingWithoutPNRReply.mainGroup.pricingGroupLevelGroup"
    );
    const convertionRate = get(
      transformedObject?.output?.informativePricing?.input["soap:Envelope"],
      "soap:Body.Fare_InformativePricingWithoutPNRReply.mainGroup.convertionRate"
    );
    const fareRulesData =
      transformedObject && transformedObject.output?.miniRules;
    let travelerCount = ZERO;
    let combinedPrice = {
      currency: "INR",
      basePrice: ZERO,
      grandTotal: ZERO,
    };
    const journeyId = get(transformedObject, "output.journeyId", EMPTY_STRING);

    const travelersPricing = [];

    const processPricingGroups = (pricingGroups) => {
      pricingGroups.forEach((pricingGroup) => {
        const {
          combinedPrice: groupCombinedPrice,
          travelerPricing,
          numberOfUnits,
        } = processPricingGroup(pricingGroup, travelerCount, convertionRate);

        travelersPricing.push(
          ...Array.from(
            { length: parseInt(numberOfUnits) },
            () => travelerPricing
          )
        );
        travelerCount += parseInt(numberOfUnits);

        combinedPrice.basePrice += groupCombinedPrice.basePrice;
        combinedPrice.grandTotal += groupCombinedPrice.grandTotal;
        combinedPrice.currency = groupCombinedPrice.currency;
      });
    };
    if (pricingGroupLevelGroup) {
      if (Array.isArray(pricingGroupLevelGroup))
        processPricingGroups(pricingGroupLevelGroup);
      else processPricingGroups([pricingGroupLevelGroup]);
      const { totalFare, combinedTravelersPricing } = combineByTravelerType(
        travelersPricing,
        combinedPrice
      );

      const taxes = {
        taxAmount: totalFare.grandTotal - totalFare.basePrice,
        taxCurrency: totalFare.currency,
        otherCharges: ZERO,
      };

      const priceResponse = {
        price: {
          ...totalFare,
          commission: "",
          IsLCC: null,
        },
        taxes,
        travelerPricings: combinedTravelersPricing,
        amadeusFareRules: fareRulesMapper(fareRulesData, journeyId),
      };

      formattedResponses[index] = priceResponse;
    }
  });

  return formattedResponses;
};

const formattedTravelerResult = (travelData) => {
  const totalPriceByTravelerType = [];

  const findIndex = (travelerType) =>
    totalPriceByTravelerType.findIndex(
      (item) => item.travelerType === travelerType
    );

  travelData.forEach((trip) => {
    trip.travelerPricings.forEach((pricing) => {
      const { travelerType, price, travelersCount } = pricing;
      const { basePrice, grandTotal } = price;

      const index = findIndex(travelerType);

      if (index === -ONE) {
        totalPriceByTravelerType.push({
          id: pricing.id,
          fareOption: pricing.fareOption,
          travelerType,
          travelersCount,
          price: {
            currency: price.currency,
            basePrice,
            grandTotal,
            commission: price.commission,
            taxes: price.taxes,
          },
        });
      } else {
        totalPriceByTravelerType[index].price.basePrice += basePrice;
        totalPriceByTravelerType[index].price.grandTotal += grandTotal;
      }
    });
  });

  return totalPriceByTravelerType;
};

const getMergedData = (data) => {
  const result = data.reduce((acc, obj) => {
    acc = acc.concat(obj?.amadeusFareRules);

    return acc;
  }, []);
  return result;
};

export const getFormattedAmadeusResponse = (data) => {
  const result = getFormattedResult(data);
  const formattedPricing = formattedTravelerResult(result);
  const { totalPrices, totaltaxes } = calculateTotalPrices(result);
  const combinedFareRule = getMergedData(result);

  const amadeusResponse = [
    {
      source: "AMADEUS",
      flightId: "",
      price: totalPrices,
      pricingOptions: {
        fareType: "",
        includedCheckedBagsOnly: "",
      },
      taxes: totaltaxes,
      refund: {
        refundableTax: "",
        refundApplicable: "",
        refundAmount: "",
        refundConditions: ["N/A"],
      },
      travelerPricings: formattedPricing,
      bookingRequirements: {
        emailAddressRequired: "",
        mobilePhoneNumberRequired: "",
      },
      amadeusFareRules: combinedFareRule,
    },
  ];

  return amadeusResponse;
};
