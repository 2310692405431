import { useTranslation } from "react-i18next";
import classNames from "classnames";
import {
  getLayoverDuration,
  getFormattedDuration,
  getCityNameByIata,
  calculateTotalDuration,
  priceFormatter,
} from "../../helper";
import FlightSearchResultCardTabs from "./FlightSearchResultCardTabs";
import { getStops } from "../../utils/flights.utils";
import PackagedFlightDetails from "./PackagedFlightDetails";
import { DEFAULT_VALUES, INDEX, FLIGHT_SERVICE_TYPE } from "../../constants";

const { ZERO, ONE, EMPTY_STRING, EMPTY_OBJECT } = DEFAULT_VALUES;
const { LAST } = INDEX;
const { AMADEUS } = FLIGHT_SERVICE_TYPE;
const MAX_CARDS_IN_ROW = 3;

const MultiCityCard = ({ flightData }) => {
  const {
    getFlightDetailsCollapseProps,
    getFlightDetailsToggleProps,
    isFlightsDetailsExpanded,
    flight,
    totalPrice,
    hasHighCO2Emission,
    isSelectedFlight,
    currency,
    handleSelectPackage,
  } = flightData;

  const { itineraries, baggage, source } = flight || EMPTY_OBJECT;

  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        "border rounded-lg transition duration-1000 ease-in-out",
        {
          "border-primary-600 bg-primary-100": isSelectedFlight,
          "border-contrast-200 bg-white hover:border-contrast-400":
            !isSelectedFlight,
        }
      )}
    >
      <div className="flex p-4 flex-col sm:flex-row">
        <div
          className={classNames("flex w-full sm:border-r border-0", {
            "flex-col xl:flex-row ": itineraries.length <= MAX_CARDS_IN_ROW,
            "flex-col": itineraries.length > MAX_CARDS_IN_ROW,
          })}
        >
          {itineraries.map((itinerary, index) => {
            const { segments, duration } = itinerary;
            const startLocation = segments[ZERO];
            const { noOfAvailableSeats, departure = EMPTY_OBJECT } =
              startLocation;
            const finalDestination = segments.at(LAST);
            const totalNumberOfStops = segments.length - ONE;
            const layoverDuration = getLayoverDuration(segments);
            const { arrival } = finalDestination;

            const getNoOfSeats = () => {
              if (noOfAvailableSeats)
                return `${noOfAvailableSeats} ${
                  noOfAvailableSeats > ONE
                    ? t("flightResults.seatsLeft")
                    : t("flightResults.seatLeft")
                }`;
            };

            return (
              <div
                key={`${departure.date}${departure.time}`}
                className="flex flex-col gap-4 flex-1 justify-between py-2"
              >
                <div
                  className={classNames(
                    "pr-4 mr-4 mb-1 flex flex-wrap gap-4 justify-between",
                    {
                      "border-gray-700": index === ZERO,
                    }
                  )}
                >
                  <PackagedFlightDetails
                    arrival={arrival}
                    startLocation={startLocation}
                    hasHighCO2Emission={hasHighCO2Emission}
                    baggage={baggage}
                    getNoOfSeats={getNoOfSeats}
                  />
                  <div className="whitespace-nowrap md:w-20">
                    <div className="text-md text-gray-900 font-semibold mb-1">
                      {source === AMADEUS
                        ? getFormattedDuration(duration)
                        : calculateTotalDuration(layoverDuration, duration)}
                      <div className="text-xs text-gray-600 font-bold whitespace-normal">
                        {getCityNameByIata(departure.iataCode)} -
                        {getCityNameByIata(arrival.iataCode)}
                      </div>
                    </div>
                  </div>
                  <div className="flex whitespace-nowrap md:w-20">
                    {!totalNumberOfStops ? (
                      <div className="flex text-gray-900 font-semibold mb-1 justify-end md:justify-start">
                        {t("flightResults.stopTypes.nonStop")}
                      </div>
                    ) : (
                      <div className="justify-end md:justify-start">
                        <h5 className="flex text-gray-900 font-semibold mb-1">
                          {totalNumberOfStops}
                          {t("flightResults.stopTypes.stop")}
                        </h5>
                        <div className="text-xs text-gray-600">
                          {getStops(segments)}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-between sm:flex-col sm:ml-auto gap-1 items-center sm:items-stretch sm:w-auto w-full py-0 sm:px-4 flex-wrap">
          <div className="flex text-sm sm:text-lg text-end text-primary-600 font-bold">
            {currency}&nbsp;
            {priceFormatter(totalPrice)}
          </div>
          <div className="flex flex-row py-2 pl-2 pr-0 sm:px-0 md:mb-auto">
            <div className="flex flex-row gap-3 justify-end mb-2 border-b-contrast-200">
              <div className="flex">
                <button
                  className={classNames(
                    "py-2 px-4 flex items-center  gap-2 rounded-md hover:bg-primary-300 active:bg-primary-200 border-none shadow-sm text-sm text-primary-700 font-medium whitespace-nowrap",
                    {
                      "bg-primary-200": isSelectedFlight,
                      "bg-primary-100": !isSelectedFlight,
                    }
                  )}
                  onClick={handleSelectPackage}
                >
                  {t("flightResults.selectFlight")}
                </button>
              </div>
            </div>
          </div>
          <div {...getFlightDetailsToggleProps()}>
            <div className="flex sm:right-2 justify-start text-primary-600 text-sm cursor-pointer d-block lg:d-none mb-2 font-semibold">
              {isFlightsDetailsExpanded
                ? t("flightResults.hideDetails")
                : t("flightResults.viewDetails")}
            </div>
          </div>
        </div>
      </div>
      {isFlightsDetailsExpanded && (
        <div {...getFlightDetailsCollapseProps()}>
          <div className="rounded-lg border-t-2 bg-gray-50">
            <FlightSearchResultCardTabs flight={flight} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiCityCard;
