import { useSelector, useDispatch } from "react-redux";
import Modal, { setSelectedModal } from "../../Modal";
import get from "lodash/get";
import {
  selectHotelInfo,
  selectPricePolicyInfo,
} from "../../../../screens/HotelInfo";
import { getAmenityIcon } from "../../../../assets/icons/Amenities";
import { MODAL_SIZE, DEFAULT_VALUES, ROUTES } from "../../../../constants";

const { LG } = MODAL_SIZE;
const { EMPTY_OBJECT, EMPTY_ARRAY } = DEFAULT_VALUES;
const { HOTEL_REVIEW } = ROUTES;
const Inclusion = "Inclusion";
const Amenities = "Amenities";

const AmenitiesModal = () => {
  const dispatch = useDispatch();
  const hotelInfo = useSelector(selectHotelInfo);
  const pricePolicyInfo = useSelector(selectPricePolicyInfo);
  const hotelDetails = get(
    hotelInfo,
    "HotelRoomInfo.HotelInfoResult.HotelDetails",
    EMPTY_OBJECT
  );
  const hotelName = hotelDetails.HotelName;
  const hotelFacilities = hotelDetails.HotelFacilities;
  const isHotelReviewPage = location.pathname.includes(HOTEL_REVIEW);
  const inclusionDetails = get(
    pricePolicyInfo,
    "PriceAndCancellationPolicyDetails.PriceAndCancellationPolicyDetail.BlockRoomResult.HotelRoomsDetails[0].Inclusion",
    EMPTY_ARRAY
  );
  const amenities = isHotelReviewPage ? inclusionDetails : hotelFacilities;
  const title = isHotelReviewPage ? Inclusion : Amenities;
  const handleClose = () => dispatch(setSelectedModal(null));
  const modalTitle = (
    <div className="font-normal">
      {title} at <strong>{hotelName}</strong>
    </div>
  );

  return (
    <div className="overflow-auto">
      <Modal
        title={modalTitle}
        size={LG}
        shouldShowModalFromProps
        handleClose={handleClose}
      >
        <div className="px-10 pb-10 font-[inter] overflow-auto font-medium text-xs sm:text-sm text-contrast-900 grid grid-cols-1 xs:grid-cols-2 gap-3 sm:gap-4 col-span-12">
          {amenities.map((amenity) => (
            <div key={amenity} className="flex items-center gap-4 col-6">
              <img
                src={getAmenityIcon(amenity)}
                width={25}
                alt="hotel-amenity"
              />
              <div> {amenity} </div>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default AmenitiesModal;
