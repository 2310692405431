import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { DotPattern, RenderSVG } from "../../assets/icons";
import { getImageUrlByName } from "../../helper";
import SearchSection from "../../components/organisms/Search";
import HybridSearch from "./HybridSearch";
import { shouldEnableHotels } from "../../constants";
import config from "../../config.json";

const { brandName } = config;

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{brandName} | Homepage</title>
      </Helmet>
      <div className="py-24 bg-contrast-50 relative px-4 sm:px-6 mt-[-12px]">
        <RenderSVG
          Svg={DotPattern}
          className="absolute right-0 top-[-25px]"
          alt="Dot Pattern"
          width="241"
          height="402"
        />
        <RenderSVG
          Svg={DotPattern}
          className="absolute left-0 bottom-0"
          alt="Dot Pattern"
          width="241"
          height="402"
        />
        <img
          src={getImageUrlByName("banner/image2.png")}
          className="absolute left-0 top-0 hidden lg:inline-block"
          alt="Banner Image"
        />
        <img
          src={getImageUrlByName("banner/image1.png")}
          className="absolute right-0 top-0 hidden lg:inline-block"
          alt="Banner Image"
        />
        <div className="container mx-auto relative">
          <div className="text-center mb-8">
            <h1 className="text-4xl sm:text-6xl text-contrast-900 font-bold mb-2 sm:mb-4">
              <span className="text-primary-600">{t("homepage.explore")}</span>
              {t("homepage.theWorld")}
            </h1>
            <p className="text-base sm:text-xl text-contrast-500">
              {t("homepage.discoverMsg")}
            </p>
          </div>
        </div>
        <div className="max-w-[1366px] mx-auto relative text-lg">
          {shouldEnableHotels ? (
            <HybridSearch />
          ) : (
            <div className="p-6 rounded-2xl bg-primary-900 text-white">
              <SearchSection />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
