import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { set } from "lodash";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import get from "lodash/get";
import { v4 as uuid } from "uuid";
import { createPopper } from "@popperjs/core";
import { RenderSVG, Users } from "../../../assets/icons";
import { DEFAULT_VALUES, WINDOWS_EVENTS, ROUTES } from "../../../constants";

const { HOME, FLIGHT_RESULTS } = ROUTES;
const { ZERO, ONE, EMPTY_ARRAY } = DEFAULT_VALUES;
const { CLICK } = WINDOWS_EVENTS;
const MAX_AGE_OF_CHILDREN = 17;
const MAX_ROOM_CAN_BOOK = 6;
const MAX_ADULTS_PER_ROOM = 8;
const MAX_CHILDREN_PER_ROOM = 4;
const MAX_GUESTS_PER_ROOM = 10;

const childrenAgeRange = Array.from({ length: MAX_AGE_OF_CHILDREN + ONE }).map(
  (_, index) => ({
    label: index.toString(),
    value: index,
  })
);

const roomsOptions = Array.from({ length: MAX_ROOM_CAN_BOOK }).map(
  (_, index) => ({
    id: index,
    label: `${index + ONE} Room${index !== ZERO ? "s" : ""}`,
    value: index + ONE,
  })
);

const adultCountOptions = Array.from({ length: MAX_ADULTS_PER_ROOM }).map(
  (_, index) => ({
    id: index,
    label: `${index + ONE} Adult${index !== ZERO ? "s" : ""}`,
    value: index + ONE,
  })
);

const HotelTravelersCount = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const guestsCountRef = useRef();
  const selectRef = useRef();
  const dropdownRef = useRef();
  const popperInstance = useRef(null);
  const [show, setShow] = useState(false);
  const [totalGuests, setTotalGuests] = useState(ONE);
  const { values, setFieldValue } = useFormikContext();

  const { noOfRooms = ONE, RoomGuests = EMPTY_ARRAY } = values;

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        guestsCountRef.current &&
        !guestsCountRef.current.contains(e.target)
      ) {
        setShow(false);
      }
    };
    document.addEventListener(CLICK, checkIfClickedOutside);

    return () => {
      document.removeEventListener(CLICK, checkIfClickedOutside);
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [guestsCountRef.current]);

  useEffect(() => {
    const totalCount = RoomGuests.reduce(
      (total, guestObject) =>
        total +
        parseInt(guestObject["NoOfAdults"]) +
        parseInt(guestObject["NoOfChild"]),
      ZERO
    );
    setTotalGuests(totalCount);
  }, [RoomGuests]);

  useEffect(() => {
    if (show) {
      popperInstance.current = createPopper(
        selectRef.current,
        dropdownRef.current,
        {
          placement: "bottom",
        }
      );
    }

    return () => {
      if (popperInstance.current) popperInstance.current.destroy();
    };
  }, [show]);

  const handleRoomCountChange = (e) => {
    const { value } = e.target;
    if (value > noOfRooms) {
      const updatedGuestsCount = Array.from({
        length: value - noOfRooms,
      }).map(() => ({
        NoOfAdults: ONE,
        NoOfChild: ZERO,
        ChildAge: [],
      }));
      setFieldValue("RoomGuests", [...RoomGuests, ...updatedGuestsCount]);
    } else if (value < noOfRooms) {
      const updatedGuestsCounts = RoomGuests.slice(0, value);
      setFieldValue("RoomGuests", updatedGuestsCounts);
    }
    setFieldValue("noOfRooms", value);
  };

  const handleGuestCountChange = (value, roomIndex, type) => {
    let updatedGuestsCounts = [...RoomGuests];
    let updatedChildAge = [...updatedGuestsCounts[roomIndex].ChildAge];

    if (type === "NoOfChild") {
      if (value > updatedChildAge.length) {
        const extraAgesArr = Array.from({
          length: value - updatedChildAge.length,
        }).fill(ONE);
        updatedChildAge = [...updatedChildAge, ...extraAgesArr];
      } else if (value < updatedChildAge.length) {
        updatedChildAge = updatedChildAge.slice(0, value);
      }
      updatedGuestsCounts[roomIndex] = {
        ...updatedGuestsCounts[roomIndex],
        [type]: value,
        ChildAge: updatedChildAge,
      };
    } else {
      updatedGuestsCounts[roomIndex] = {
        ...updatedGuestsCounts[roomIndex],
        [type]: value,
      };
    }

    setFieldValue("RoomGuests", updatedGuestsCounts);
  };

  const handleChildAgeChange = (value, roomIndex, childIndex) => {
    let updatedGuestsCount = [...RoomGuests];
    set(updatedGuestsCount, `${roomIndex}.ChildAge[${childIndex}]`, value);
    setFieldValue("RoomGuests", updatedGuestsCount);
  };

  const renderCountPlaceholder = () => {
    return totalGuests > ONE
      ? t("searchSection.guests")
      : t("searchSection.guest");
  };

  const isBottom = get(
    popperInstance,
    'current.state.placement.includes("bottom")',
    ""
  );

  return (
    <div ref={guestsCountRef}>
      <button
        ref={selectRef}
        type="button"
        onClick={() => setShow(!show)}
        className={classNames(
          "rounded-md w-full bg-white border shadow-sm border-contrast-300 flex items-center gap-2 p-3",
          {
            "py-3": location.pathname === HOME,
            "py-3.5": location.pathname === FLIGHT_RESULTS,
          }
        )}
      >
        <RenderSVG Svg={Users} className="text-contrast-400" alt="User Icon" />
        <span className="text-gray-900 whitespace-nowrap text-ellipsis overflow-hidden text-[17px]">
          {noOfRooms} Room | {`${totalGuests} ${renderCountPlaceholder()}`}
        </span>
      </button>
      {show && (
        <div
          ref={dropdownRef}
          className={classNames(
            "dropdown-menu absolute z-30 bg-white px-6 py-4 rounded-lg border border-contrast-200 shadow-2xl my-2 w-full md:min-w-[450px] md:right-0 !mt-3 max-h-72 overflow-y-auto",
            {
              "top-full": !isBottom,
              "bottom-full": isBottom,
            }
          )}
        >
          <div className="flex justify-between">
            <div className="font-semibold">No. of Room(s)</div>
            <div className=" border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500">
              <select
                className="w-32 rounded-md py-2 px-8 border-0 block text-sm shadow-none bg-primary-100/80"
                onChange={handleRoomCountChange}
                value={noOfRooms}
              >
                {roomsOptions.map((item) => (
                  <option key={item.id} value={item.value} className="text-sm">
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {Array.from({ length: noOfRooms }).map((_, index) => {
            const childrenCountOptions = Array.from({
              length:
                Math.min(
                  MAX_CHILDREN_PER_ROOM,
                  MAX_GUESTS_PER_ROOM - RoomGuests[index]?.NoOfAdults
                ) + ONE,
            }).map((_, index) => ({
              id: index,
              label: `${index} ${index <= ONE ? "Child" : "Children"}`,
              value: index,
            }));

            return (
              <div key={uuid()}>
                <ul className="divide-y divide-contrast-200">
                  <li className="py-2 flex items-center justify-between">
                    <div className="font-semibold">Room {index + ONE} </div>
                    <div className="flex gap-2">
                      <div className="border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500">
                        <select
                          className="w-32 rounded-md py-2 px-8 border-0 block text-sm shadow-none bg-primary-100/80"
                          onChange={(e) =>
                            handleGuestCountChange(
                              e.target.value,
                              index,
                              "NoOfAdults"
                            )
                          }
                          value={RoomGuests[index]?.NoOfAdults}
                        >
                          {adultCountOptions.map(({ id, label, value }) => (
                            <option key={id} value={value} className="text-sm">
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500">
                        <select
                          className="w-32 rounded-md py-2 px-8 border-0 block text-sm shadow-none bg-primary-100/80"
                          onChange={(e) =>
                            handleGuestCountChange(
                              e.target.value,
                              index,
                              "NoOfChild"
                            )
                          }
                          value={RoomGuests[index]?.NoOfChild}
                        >
                          {childrenCountOptions.map(({ id, label, value }) => (
                            <option key={id} value={value} className="text-sm">
                              {label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </li>
                </ul>
                {RoomGuests[index]?.ChildAge.length !== ZERO && (
                  <div className="max-h-60 overflow-y-auto no-scrollbar">
                    <div>
                      {RoomGuests[index]?.ChildAge.map((age, id) => (
                        <div
                          key={uuid()}
                          className="flex gap-2 space-y-1 justify-between text-contrast-900 items-center"
                        >
                          <label
                            className="text-sm ml-auto"
                            htmlFor={`childAge-${id}`}
                          >
                            Child Age {id + ONE} (Years)
                          </label>
                          <div className="border border-contrast-300 rounded-md focus:outline-none focus-within:ring-1 focus-within:ring-primary-500 focus-within:border-primary-500">
                            <select
                              id={`childAge-${id}`}
                              className="w-32 rounded-md  py-2 px-8 border-0 block text-sm shadow-none bg-primary-100/80"
                              onChange={(e) =>
                                handleChildAgeChange(e.target.value, index, id)
                              }
                              value={age}
                            >
                              {childrenAgeRange.map(({ label, value }) => (
                                <option
                                  key={label}
                                  value={value}
                                  className="text-sm"
                                >
                                  {label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default HotelTravelersCount;
