import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { cloneDeep, get, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { Filter, RenderSVG } from "../../assets/icons";
import {
  getObjectFromQueryParams,
  setToSessionStorage,
  priceFormatter,
  getFormattedAddress,
} from "../../helper";
import { setHotelInfoReqBody } from "../HotelInfo";
import Spinner, { SPINNER_NAMES } from "../../components/organisms/Spinner";
import {
  selectHotelSearchFilters,
  selectHotels,
} from "../../components/organisms/Search";
import { setSelectedDrawer } from "../../components/organisms/Drawer";
import { selectCountryInfo } from "../Profile";
import NoHotelResults from "./NoHotelResults";
import SearchHotelSection from "../../components/organisms/Search/SearchHotelSection";
import HotelFilters from "./HotelFilters/HotelFilters";
import HotelFiltersDrawer from "./HotelFilters/HotelFiltersDrawer";
import { DRAWERS } from "../../components/organisms/AppDrawers/drawer.constants";
import {
  ROUTES,
  CACHE_KEYS,
  DEFAULT_VALUES,
  INITIAL_HOTEL_VALUES,
  INITIAL_HOTEL_FILTERS,
  CURRENCY_SYMBOLS,
} from "../../constants";
import config from "../../config.json";

const { brandName } = config;
const { HOTEL_INFO_REQUEST_BODY } = CACHE_KEYS;
const { HOTEL_INFO } = ROUTES;
const { FETCH_HOTEL_RESULTS } = SPINNER_NAMES;
const { ZERO, ONE, EMPTY_STRING, EMPTY_ARRAY, EMPTY_OBJECT } = DEFAULT_VALUES;
const { SHOW_HOTEL_FILTERS_DRAWER } = DRAWERS;
const { INR } = CURRENCY_SYMBOLS;

const HotelResultCard = ({ hotelInformation, TraceId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [imageError, setImageError] = useState(false);

  const selectedCountryInfo = useSelector(selectCountryInfo);
  const {
    ResultIndex,
    HotelCode,
    HotelName,
    HotelAddress,
    StarRating,
    Price: { PublishedPriceRoundedOff, OtherCharges, AgentCommission, Tax },
    HotelPicture,
    HotelContactNo,
    SupplierHotelCodes,
  } = hotelInformation;

  const totalTax = OtherCharges + AgentCommission + Tax;
  const currencySymbol = get(selectedCountryInfo, "currency.symbol", INR);
  const CategoryId = get(SupplierHotelCodes, "[0].CategoryId", EMPTY_STRING);

  const handleSelectHotel = () => {
    const { ip: endUserIp } = selectedCountryInfo;
    const hotelInfoAPIReq = {
      ResultIndex,
      HotelCode,
      CategoryId,
      EndUserIp: endUserIp,
      TraceId,
    };
    dispatch(setHotelInfoReqBody(hotelInfoAPIReq));
    setToSessionStorage(HOTEL_INFO_REQUEST_BODY, hotelInfoAPIReq);
    navigate(HOTEL_INFO.replace(":hotelId", HotelCode));
  };

  return (
    <div className="block p-3 rounded-lg border mb-3 hover:border-contrast-400 transition duration-1000 ease-in-out">
      <div className="flex flex-col sm:grid grid-cols-12 sm:col-span-12 gap-3">
        <div className="col-span-3">
          {imageError ? (
            <div className="flex justify-center items-center w-full rounded-lg h-28 bg-black bg-opacity-30 px-4 py-2">
              <span className=" text-white font-bold">{HotelName}</span>
            </div>
          ) : (
            <img
              src={HotelPicture}
              alt={HotelName}
              onError={() => setImageError(true)}
              className="w-full rounded-lg h-28"
            />
          )}
        </div>
        <div className="col-span-6 flex flex-col gap-3">
          <div className="flex justify-between items-top gap-2">
            <div className="text-lg sm:text-base font-semibold">
              {HotelName}
            </div>
            <div className="flex place-content-around">
              <div className="text-sm xs:text-xs border bg-contrast-100 rounded-full px-2 whitespace-nowrap h-fit">
                {StarRating}
                {t("hotelResults.hotelCard.star")}
              </div>
            </div>
          </div>
          <div className="text-base sm:text-xs xs:text-xxs font-normal">
            <span className="text-sm xs:text-xxs text-contrast-500">
              {getFormattedAddress(HotelAddress)}
            </span>
            {HotelContactNo && (
              <span className="text-sm xs:text-xxs text-contrast-500">
                {" "}
                • {HotelContactNo}
              </span>
            )}
          </div>
        </div>
        <div className="col-span-3 flex flex-row sm:flex-col xl:flex-row justify-between sm:justify-center gap-3 lg:gap-1 items-center">
          <div className="flex lg:flex-1 text-primary-600 flex-col items-center justify-center text-center">
            <div className="text-lg sm:text-base xs:text-sm font-semibold whitespace-nowrap">
              {currencySymbol}
              {priceFormatter(PublishedPriceRoundedOff)}
            </div>
            <div className="text-sm xs:text-xs whitespace-nowrap">
              +{currencySymbol}
              {priceFormatter(totalTax)} {t("hotelResults.hotelCard.taxes")}
            </div>
          </div>
          <div>
            <button
              className="py-2 px-4 flex items-center gap-2 rounded-md bg-primary-100 hover:bg-primary-200 border-none shadow-sm text-sm text-primary-700 font-normal whitespace-nowrap"
              onClick={handleSelectHotel}
            >
              {t("hotelResults.hotelCard.select")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const HotelResults = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedHotelResults = useSelector(selectHotels);
  const hotelSearchFilters = useSelector(selectHotelSearchFilters);
  const hotelResults = get(
    selectedHotelResults,
    "HotelSearchResult.HotelResults",
    EMPTY_ARRAY
  );
  const traceId = get(
    selectedHotelResults,
    "HotelSearchResult.TraceId",
    EMPTY_ARRAY
  );
  const { CityName } = getObjectFromQueryParams(search);

  const defaultExpandedValue = !useMediaQuery({ maxWidth: 1000 });

  const [showFilters, setShowFilters] = useState(false);
  const [filteredHotels, setFilteredHotels] = useState(EMPTY_OBJECT);
  const [sortingValues, setSortingValues] = useState(INITIAL_HOTEL_VALUES);
  const [hotelFilters, setHotelFilters] = useState(INITIAL_HOTEL_FILTERS);
  const [resetFilters, setResetFilters] = useState(false);

  const getHotelPrice = (hotel) => {
    const {
      Price: { PublishedPriceRoundedOff, Tax },
    } = hotel;
    return PublishedPriceRoundedOff + Tax;
  };

  useEffect(() => {
    if (hotelResults.length) {
      setHotelFilters(INITIAL_HOTEL_FILTERS);
      const updatedSortingValues = hotelResults.reduce((values, hotel) => {
        const price = getHotelPrice(hotel);
        const location = hotel.HotelLocation;
        const stars = hotel.StarRating;
        const category = hotel.HotelCategory;

        values.price.minPrice = values.price.minPrice
          ? Math.min(values.price.minPrice, Math.floor(price))
          : Math.floor(price);
        values.price.maxPrice = values.price.maxPrice
          ? Math.max(values.price.maxPrice, Math.ceil(price))
          : Math.ceil(price);

        location &&
          (values.locality[location] =
            (values.locality[location] || ZERO) + ONE);
        stars &&
          (values.starRatings[stars] =
            (values.starRatings[stars] || ZERO) + ONE);
        category &&
          (values.categories[category] =
            (values.categories[category] || ZERO) + ONE);

        return values;
      }, cloneDeep(INITIAL_HOTEL_VALUES));

      updatedSortingValues.price.currency = get(
        hotelResults[ZERO],
        "Price.CurrencyCode",
        EMPTY_STRING
      );
      setSortingValues(updatedSortingValues);
      setShowFilters(true);
    }
  }, [hotelResults]);

  useEffect(() => {
    if (!hotelResults) return;
    const filteredHotelResults = hotelResults.filter((hotel) => {
      const price = getHotelPrice(hotel);
      const location = hotel.HotelLocation;
      const stars = hotel.StarRating;
      const category = hotel.HotelCategory;

      const locationCheck =
        !isEmpty(hotelFilters.locality) &&
        hotelFilters.locality.includes(location);
      const starsCheck =
        !isEmpty(hotelFilters.starRatings) &&
        !hotelFilters.starRatings.includes(String(stars));
      const categoryCheck =
        !isEmpty(hotelFilters.categories) &&
        !hotelFilters.categories.includes(category);

      const maxPriceCheck =
        hotelFilters.maxPrice && price > hotelFilters.maxPrice;
      const minPriceCheck =
        hotelFilters.minPrice && price < hotelFilters.minPrice;

      return !(
        locationCheck ||
        starsCheck ||
        categoryCheck ||
        maxPriceCheck ||
        minPriceCheck
      );
    });
    setFilteredHotels(filteredHotelResults);
  }, [hotelFilters, hotelResults]);

  useEffect(() => {
    if (hotelSearchFilters && hotelResults) setFilteredHotels(hotelResults);
  }, [hotelSearchFilters, hotelResults]);

  return (
    <>
      <Helmet>
        <title>{brandName} | Hotels</title>
      </Helmet>
      <div className="sticky top-0 px-8 p-3 border-b z-30 bg-white border-contrast-200">
        <SearchHotelSection
          showEditSearch={true}
          defaultExpandedValue={defaultExpandedValue}
        />
      </div>

      <div className="min-h-[60vh] flex flex-col align-middle relative">
        <Spinner
          name={FETCH_HOTEL_RESULTS}
          message={t("hotelResults.searching")}
          size="w-10 h-10"
        >
          {!isEmpty(get(selectedHotelResults, "HotelSearchResult")) && (
            <div>
              {!isEmpty(hotelResults) && showFilters && (
                <div className="hidden lg:block">
                  <HotelFilters
                    sortingValues={sortingValues}
                    hotelFilters={hotelFilters}
                    setHotelFilters={setHotelFilters}
                    resetFilters={resetFilters}
                    setResetFilters={setResetFilters}
                  />
                </div>
              )}
              <div
                className={classNames("bg-white", {
                  "lg:ml-80": !!hotelResults.length,
                  "lg:w-full": !hotelResults.length,
                })}
              >
                <div className="flex justify-between px-6 py-4 border-b border-contrast-200 text-contrast-900 text-2xl font-bold w-full">
                  <div>
                    {t("hotelResults.property")}
                    {CityName}
                  </div>
                  <div className="flex justify-between">
                    <div className="flex gap-4">
                      {hotelResults.length !== filteredHotels.length && (
                        <button
                          className="py-2 px-4 flex items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-600 font-medium"
                          onClick={() => {
                            setResetFilters(true);
                            setFilteredHotels(hotelResults);
                          }}
                          type="button"
                        >
                          <RenderSVG Svg={Filter} alt="Filter Icon" />
                          <span>{t("hotelResults.filterReset")}</span>
                        </button>
                      )}
                      <button
                        className="py-2 px-4 flex lg:hidden items-center gap-2 rounded-md bg-white hover:bg-contrast-50 active:bg-white border border-contrast-300 shadow-sm text-sm text-contrast-600 font-medium disabled:cursor-not-allowed"
                        disabled={isEmpty(hotelResults)}
                        onClick={() =>
                          dispatch(setSelectedDrawer(SHOW_HOTEL_FILTERS_DRAWER))
                        }
                        type="button"
                      >
                        <RenderSVG Svg={Filter} alt="Filter Icon" />
                        <span>{t("hotelResults.filter")}</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="px-6 py-4 max-h-[87vh] overflow-y-auto rounded-lg no-scrollbar">
                  {!isEmpty(filteredHotels) ? (
                    filteredHotels.map((hotelInformation) => (
                      <HotelResultCard
                        key={hotelInformation.ResultIndex}
                        hotelInformation={hotelInformation}
                        TraceId={traceId}
                      />
                    ))
                  ) : (
                    <NoHotelResults totalHotels={hotelResults.length} />
                  )}
                </div>
              </div>
            </div>
          )}
        </Spinner>
        {!!hotelResults.length && showFilters && (
          <HotelFiltersDrawer
            sortingValues={sortingValues}
            hotelFilters={hotelFilters}
            setHotelFilters={setHotelFilters}
            resetFilters={resetFilters}
            setResetFilters={setResetFilters}
          />
        )}
      </div>
    </>
  );
};

export default HotelResults;
