import { useFormikContext } from "formik";
import classNames from "classnames";
import { RenderSVG, ToggleDirection } from "../../../assets/icons";
import { get } from "lodash";
import ErrorMessage from "../../atoms/ErrorMessage";
import {
  FlightClass,
  LocationPicker,
  TravelersCount,
  DateSelector,
} from "../../molecules";
import {
  DATE_TYPE_TITLES,
  DEFAULT_VALUES,
  LOCATION_TYPE,
  TRIP_TYPES,
  SEARCH_SECTION,
  DATE_FORMAT,
} from "../../../constants";
import config from "../../../config.json";

const { ZERO, ONE } = DEFAULT_VALUES;
const { FROM, TO } = LOCATION_TYPE;
const { DEPARTURE, RETURN } = DATE_TYPE_TITLES;
const { ROUND_TRIP, ONE_WAY } = TRIP_TYPES;
const { FLIGHT } = SEARCH_SECTION;

const getNextDate = (dateString) => {
  const originalDate = new Date(dateString);
  const oneDayAfter = new Date(originalDate);
  oneDayAfter.setDate(originalDate.getDate() + ONE);
  return oneDayAfter.toISOString().split("T")[ZERO];
};

const RoundTripSearch = ({
  handleLocationSwap,
  handleLocationChange,
  footer,
}) => {
  const { values, touched, errors, setFieldValue } = useFormikContext();
  const { tripType } = values;

  const renderFieldError = (name) =>
    get(errors, name, false) &&
    get(touched, name, false) && (
      <ErrorMessage errorMessage={get(errors, name)} />
    );

  const handleDepartureDateChange = (values) => {
    const startDate = get(values, "0");
    const endDate = get(values, "1");
    const formattedStartDate = startDate?.format(DATE_FORMAT);
    const formattedEndDate = endDate?.format(DATE_FORMAT);
    setFieldValue("journey[0].departureDate", formattedStartDate);
    setFieldValue("journey[0].returnDate", formattedEndDate);
  };

  const handleCloseDatePicker = () => {
    const departureDate = get(values, "journey[0].departureDate");
    const endDate = get(values, "journey[0].returnDate");
    !endDate &&
      setFieldValue("journey[0].returnDate", getNextDate(departureDate));
  };

  return (
    <div>
      <div className="grid grid-cols-12 gap-2 xl:gap-2">
        <div className="col-span-12 md:col-span-7 lg:col-span-4 grid grid-cols-2 gap-4 relative">
          <span
            className="absolute z-10 left-1/2 -translate-x-1/2 top-11 sm:top-6 sm:-translate-y-1/2 cursor-pointer rotate-90 sm:rotate-0"
            onClick={() => handleLocationSwap(ZERO, values, setFieldValue)}
          >
            <RenderSVG
              Svg={ToggleDirection}
              width="32"
              height="32"
              alt="Toggle Direction"
              stroke={config.primaryColor}
            />
          </span>
          <LocationPicker
            name={`journey[0].originCode`}
            handleLocationChange={handleLocationChange}
            type={FROM}
            source={FLIGHT}
          />
          <LocationPicker
            name={`journey[0].destCode`}
            handleLocationChange={handleLocationChange}
            type={TO}
            source={FLIGHT}
          />
        </div>
        <div className="col-span-12 md:col-span-5 lg:col-span-4">
          <div className="flex flex-col sm:flex-row gap-2">
            <DateSelector
              showPrices={false}
              placeholder={DEPARTURE}
              name="journey[0].departureDate"
              defaultDates={[
                get(values, "journey[0].departureDate"),
                get(values, "journey[0].returnDate"),
              ]}
              tripType={ROUND_TRIP}
              range={true}
              showCalendarIcon={true}
              minDate={new Date()}
              height="53px"
              inputClasses="!rounded-md !w-full !text-left"
              handleChangeFromProps={handleDepartureDateChange}
              fareCalendarPrices={{
                origin: values.journey[ZERO].originCode.iata,
                destination: values.journey[ZERO].destCode.iata,
              }}
              onClose={handleCloseDatePicker}
            />
            <DateSelector
              showPrices={false}
              placeholder={RETURN}
              name={`journey[0].returnDate`}
              tripType={ONE_WAY}
              showCalendarIcon={true}
              inputClasses="!rounded-md"
              minDate={get(values, "journey[0].departureDate")}
              height="53px"
              fareCalendarPrices={{
                origin: values.journey[ZERO].originCode.iata,
                destination: values.journey[ZERO].destCode.iata,
              }}
            />
          </div>
          <div>{renderFieldError("journey[0].returnDate")}</div>
        </div>
        <div className="col-span-12 lg:col-span-4 flex flex-col md:flex-row gap-3 2xl:gap-4">
          <div
            className={classNames("gap-2", {
              "flex flex-col md:flex-row": tripType !== ROUND_TRIP,
              "w-full grid grid-cols-12": tripType === ROUND_TRIP,
            })}
          >
            <FlightClass />
            <TravelersCount />
            {footer}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundTripSearch;
