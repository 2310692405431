import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field } from "formik";
import { v4 as uuid } from "uuid";
import { isEmpty } from "lodash";
import * as Yup from "yup";
import classNames from "classnames";
import {
  getFormattedDateTime,
  getPrimaryPaxValidationSchema,
  getPanValidationSchema,
  getPassportValidationSchema,
  renderFieldError,
} from "../../helper";
import {
  RenderSVG,
  EditIcon,
  noUserAdded,
} from "../../assets/icons";
import ErrorMessage from "../../components/atoms/ErrorMessage";
import RenderPassportFields from "./RenderPassportFields";
import Modal from "../../components/organisms/Modal";
import { selectOtherGuests } from "../HotelBooking";
import { selectHotelSearchFilters } from "../../components/organisms/Search";
import { actions as hotelBookActions } from "../HotelBooking/hotelBooking.reducer";
import GuestPrimaryDetails from "./GuestPrimaryDetails";
import { MODAL_SIZE, DEFAULT_VALUES } from "../../constants";

const { ZERO, ONE, EMPTY_STRING } = DEFAULT_VALUES;
const { LG } = MODAL_SIZE;
const { setOtherGuests } = hotelBookActions;
const SHOW_ERROR_DURATION = 3000;

const initialFormValues = {
  profileDetails: {
    title: "Mr",
    firstName: EMPTY_STRING,
    middleName: EMPTY_STRING,
    lastName: EMPTY_STRING,
    isChild: false,
  },
  isSelected: true,
  passportDetails: {
    passportNumber: EMPTY_STRING,
    issueCountry: { isoCode: EMPTY_STRING },
    issuedDate: getFormattedDateTime(new Date())[ZERO],
    expiryDate: getFormattedDateTime(new Date())[ZERO],
  },
  panCardDetails: {
    panCardNumber: EMPTY_STRING,
  },
};

const RenderGuestsList = ({
  type,
  guests = [],
  roomIndex,
  handleEdit = () => {},
  handleSelection = () => {},
}) => {
  const filteredGuests = guests.filter(
    ({ roomIndex: guestRoomIndex }) =>
      guestRoomIndex === roomIndex || guestRoomIndex === null
  );

  return (
    !isEmpty(filteredGuests) && (
      <div className="mb-4 px-4 py-18">
        <div className="font-bold text-contrast-800 text-sm mb-4 leading-normal">
          {type}
        </div>
        <div className="flex flex-col gap-3 mb-2">
          {filteredGuests.map(({ id, isSelected, profileDetails }) => {
            const { title, firstName, middleName, lastName, isChild } =
              profileDetails;
            return (
              <div key={id} className="flex justify-between items-center">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => handleSelection(id, isChild, isSelected)}
                    className="h-5 w-5 mr-4 border-gray-300 !rounded-sm cursor-pointer checked:bg-primary-700 focus:ring-0"
                  />
                  <span className="capitalize text-gray-800">{`${title} ${firstName} ${middleName} ${lastName}`}</span>
                </div>
                <button onClick={() => handleEdit(id)}>
                  <RenderSVG Svg={EditIcon} className="h-5 w-5" />
                </button>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

const HotelGuestModal = ({
  handleClose = () => {},
  isPANRequired,
  isPassportRequired,
  isSamePANForAllAllowed,
  roomIndex,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const otherGuestsInfo = useSelector(selectOtherGuests);
  const [guestIdToBeEdited, setGuestIdToBeEdited] = useState(null);
  const [editingGuestValues, setEditingGuestValues] =
    useState(initialFormValues);
  const [errorMessage, setErrorMessage] = useState("");
  const searchFilters = useSelector(selectHotelSearchFilters);
  const { RoomGuests = [], NoOfRooms = "" } = searchFilters || {};
  const searchedAdultsCount =
    RoomGuests[roomIndex].NoOfAdults - (!roomIndex ? ONE : ZERO);
  const searchedChildrenCount = RoomGuests[roomIndex].NoOfChild;
  const title = NoOfRooms <= 1 ? `Room Guests` : `Room ${roomIndex + 1} Guests`;

  const passportValidationSchema = getPassportValidationSchema();
  const panValidationSchema = getPanValidationSchema();
  const adultsFilter = ({
    isSelected,
    roomIndex: guestRoomIndex,
    profileDetails: { isChild },
  }) => isSelected && !isChild && guestRoomIndex === roomIndex;
  const childrenFilter = ({
    isSelected,
    roomIndex: guestRoomIndex,
    profileDetails: { isChild },
  }) => isSelected && isChild && guestRoomIndex === roomIndex;

  const baseValidationSchema = {
    profileDetails: Yup.object().shape(getPrimaryPaxValidationSchema(t)),
  };

  const guestValidationSchema = Yup.object().shape({
    ...baseValidationSchema,
    ...(isPassportRequired && passportValidationSchema),
    ...(isPANRequired && !isSamePANForAllAllowed && panValidationSchema),
  });

  const adultGuests = otherGuestsInfo.filter(
    ({ profileDetails: { isChild } }) => !isChild
  );
  const childGuests = otherGuestsInfo.filter(
    ({ profileDetails: { isChild } }) => isChild
  );

  const handleSubmit = (values, { resetForm }) => {
    const { isChild } = values.profileDetails || {};

    if (guestIdToBeEdited) {
      const updatedGuests = otherGuestsInfo.map((guest) =>
        guest.id === guestIdToBeEdited
          ? {
              ...guest,
              ...values,
            }
          : guest
      );
      dispatch(setOtherGuests(updatedGuests));
      setGuestIdToBeEdited(null);
    } else {
      const isSelected = isChild
        ? otherGuestsInfo.filter(childrenFilter).length < searchedChildrenCount
        : otherGuestsInfo.filter(adultsFilter).length < searchedAdultsCount;
      const newGuest = {
        id: uuid(),
        ...values,
        roomIndex,
        isSelected,
      };
      dispatch(setOtherGuests([...otherGuestsInfo, newGuest]));
    }
    resetForm();
  };

  const showError = (error) => {
    setErrorMessage(error);
    setTimeout(() => setErrorMessage(EMPTY_STRING), SHOW_ERROR_DURATION);
  };

  const handleEdit = (guestId) => {
    const guestToEdit = otherGuestsInfo.find((guest) => guest.id === guestId);
    setGuestIdToBeEdited(guestId);
    setEditingGuestValues(guestToEdit);
  };

  const handleSelection = (guestId, isChild, isSelected) => {
    const filterCriteria = (detail) =>
      detail.isSelected &&
      detail.roomIndex === roomIndex &&
      (isChild
        ? detail.profileDetails.isChild
        : !detail.profileDetails.isChild);

    const selectedGuestsCount = otherGuestsInfo.filter(filterCriteria).length;
    let error = null;
    if (
      !isSelected &&
      selectedGuestsCount >=
        (isChild ? searchedChildrenCount : searchedAdultsCount)
    )
      error = `You have already selected the required number of ${
        isChild ? "children" : "adults"
      }`;
    if (error) {
      return showError(error);
    }
    const updatedGuestsArray = otherGuestsInfo.map((guest) =>
      guest.id === guestId
        ? {
            ...guest,
            isSelected: !guest.isSelected,
            roomIndex: !guest.isSelected ? roomIndex : null,
          }
        : guest
    );
    dispatch(setOtherGuests(updatedGuestsArray));
  };

  return (
    <Modal
      title={title}
      size={LG}
      shouldShowModalFromProps
      handleClose={handleClose}
    >
      <div className="px-4 md:px-10 py-5 border rounded max-h-[80%] overflow-y-scroll">
        <div className="grid grid-cols-12 gap-2">
          <div className="grid md:col-span-7 col-span-12 overflow-y-auto no-scrollbar">
            <div className="px-4 pb-4 rounded-lg">
              <div className="flex justify-between mb-2">
                <div className="text-lg font-bold text-contrast-800">
                  {guestIdToBeEdited ? "Update Guest Info" : "Add New Guest"}
                </div>
              </div>
              <Formik
                initialValues={
                  guestIdToBeEdited ? editingGuestValues : initialFormValues
                }
                validationSchema={guestValidationSchema}
                validateOnMount={true}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ errors, touched }) => (
                  <Form className="grid grid-cols-12 gap-2">
                    <GuestPrimaryDetails />
                    <div className="col-span-full my-2">
                      <Field
                        id="isChildCheckbox"
                        type="checkbox"
                        className="form-control h-5 w-5 cursor-pointer focus:ring-0 !rounded-sm checked:bg-primary-700"
                        name="profileDetails.isChild"
                      />
                      <label
                        htmlFor="isChildCheckbox"
                        className="ml-2 text-sm font-medium text-contrast-900"
                      >
                        Below 18 years of age
                      </label>
                    </div>
                    {isPassportRequired && <RenderPassportFields />}
                    {isPANRequired && !isSamePANForAllAllowed && (
                      <div className="col-span-12 sm:col-span-6 md:col-span-5">
                        <div className="form-group">
                          <label
                            htmlFor="panCardNumber"
                            className="block form-control text-sm font-medium mb-1 text-contrast-900"
                          >
                            PAN Card Number
                          </label>
                          <Field
                            id="pandCardNumber"
                            type="text"
                            name="panCardDetails.panCardNumber"
                            className="form-control block w-full text-sm py-2 px-3 border-contrast-300 rounded-lg placeholder:text-blue-contrast-500"
                            placeholder="PAN Number"
                          />
                        </div>
                        <div>
                          {renderFieldError(
                            "panCardDetails.panCardNumber",
                            errors,
                            touched
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-span-full mt-4">
                      <button
                        type="submit"
                        className="py-2 px-4 items-center justify-center gap-2 rounded-lg bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white hover:shadow-lg"
                      >
                        {guestIdToBeEdited ? "Update Details" : "Add Guest"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div
            className={classNames("md:col-span-5 col-span-12 border-contrast-500 border-dashed border-t md:border-l md:border-t-0", {
              "hidden md:block": isEmpty(otherGuestsInfo),
            })}
          >
            {isEmpty(otherGuestsInfo) ? (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <RenderSVG
                  Svg={noUserAdded}
                  width="120"
                  height="120"
                  color="gray"
                />
                <p className="font-semibold lg:bottom-16 ">No Guest Added</p>
              </div>
            ) : (
              <>
                <ErrorMessage errorMessage={errorMessage} className="mb-2" />
                <RenderGuestsList
                  type="Adults"
                  guests={adultGuests}
                  roomIndex={roomIndex}
                  handleEdit={handleEdit}
                  handleSelection={handleSelection}
                />
                <RenderGuestsList
                  type="Children"
                  guests={childGuests}
                  roomIndex={roomIndex}
                  handleEdit={handleEdit}
                  handleSelection={handleSelection}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="text-center p-5 text-xl">
        <button
          className="py-3 px-4 rounded-lg min-w-[150px] bg-primary-600 hover:bg-primary-700 active:bg-primary-600 shadow-sm text-sm text-white font-medium"
          onClick={handleClose}
        >
          Save & Proceed
        </button>
      </div>
    </Modal>
  );
};

export default HotelGuestModal;
